import React, { useEffect, useState } from 'react';
import {
    defaultYandexNamesSnapshot,
    YandexNames,
} from '../../../../../modules/business-logic/stores/Product/Manage/YandexNames';
import serviceInitializer from '../../../../../modules/api/serviceInitializer';
import { SimilarNamesService } from '../../../../../modules/api/similar-names';
import { Content } from './Content';
import './styles.css';

interface Props {
    disabledButton: boolean;
    url: string | null;
    onSelect: (name: string) => void;
}

export const SimilarNames: React.FC<Props> = ({ disabledButton, url, onSelect }) => {
    const [store, setStore] = useState<any>(defaultYandexNamesSnapshot);
    const [isVisibleNamesModal, setVisibleNamesModal] = useState(false);

    useEffect(() => {
        const namesStore = YandexNames.create(defaultYandexNamesSnapshot, {
            similarNamesService: serviceInitializer<SimilarNamesService>(SimilarNamesService),
        });
        setStore(namesStore);
    }, []);

    const openModal = () => {
        setVisibleNamesModal(true);
        store.getData(url);
    };
    const closeModal = () => setVisibleNamesModal(false);

    return (
        <Content
            store={store}
            url={url}
            disabledButton={disabledButton}
            isVisibleNamesModal={isVisibleNamesModal}
            onSelect={onSelect}
            openModal={openModal}
            closeModal={closeModal}
        />
    );
};
