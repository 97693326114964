import * as React from 'react';
import { Content } from './Content';
import { useEffect, useState } from 'react';
import { AccountService } from '../../../../../modules/api/account';
import { defaultBaseProductStore } from '../../../../../modules/business-logic/stores/Product/Manage/Base';
import serviceInitializer from '../../../../../modules/api/serviceInitializer';
import { ProductService } from '../../../../../modules/api/product';
import { CurrencyService } from '../../../../../modules/api/currency';
import { isAdmin } from '../../../../../context/UserProvider';
import { SimilarNamesService } from '../../../../../modules/api/similar-names';
import { createReactModal, Modal } from '../../../../ModalWindow/AntModal/createAntModal';
import { Product } from '../../../../../modules/api/product/interfaces';
import { EditProductStore } from '../../../../../modules/business-logic/stores/Product/Manage/Edit';
import { FileService } from '../../../../../modules/api/attachment';
import {CategoryService} from "../../../../../modules/api/category";

interface Params {
    onEdit: () => void;
    user: any;
    currency?: string | null;
    number: number;
}

type Props = Params & Modal<Product | {}>;

export const EditProductModal: React.FC<Props> = (props) => {
    const onClose = () => props.modal.onClose({});
    const [store, setStore] = useState<any>(defaultBaseProductStore);
    useEffect(() => {
        const productStore = EditProductStore.create(
            {
                ...defaultBaseProductStore,
                isLoadingData: {
                    value: true,
                },
            },
            {
                isAdmin: isAdmin(props.user),
                userId: props.user?._id,
                currency: props.currency,
                number: props.number,
                productService: serviceInitializer<ProductService>(ProductService),
                currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                accountService: serviceInitializer<AccountService>(AccountService),
                fileService: serviceInitializer<FileService>(FileService),
                similarNamesService: serviceInitializer<SimilarNamesService>(SimilarNamesService),
                categoryService: serviceInitializer<CategoryService>(CategoryService),
            },
        );
        setStore(productStore);
    }, [props.user]);

    if (!store) return null;

    const submit = async () => {
        const res = await store.edit();
        if (res?._id) {
            props.modal.onClose(res);
            props.onEdit();
        }
    };

    return <Content onClose={onClose} store={store} onSubmit={submit} editMode />;
};

//@ts-ignore
export const editProduct = (params: Params) => createReactModal(EditProductModal, params);
