import React, { useEffect, useState } from 'react';
import difference from 'lodash/difference';
import { Table, Transfer } from 'antd';
import type { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import type { TransferItem, TransferProps } from 'antd/es/transfer';
import { observer } from 'mobx-react';
import { WayBills } from '../../../../modules/api/waybill/interfaces';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Currency } from '../../../../modules/api/currency/interfaces';
import { useTranslation } from 'react-i18next';

interface RecordType {
    key: string;
    number: number;
    numberOrder: string;
    title: string;
    createdAt: string;
    productsCount: number;
    chosen: boolean;
}

interface DataType {
    key: string;
    number: number;
    numberOrder: string;
    title: string;
    createdAt: string;
    productsCount: number;
    chosen: boolean;
}

interface TableTransferProps extends TransferProps<TransferItem> {
    dataSource: DataType[];
    leftColumns: ColumnsType<DataType>;
    rightColumns: ColumnsType<DataType>;
}

const formatDate = (date: string | null) => {
    return date ? format(new Date(date), 'dd.MM.yyyy HH:mm', { locale: ru }) : '-';
};

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
    //@ts-ignore
    <Transfer {...restProps}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;

            const rowSelection: TableRowSelection<TransferItem> = {
                getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter((item) => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys as string[], selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key as string, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };

            return (
                <Table
                    rowSelection={rowSelection}
                    //@ts-ignore
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{ pointerEvents: listDisabled ? 'none' : undefined }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key as string, !listSelectedKeys.includes(key as string));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

interface Props {
    currencies: Currency[];
    wayBills: WayBills;
    selectedWayBills: string[];
    loading: boolean;
    selectWayBills: (ids: string[]) => void;
}

export const TransferWayBillsTable: React.FC<Props> = observer(
    ({ wayBills, loading, selectWayBills, currencies }) => {
        const [mockData, setMockData] = useState<RecordType[]>([]);
        const [targetKeys, setTargetKeys] = useState<string[]>([]);
        const { t } = useTranslation();

        const leftTableColumns: ColumnsType<DataType> = [
            {
                dataIndex: 'number',
                title: t('waybillList:tableHeaders.id'),
            },
            {
                dataIndex: 'numberOrder',
                title: t('waybillList:tableHeaders.numberOrder'),
                // render: (tag) => <Tag>{tag}</Tag>,
            },
            {
                dataIndex: 'title',
                title: t('waybillList:tableHeaders.title'),
            },
            {
                dataIndex: 'createdAt',
                title: t('waybillList:tableHeaders.createdAt'),
            },
            {
                dataIndex: 'productsCount',
                title: t('waybillList:tableHeaders.countProducts'),
            },
        ];

        const rightTableColumns: ColumnsType<DataType> = leftTableColumns;

        const getMock = () => {
            const tempMockData = wayBills.map(
                ({ _id, number, numberOrder, createdAt, productIds, currencyId, label }) => ({
                    key: _id,
                    number,
                    numberOrder,
                    title: label || '-',
                    currency: currencies.find((currency) => currency._id === currencyId)?.value,
                    createdAt: formatDate(createdAt),
                    productsCount: productIds.reduce((acc, product) => product.count + acc, 0), //productIds.length,
                    chosen: false,
                }),
            );
            setMockData(tempMockData);
        };

        useEffect(() => {
            getMock();
        }, [wayBills, loading]);

        const handleChange = (newTargetKeys: string[]) => {
            selectWayBills(newTargetKeys);
            setTargetKeys(newTargetKeys);
        };

        return (
            <>
                <TableTransfer
                    dataSource={mockData}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    filterOption={(inputValue, item) =>
                        item.title!.indexOf(inputValue) !== -1 ||
                        item.tag.indexOf(inputValue) !== -1
                    }
                    leftColumns={leftTableColumns}
                    //@ts-ignore
                    rightColumns={rightTableColumns}
                />
            </>
        );
    },
);
