import React from 'react';
import { Button, Image, Modal, Typography } from 'antd';
import './styles.css';
import { observer } from 'mobx-react-lite';
import { Spinner } from '../../../../Page/components/Spinner';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface NameItem {
    name: string;
    src: string;
}

interface Props {
    store: any;
    url: string | null;
    disabledButton: boolean;
    isVisibleNamesModal: boolean;
    onSelect: (name: string) => void;
    openModal: () => void;
    closeModal: () => void;
}

export const Content: React.FC<Props> = observer(
    ({ disabledButton, isVisibleNamesModal, onSelect, store, openModal, closeModal }) => {
        const { t } = useTranslation();
        return (
            <>
                <div>
                    <Button
                        size={'middle'}
                        onClick={openModal}
                        disabled={disabledButton || store.isGetting.value}
                        loading={store.isGetting.value}
                    >
                        {t('productCreate:findSimilarYa')}
                    </Button>
                </div>
                {isVisibleNamesModal && (
                    <Modal
                        open={true}
                        onCancel={closeModal}
                        title={
                            <Text strong className={'modalTitle'}>
                                {t('productCreate:addingTitle')}
                            </Text>
                        }
                        footer={null}
                    >
                        <div className={'modalBody'}>
                            {store.isGetting.value && <Spinner />}
                            {!store.isGetting.value &&
                                store.names.map((item: NameItem, index: number) => (
                                    <div className={'nameItem'} key={index}>
                                        <div className={'productItemImg'}>
                                            <Image src={item.src} width={60} height={60} />
                                        </div>
                                        <div
                                            className={'productItemText'}
                                            onClick={() => {
                                                onSelect(item.name);
                                                closeModal();
                                            }}
                                        >
                                            <Text>{item.name}</Text>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Modal>
                )}
            </>
        );
    },
);
