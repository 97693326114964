import { Empty } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import './styles.css';
import { Spinner } from '../../../Page/components/Spinner';
import { AccountListItem } from './Item';
import { useTranslation } from 'react-i18next';

interface Props {
    store: any;
}

export const List: React.FC<Props> = observer(({ store }) => {
    const { t } = useTranslation();
    const isLoading = store.indicators.isLoading.value;
    const isEmpty = store.indicators.isEmpty.value;

    if (isLoading) {
        return <Spinner />;
    }

    if (isEmpty) return <Empty style={{ marginTop: 50 }} description={'Нет данных'} />;

    return (
        <div className={'list'}>
            <div className={'accountListItemHeader'}>
                <div className={'id'}>{t('accountList:tableHeaders.id')}</div>
                <div className={'username'}>{t('accountList:tableHeaders.username')}</div>
                <div className={'name'}>{t('accountList:tableHeaders.name')}</div>
                <div className={'lastLoginDate'}>{t('accountList:tableHeaders.lastLoginDate')}</div>
                <div className={'status'}>{t('accountList:tableHeaders.status')}</div>
                <div className={'accountActions'}></div>
            </div>
            {store.list &&
                store.list.map((item: any) => (
                    <AccountListItem
                        _id={item._id}
                        number={item.number}
                        username={item.username}
                        firstName={item.firstName}
                        secondName={item.secondName}
                        isBlocked={item.isBlocked}
                        lastLoginDate={item.lastLoginDate}
                        updateList={store.getList}
                        onBlock={(isBlocked: boolean) => {
                            store.onBlock(item.number, isBlocked);
                        }}
                    />
                ))}
        </div>
    );
});
