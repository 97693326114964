import * as React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Result, Typography } from 'antd';
import {
    defaultResetPasswordStore,
    ResetPasswordStore,
} from '../../../../../modules/business-logic/stores/Users/ResetPassword';
import { useEffect, useState } from 'react';
import serviceInitializer from '../../../../../modules/api/serviceInitializer';
import { AccountService } from '../../../../../modules/api/account';
import { Copy } from '../../../../Copy';
import { observer } from 'mobx-react';

const { Text } = Typography;

interface Props {
    _id: string;
    onClose: () => void;
}

export const ResetPasswordModal: React.FC<Props> = observer(({ _id, onClose }) => {
    const [store, setStore] = useState<any>(defaultResetPasswordStore);

    useEffect(() => {
        const store = ResetPasswordStore.create(defaultResetPasswordStore, {
            accountService: serviceInitializer<AccountService>(AccountService),
        });
        setStore(store);
    }, []);

    if (!store) return null;

    if (!store.isSending.value && store.hasCredentials.value) {
        const username = store.username;
        const password = store.password;

        if (!username || !password) return null;

        return (
            <Modal open={true} footer={null} onCancel={onClose}>
                <Result
                    status="success"
                    title={
                        <Text style={{ fontWeight: 600, fontSize: 24, color: '#333' }}>
                            Пароль был успешно сброшен!
                        </Text>
                    }
                    subTitle={
                        <>
                            <Text type={'secondary'}>
                                Передайте данные учетной записи для авторизации. В случае потери
                                данных заблокируйте учетную запись или пересоздайте пароль
                            </Text>

                            <div className={'deviceCredentials'}>
                                <div>
                                    <Text strong>{'Логин: '}</Text>
                                    <Text>
                                        {`${username}`}
                                        <Copy value={username} />
                                    </Text>
                                </div>
                                <div>
                                    <Text strong>{'Пароль: '}</Text>
                                    <Text>
                                        {`${password}`}
                                        <Copy value={password} />
                                    </Text>
                                </div>
                            </div>
                        </>
                    }
                />
            </Modal>
        );
    }

    return (
        <Modal
            title={
                <>
                    <Text strong className={'modalTitle'}>
                        <InfoCircleOutlined style={{ color: 'red' }} />
                        {' Сброс пароля '}
                    </Text>

                    {/*<Tint title={'Сброс пароля'} description={'Сброс паролдя'} />*/}
                </>
            }
            open={true}
            onOk={() => store.resetPassword(_id)}
            onCancel={onClose}
            cancelText={'Отменить'}
            okText={'Сбросить пароль'}
            confirmLoading={store.isSending.value}
            okButtonProps={{
                disabled: store.isSending.value,
            }}
        >
            <div className={'modalBody'}>
                <Text type={'secondary'}>
                    {
                        'Внимание! При сбросе пароля учетная запись потеряет доступ к системе, так как старый пароль перестанет быть актуальным'
                    }
                </Text>
            </div>
        </Modal>
    );
});
