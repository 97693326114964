import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Input, Modal, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import {Spinner} from "../../../Page/components/Spinner";

const { Text } = Typography;

interface Props {
    store: any;
    isAdmin: boolean;
    editMode?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({ store, onClose, onSubmit, isAdmin }) => {
    const { t } = useTranslation();
    const editMode = false;

    const { title, code, indicators } = store;
    const isValid = store.isValid && store.isValid();

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('Добавление страны')}
                </Text>
            }
            open
            onOk={onSubmit}
            onCancel={onClose}
            okButtonProps={{ disabled: !isValid }}
            cancelText={t('common:cancel')}
            okText={t(`common:${editMode ? 'approve' : 'create'}`)}
            confirmLoading={indicators.isSending.value}
        >
            <div className={'modalBody'}>
                {!indicators.isLoading.value && (
                    <>
                        <Input
                            placeholder={t('productCreate:title')}
                            size={'large'}
                            value={title.value}
                            onChange={(e) => title.change(e.target.value)}
                        />
                        <Input
                            placeholder={t('Код страны')}
                            size={'large'}
                            value={code.value}
                            onChange={(e) => code.change(e.target.value)}
                        />
                    </>
                )}
                {indicators.isLoading.value && <Spinner />}
            </div>
        </Modal>
    );
});
