import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultTextFieldSnapshot, TextField } from '../../common/TextField';
import { Currency } from '../../Currency';
import { Consignment } from '../models';
import { defaultIndicatorsSnapshot, Indicators } from './models';
import { CurrencyService } from '../../../../api/currency';
import { WayBillService } from '../../../../api/waybill';
import { config } from '../../../../api/serviceInitializer';

interface Environment {
    currencyService: CurrencyService;
    wayBillService: WayBillService;
}

export const ExportWayBillStore = types
    .model({
        dateFrom: TextField,
        dateTo: TextField,
        currencies: types.array(Currency),
        selectedCurrencyId: types.maybeNull(types.string),
        wayBills: types.array(Consignment),
        selectedWayBillsIds: types.array(types.string),
        inputFileId: types.maybeNull(types.string),
        outputFilePath: types.maybeNull(types.string),
        indicators: Indicators,
        totalCount: types.number
    })
    .actions((self) => {
        const { currencyService, wayBillService } = getEnv<Environment>(self);

        const setDates = (from: string, to: string) => {
            self.dateFrom.change(from);
            self.dateTo.change(to);

            self.indicators.isDatesSetted.set(
                Boolean(self.dateFrom.value.length && self.dateTo.value.length),
            );

            if (self.indicators.isDatesSetted.value || self.selectedCurrencyId) getWayBills();
        };

        const uploadInputfile = (id: string) => {
            self.inputFileId = id;
        };

        const setCurrency = async (_id: string) => {
            self.selectedCurrencyId = _id;
            if (self.indicators.isDatesSetted.value || self.selectedCurrencyId) getWayBills();

            if (self.selectedCurrencyId)
                await localStorage.setItem('currencyId', self.selectedCurrencyId);
        };

        const getCurrencies = flow(function* () {
            self.indicators.isGettingCurrencies.set(true);
            try {
                self.currencies = yield currencyService.getList();

                const savedCurrencyId = localStorage.getItem('currencyId');
                if (savedCurrencyId) self.selectedCurrencyId = savedCurrencyId;
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingCurrencies.set(false);
            }
        });

        const getWayBills = flow(function* () {
            self.indicators.isGettingWayBills.set(true);
            try {
                 const { items, totalCount } = yield wayBillService.getList({
                    startDate: self.dateFrom.value,
                    endDate: self.dateTo.value,
                    currencyId: self.selectedCurrencyId || undefined,
                });
                self.wayBills = items;
                self.totalCount = totalCount;
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingWayBills.set(false);
            }
        });

        const selectWayBills = (ids: string[]) => {
            self.selectedWayBillsIds.replace(ids);

            self.indicators.enabledUploadButton.set(Boolean(self.selectedWayBillsIds.length));
        };

        const afterCreate = () => {
            getCurrencies();
        };

        const getExportFileLink = flow(function* () {
            if (!self.inputFileId) return;
            self.indicators.isExporting.set(true);
            try {
                const { response } = yield wayBillService.exportRegistry({
                    inputFileId: self.inputFileId,
                    wayBillIds: self.selectedWayBillsIds.reduce(
                        (acc, id) => `${acc}${acc.length ? ',' : ''}${id}`,
                        '',
                    ),
                });

                self.outputFilePath = `${config.baseURL}/${response.outputFilePath}`;
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isExporting.set(false);
            }
        });

        const checkValid = () => {
            const isValidDates =
                Boolean(self.dateFrom.value.length) && Boolean(self.dateTo.value.length);
            const isSelectedWayBills = Boolean(self.selectedWayBillsIds.length);
            const uploadedFile = Boolean(self.inputFileId && self.inputFileId.length);

            return [isValidDates, isSelectedWayBills, uploadedFile].every((r) => Boolean(r));
        };

        return {
            afterCreate,
            setDates,
            selectWayBills,
            setCurrency,
            uploadInputfile,
            getExportFileLink,
            checkValid,
        };
    });

export const defaultExportWayBillStore: SnapshotIn<typeof ExportWayBillStore> = {
    dateFrom: defaultTextFieldSnapshot,
    dateTo: defaultTextFieldSnapshot,
    currencies: [],
    wayBills: [],
    selectedWayBillsIds: [],
    indicators: defaultIndicatorsSnapshot,
    totalCount: 0,
};
