import {LoginParams, LoginResponse, PingParams, PingResponse} from './interfaces';
import { BaseService } from '../base/BaseService';

export class AuthService extends BaseService {
    private readonly url: string = `${this.baseUrl}/auth`;

    public login = (params: LoginParams): Promise<LoginResponse> =>
        this.http.post(`${this.url}/login`, params).then((res) => res.data);

    public ping = (params: PingParams): Promise<PingResponse> =>
        this.http.get(`${this.url}/ping`, {
            params
        }).then((res) => res.data);
}
