import * as React from 'react';
import { useEffect, useState } from 'react';
import serviceInitializer from "../../../../modules/api/serviceInitializer";
import {Product} from "../../../../modules/api/product/interfaces";
import {isAdmin} from "../../../../context/UserProvider";
import {createReactModal, Modal} from "../../../ModalWindow/AntModal/createAntModal";
import {AccountService} from "../../../../modules/api/account";
import {
    EditCategoryStore,
    defaultEditCategoryStoreSnapshot
} from "../../../../modules/business-logic/stores/Category/Edit";
import {CategoryService} from "../../../../modules/api/category";
import {Content} from "./Content";

interface Params {
    _id: string;
    updateList: () => void;
    user: any;
}

type Props = Params & Modal<Product | {}>;

export const EditCategoryModal: React.FC<Props> = (props) => {
    const onClose = () => props.modal.onClose({});
    const [store, setStore] = useState<any>(null);
    const isAdminUser = isAdmin(props.user) || false;
    useEffect(() => {
        const productStore = EditCategoryStore.create(defaultEditCategoryStoreSnapshot, {
            _id: props._id,
            isAdmin: isAdminUser,
            accountService: serviceInitializer<AccountService>(AccountService),
            categoryService: serviceInitializer<CategoryService>(CategoryService),
        });
        setStore(productStore);
    }, [props.user]);

    if (!store) return null;

    const submit = async () => {
        const res = await store.edit(props._id);
        props.modal.onClose(res);
        props.updateList();
    };

    return <Content onClose={onClose} store={store} onSubmit={submit} isAdmin={isAdminUser} />;
};

//@ts-ignore
export const editCategory = (params: Params) => createReactModal(EditCategoryModal, params);
