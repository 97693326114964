export const EnFlagIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.00292969" width="19.8174" height="20" fill="#0052B4" />
            <rect x="0.0385742" y="18.4375" width="19.8174" height="1.5625" fill="#D80027" />
            <rect y="18.4375" width="19.8561" height="1.5625" fill="#D80027" />
            <rect y="16.875" width="19.8561" height="1.5625" fill="white" />
            <rect y="15.3125" width="19.8561" height="1.5625" fill="#D80027" />
            <rect y="13.75" width="19.8561" height="1.5625" fill="white" />
            <rect y="12.1875" width="19.8561" height="1.5625" fill="#D80027" />
            <rect y="10.625" width="19.8561" height="1.5625" fill="white" />
            <rect x="0.0385742" y="9.0625" width="19.8174" height="1.5625" fill="#D80027" />
            <rect x="0.0385742" y="7.5" width="19.8174" height="1.5625" fill="white" />
            <rect y="5.9375" width="19.8174" height="1.5625" fill="#D80027" />
            <rect y="4.375" width="19.8174" height="1.5625" fill="white" />
            <rect y="2.8125" width="19.8174" height="1.5625" fill="#D80027" />
            <rect width="19.8174" height="1.25" fill="#D80027" />
            <rect y="1.25" width="19.8174" height="1.5625" fill="white" />
            <rect
                y="10.625"
                width="10.625"
                height="9.94741"
                transform="rotate(-90 0 10.625)"
                fill="#0052B4"
            />
            <path
                d="M3.52237 7.85156L3.77438 8.63432H4.58991L3.93013 9.11809L4.18214 9.90084L3.52237 9.41707L2.8626 9.90084L3.11461 9.11809L2.45484 8.63432H3.27036L3.52237 7.85156Z"
                fill="white"
            />
            <path
                d="M3.52237 7.85156L3.77438 8.63432H4.58991L3.93013 9.11809L4.18214 9.90084L3.52237 9.41707L2.8626 9.90084L3.11461 9.11809L2.45484 8.63432H3.27036L3.52237 7.85156Z"
                fill="white"
            />
            <path
                d="M3.52237 7.85156L3.77438 8.63432H4.58991L3.93013 9.11809L4.18214 9.90084L3.52237 9.41707L2.8626 9.90084L3.11461 9.11809L2.45484 8.63432H3.27036L3.52237 7.85156Z"
                fill="white"
            />
            <path
                d="M3.52237 7.85156L3.77438 8.63432H4.58991L3.93013 9.11809L4.18214 9.90084L3.52237 9.41707L2.8626 9.90084L3.11461 9.11809L2.45484 8.63432H3.27036L3.52237 7.85156Z"
                fill="white"
            />
            <path
                d="M3.52237 7.85156L3.77438 8.63432H4.58991L3.93013 9.11809L4.18214 9.90084L3.52237 9.41707L2.8626 9.90084L3.11461 9.11809L2.45484 8.63432H3.27036L3.52237 7.85156Z"
                fill="white"
            />
            <path
                d="M5.767 5.46875L6.01901 6.2515H6.83453L6.17476 6.73527L6.42677 7.51803L5.767 7.03426L5.10723 7.51803L5.35924 6.73527L4.69947 6.2515H5.51499L5.767 5.46875Z"
                fill="white"
            />
            <path
                d="M5.767 5.46875L6.01901 6.2515H6.83453L6.17476 6.73527L6.42677 7.51803L5.767 7.03426L5.10723 7.51803L5.35924 6.73527L4.69947 6.2515H5.51499L5.767 5.46875Z"
                fill="white"
            />
            <path
                d="M5.767 5.46875L6.01901 6.2515H6.83453L6.17476 6.73527L6.42677 7.51803L5.767 7.03426L5.10723 7.51803L5.35924 6.73527L4.69947 6.2515H5.51499L5.767 5.46875Z"
                fill="white"
            />
            <path
                d="M5.767 5.46875L6.01901 6.2515H6.83453L6.17476 6.73527L6.42677 7.51803L5.767 7.03426L5.10723 7.51803L5.35924 6.73527L4.69947 6.2515H5.51499L5.767 5.46875Z"
                fill="white"
            />
            <path
                d="M5.767 5.46875L6.01901 6.2515H6.83453L6.17476 6.73527L6.42677 7.51803L5.767 7.03426L5.10723 7.51803L5.35924 6.73527L4.69947 6.2515H5.51499L5.767 5.46875Z"
                fill="white"
            />
            <path
                d="M1.27726 5.58594L1.52927 6.36869H2.34479L1.68502 6.85246L1.93703 7.63521L1.27726 7.15145L0.617484 7.63521L0.869494 6.85246L0.209723 6.36869H1.02525L1.27726 5.58594Z"
                fill="white"
            />
            <path
                d="M1.27726 5.58594L1.52927 6.36869H2.34479L1.68502 6.85246L1.93703 7.63521L1.27726 7.15145L0.617484 7.63521L0.869494 6.85246L0.209723 6.36869H1.02525L1.27726 5.58594Z"
                fill="white"
            />
            <path
                d="M1.27726 5.58594L1.52927 6.36869H2.34479L1.68502 6.85246L1.93703 7.63521L1.27726 7.15145L0.617484 7.63521L0.869494 6.85246L0.209723 6.36869H1.02525L1.27726 5.58594Z"
                fill="white"
            />
            <path
                d="M1.27726 5.58594L1.52927 6.36869H2.34479L1.68502 6.85246L1.93703 7.63521L1.27726 7.15145L0.617484 7.63521L0.869494 6.85246L0.209723 6.36869H1.02525L1.27726 5.58594Z"
                fill="white"
            />
            <path
                d="M1.27726 5.58594L1.52927 6.36869H2.34479L1.68502 6.85246L1.93703 7.63521L1.27726 7.15145L0.617484 7.63521L0.869494 6.85246L0.209723 6.36869H1.02525L1.27726 5.58594Z"
                fill="white"
            />
            <path
                d="M8.01212 7.73438L8.26413 8.51713H9.07965L8.41988 9.0009L8.67189 9.78365L8.01212 9.29988L7.35235 9.78365L7.60436 9.0009L6.94459 8.51713H7.76011L8.01212 7.73438Z"
                fill="white"
            />
            <path
                d="M8.01212 7.73438L8.26413 8.51713H9.07965L8.41988 9.0009L8.67189 9.78365L8.01212 9.29988L7.35235 9.78365L7.60436 9.0009L6.94459 8.51713H7.76011L8.01212 7.73438Z"
                fill="white"
            />
            <path
                d="M8.01212 7.73438L8.26413 8.51713H9.07965L8.41988 9.0009L8.67189 9.78365L8.01212 9.29988L7.35235 9.78365L7.60436 9.0009L6.94459 8.51713H7.76011L8.01212 7.73438Z"
                fill="white"
            />
            <path
                d="M8.01212 7.73438L8.26413 8.51713H9.07965L8.41988 9.0009L8.67189 9.78365L8.01212 9.29988L7.35235 9.78365L7.60436 9.0009L6.94459 8.51713H7.76011L8.01212 7.73438Z"
                fill="white"
            />
            <path
                d="M8.01212 7.73438L8.26413 8.51713H9.07965L8.41988 9.0009L8.67189 9.78365L8.01212 9.29988L7.35235 9.78365L7.60436 9.0009L6.94459 8.51713H7.76011L8.01212 7.73438Z"
                fill="white"
            />
            <path
                d="M3.52237 3.55469L3.77438 4.33744H4.58991L3.93013 4.82121L4.18214 5.60396L3.52237 5.1202L2.8626 5.60396L3.11461 4.82121L2.45484 4.33744H3.27036L3.52237 3.55469Z"
                fill="white"
            />
            <path
                d="M3.52237 3.55469L3.77438 4.33744H4.58991L3.93013 4.82121L4.18214 5.60396L3.52237 5.1202L2.8626 5.60396L3.11461 4.82121L2.45484 4.33744H3.27036L3.52237 3.55469Z"
                fill="white"
            />
            <path
                d="M3.52237 3.55469L3.77438 4.33744H4.58991L3.93013 4.82121L4.18214 5.60396L3.52237 5.1202L2.8626 5.60396L3.11461 4.82121L2.45484 4.33744H3.27036L3.52237 3.55469Z"
                fill="white"
            />
            <path
                d="M3.52237 3.55469L3.77438 4.33744H4.58991L3.93013 4.82121L4.18214 5.60396L3.52237 5.1202L2.8626 5.60396L3.11461 4.82121L2.45484 4.33744H3.27036L3.52237 3.55469Z"
                fill="white"
            />
            <path
                d="M3.52237 3.55469L3.77438 4.33744H4.58991L3.93013 4.82121L4.18214 5.60396L3.52237 5.1202L2.8626 5.60396L3.11461 4.82121L2.45484 4.33744H3.27036L3.52237 3.55469Z"
                fill="white"
            />
            <path
                d="M5.767 1.17188L6.01901 1.95463H6.83453L6.17476 2.4384L6.42677 3.22115L5.767 2.73738L5.10723 3.22115L5.35924 2.4384L4.69947 1.95463H5.51499L5.767 1.17188Z"
                fill="white"
            />
            <path
                d="M5.767 1.17188L6.01901 1.95463H6.83453L6.17476 2.4384L6.42677 3.22115L5.767 2.73738L5.10723 3.22115L5.35924 2.4384L4.69947 1.95463H5.51499L5.767 1.17188Z"
                fill="white"
            />
            <path
                d="M5.767 1.17188L6.01901 1.95463H6.83453L6.17476 2.4384L6.42677 3.22115L5.767 2.73738L5.10723 3.22115L5.35924 2.4384L4.69947 1.95463H5.51499L5.767 1.17188Z"
                fill="white"
            />
            <path
                d="M5.767 1.17188L6.01901 1.95463H6.83453L6.17476 2.4384L6.42677 3.22115L5.767 2.73738L5.10723 3.22115L5.35924 2.4384L4.69947 1.95463H5.51499L5.767 1.17188Z"
                fill="white"
            />
            <path
                d="M5.767 1.17188L6.01901 1.95463H6.83453L6.17476 2.4384L6.42677 3.22115L5.767 2.73738L5.10723 3.22115L5.35924 2.4384L4.69947 1.95463H5.51499L5.767 1.17188Z"
                fill="white"
            />
            <path
                d="M1.27726 1.28906L1.52927 2.07182H2.34479L1.68502 2.55559L1.93703 3.33834L1.27726 2.85457L0.617484 3.33834L0.869494 2.55559L0.209723 2.07182H1.02525L1.27726 1.28906Z"
                fill="white"
            />
            <path
                d="M1.27726 1.28906L1.52927 2.07182H2.34479L1.68502 2.55559L1.93703 3.33834L1.27726 2.85457L0.617484 3.33834L0.869494 2.55559L0.209723 2.07182H1.02525L1.27726 1.28906Z"
                fill="white"
            />
            <path
                d="M1.27726 1.28906L1.52927 2.07182H2.34479L1.68502 2.55559L1.93703 3.33834L1.27726 2.85457L0.617484 3.33834L0.869494 2.55559L0.209723 2.07182H1.02525L1.27726 1.28906Z"
                fill="white"
            />
            <path
                d="M1.27726 1.28906L1.52927 2.07182H2.34479L1.68502 2.55559L1.93703 3.33834L1.27726 2.85457L0.617484 3.33834L0.869494 2.55559L0.209723 2.07182H1.02525L1.27726 1.28906Z"
                fill="white"
            />
            <path
                d="M1.27726 1.28906L1.52927 2.07182H2.34479L1.68502 2.55559L1.93703 3.33834L1.27726 2.85457L0.617484 3.33834L0.869494 2.55559L0.209723 2.07182H1.02525L1.27726 1.28906Z"
                fill="white"
            />
            <path
                d="M8.01212 3.4375L8.26413 4.22025H9.07965L8.41988 4.70402L8.67189 5.48678L8.01212 5.00301L7.35235 5.48678L7.60436 4.70402L6.94459 4.22025H7.76011L8.01212 3.4375Z"
                fill="white"
            />
            <path
                d="M8.01212 3.4375L8.26413 4.22025H9.07965L8.41988 4.70402L8.67189 5.48678L8.01212 5.00301L7.35235 5.48678L7.60436 4.70402L6.94459 4.22025H7.76011L8.01212 3.4375Z"
                fill="white"
            />
            <path
                d="M8.01212 3.4375L8.26413 4.22025H9.07965L8.41988 4.70402L8.67189 5.48678L8.01212 5.00301L7.35235 5.48678L7.60436 4.70402L6.94459 4.22025H7.76011L8.01212 3.4375Z"
                fill="white"
            />
            <path
                d="M8.01212 3.4375L8.26413 4.22025H9.07965L8.41988 4.70402L8.67189 5.48678L8.01212 5.00301L7.35235 5.48678L7.60436 4.70402L6.94459 4.22025H7.76011L8.01212 3.4375Z"
                fill="white"
            />
            <path
                d="M8.01212 3.4375L8.26413 4.22025H9.07965L8.41988 4.70402L8.67189 5.48678L8.01212 5.00301L7.35235 5.48678L7.60436 4.70402L6.94459 4.22025H7.76011L8.01212 3.4375Z"
                fill="white"
            />
        </svg>
    );
};
