import React from 'react';
import { observer } from 'mobx-react';
import {Input, Modal, notification, Result, Select, Typography} from 'antd';
import { Tint } from '../../../Statistics/components/Tint';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {OrganisationSelect} from "../../../../OrganisationSelect";

interface Props {
    store: any; //Instance<typeof CreateProgramStore>
    onClose: () => void;
    updateList: () => void;
}

const { Text } = Typography;

export const Content: React.FC<Props> = observer(({ store, onClose, updateList }) => {
    const { t } = useTranslation();
    const { firstName, secondName, username } = store;

    const isValid = store.isValid && store.isValid();

    const submit = async () => {
        await store.create();
        updateList();
    };

    const showCopyNotification = () => {
        notification.success({
            message: t('common:textCopied'),
            duration: 1,
        });
    };

    if (store.hasCredentials.value) {
        const username = store.username.value;
        const password = store.password;
        return (
            <Modal open={true} footer={null} onCancel={onClose}>
                <Result
                    status="success"
                    title={
                        <Text style={{ fontWeight: 600, fontSize: 24, color: '#333' }}>
                            Пользователь добавлен!
                        </Text>
                    }
                    subTitle={
                        <>
                            <Text type={'secondary'}>
                                Передайте данные для авторизации пользователя. В случае потери
                                данных заблокируйте учетную запись или пересоздайте пароль
                            </Text>

                            <div className={'deviceCredentials'}>
                                <div>
                                    <Text strong>{'Логин: '}</Text>
                                    <Text>
                                        {`${username} `}
                                        <CopyToClipboard
                                            text={username}
                                            onCopy={showCopyNotification}
                                        >
                                            <CopyOutlined className={'copyIcon'} />
                                        </CopyToClipboard>
                                    </Text>
                                </div>
                                <div>
                                    <Text strong>{'Пароль: '}</Text>
                                    <Text>
                                        {`${password} `}
                                        <CopyToClipboard
                                            text={password}
                                            onCopy={showCopyNotification}
                                        >
                                            <CopyOutlined className={'copyIcon'} />
                                        </CopyToClipboard>
                                    </Text>
                                </div>
                            </div>
                        </>
                    }
                />
            </Modal>
        );
    }

    return (
        <Modal
            title={
                <>
                    <Text strong className={'modalTitle'}>
                        {'Добавление пользователя '}
                    </Text>
                    <Tint
                        title={'Добавление пользователя'}
                        description={
                            'Оператор следит за статусом устройства и предотвращает возникновение непредвиденных ситуаций'
                        }
                    />
                </>
            }
            open={true}
            onOk={submit}
            onCancel={onClose}
            cancelButtonProps={{ size: 'large' }}
            okButtonProps={{ size: 'large', disabled: !isValid }}
            cancelText={'Отменить'}
            okText={'Создать'}
            confirmLoading={store.isSending.value}
        >
            <div className={'modalBody'}>
                <Input
                    placeholder={'Логин'}
                    size={'large'}
                    value={username.value}
                    onChange={(e) => username.change(e.target.value)}
                />
                <Input
                    placeholder={'Имя'}
                    size={'large'}
                    value={firstName.value}
                    onChange={(e) => firstName.change(e.target.value)}
                />
                <Input
                    placeholder={'Фамилия'}
                    size={'large'}
                    value={secondName.value}
                    onChange={(e) => secondName.change(e.target.value)}
                />
                <OrganisationSelect
                    store={store.organisations}
                    isLoading={store.isGettingOrganisations.value}
                    disabled={store.isGettingOrganisations.value}
                    value={store.selectedResponsiblePersonId}
                    set={store.setOrganisationId}
                />
            </div>
        </Modal>
    );
});
