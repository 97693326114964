import { types } from 'mobx-state-tree';
import { Currency } from '../../Currency';
import { File } from '../../common/File';

export const Product = types.model({
    _id: types.string,
    title: types.string,
    description: types.string,
    barcode: types.maybe(types.string),
    externalLink: types.maybe(types.string),
    logoId: types.string,
    currencyId: types.string,
    imageIds: types.array(types.string),
    number: types.number,
    createdAt: types.string,
    isBlocked: types.boolean,
    price: types.number,
    weight: types.number,
    responsiblePersonId: types.maybeNull(types.string),
    organisationId: types.maybe(types.string),
});

export const ProductListItem = types.model({
    product: Product,
    currency: Currency,
    logo: File,
});
