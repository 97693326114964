import { CreateParams, GetParams, Country, Countries } from './interfaces';
import { BaseService } from '../base/BaseService';

export class CountryService extends BaseService {
    private readonly url: string = `${this.baseUrl}/country`;

    public get = (params: GetParams): Promise<Country> =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public getList = (): Promise<Countries> =>
        this.http.get(`${this.url}/list`).then((res) => res.data);

    public create = (params: CreateParams) =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public disable = (_id: string) =>
        this.http.patch(`${this.url}/disable`, { params: { _id } }).then((res) => res.data);
}
