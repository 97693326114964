import * as React from 'react';
import { Button, Input, Typography } from 'antd';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import { setToken } from '../../../../../modules/api/serviceInitializer';
import { useUser } from '../../../../../context/UserProvider';

const { Text, Title } = Typography;

interface Props {
    store: any;
    onAuth: () => void;
}

export const AuthForm: React.FC<Props> = observer(({ store, onAuth }) => {
    const { setUser } = useUser();

    const onSubmit = async () => {
        const { token, user } = await store.login();
        Cookies.set('auth_token', token || '');
        Cookies.set('user', JSON.stringify(user));
        setUser(user);
        const savedToken = Cookies.get('auth_token');
        if (savedToken?.length) {
            setToken(token);
            onAuth();
        }
    };

    const isValid = store.username.value.length > 3 && store.password.value.length > 3;

    return (
        <div className={'authForm'}>
            <Title className={'authFormTitle'}>Авторизация</Title>

            <div className={'authInput'}>
                <Input
                    bordered={false}
                    size="large"
                    placeholder="Адрес электронной почты"
                    allowClear
                    onChange={(e) => store.username.change(e.target.value)}
                    value={store.username.value}
                />
            </div>
            <div className={'authInput'}>
                <Input.Password
                    bordered={false}
                    size="large"
                    placeholder="Пароль"
                    allowClear
                    onChange={(e) => store.password.change(e.target.value)}
                    value={store.password.value}
                />
            </div>
            <div className={'authFormButtonContent'}>
                <Button
                    className={'authButton'}
                    size={'large'}
                    type={'primary'}
                    disabled={!isValid}
                    onClick={onSubmit}
                    loading={store.isSending.value}
                >
                    <Text className={'authButtonText'} strong>
                        Войти в систему
                    </Text>
                </Button>
            </div>
        </div>
    );
});
