import { SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';

export const Indicators = types.model({
    isLoadingProduct: Indicator,
    isLoadingCurrency: Indicator,
    isLoadingImages: Indicator,
    isLoadingResponsiblePerson: Indicator,
    isManageMode: Indicator,
    isChangingResponsiblePerson: Indicator,
    isVisibleEditButton: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoadingProduct: defaultIndicatorSnapshot,
    isLoadingCurrency: defaultIndicatorSnapshot,
    isLoadingImages: defaultIndicatorSnapshot,
    isLoadingResponsiblePerson: defaultIndicatorSnapshot,
    isManageMode: defaultIndicatorSnapshot,
    isChangingResponsiblePerson: defaultIndicatorSnapshot,
    isVisibleEditButton: defaultIndicatorSnapshot,
};
