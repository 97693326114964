import React from 'react';
import { Spinner } from '../../../Page/components/Spinner';
import { Tint } from '../components/Tint';
import { Typography } from 'antd';
import { Instance } from 'mobx-state-tree';
import { StatisticsView } from '../../../../modules/business-logic/stores/Statistics';
import { observer } from 'mobx-react';

const { Text } = Typography;

interface Props {
    store: any;
}

export const Content: React.FC<Props> = observer(({ store }) => {
    const { statistics }: Instance<typeof StatisticsView> = store;
    const isLoading = store.indicators.isLoading.value;

    if (isLoading || !statistics) {
        return <Spinner />;
    }

    return (
        <>
            <div className={'statsCards'}>
                <div className={'statsCell'}>
                    <Text type={'secondary'}>
                        Создано пользоватей{' '}
                        <Tint description={'Количество созданных пользователей на проекте'} />
                    </Text>
                    <Text className={'cellValue'}>{statistics.usersCount}</Text>
                </div>
                <div className={'statsCell statsCellSuccess'}>
                    <Text type={'secondary'}>
                        Накладные <Tint description={'Количество накладных'} />
                    </Text>
                    <Text className={'cellValue cellValueSuccess'}>
                        {statistics.totalWayBillsCount}
                    </Text>
                </div>
                <div className={'statsCell statsCellError'}>
                    <Text type={'secondary'}>
                        Товары{' '}
                        <Tint
                            description={'Общее количество товаров, созданных за указанное время'}
                        />
                    </Text>
                    <Text className={'cellValue cellValueError'}>
                        {statistics.totalProductsCount}
                    </Text>
                </div>
                {/*<div className={'statsCell'}>*/}
                {/*    <Text type={'secondary'}>*/}
                {/*        Всего устройств{' '}*/}
                {/*        <Tint description={'Общее количество зарегистрированных устройств'} />*/}
                {/*    </Text>*/}
                {/*    <Text className={'cellValue'}>{statistics.deviceCount}</Text>*/}
                {/*</div>*/}
                {/*<div className={'statsCell'}>*/}
                {/*    <Text type={'secondary'}>*/}
                {/*        Устройств в сети{' '}*/}
                {/*        <Tint*/}
                {/*            description={*/}
                {/*                'Общее количество устройств, с которых были получены данные в течении 10 минут'*/}
                {/*            }*/}
                {/*        />*/}
                {/*    </Text>*/}
                {/*    <Text className={'cellValue'}>0</Text>*/}
                {/*</div>*/}
                {/*<div className={'statsCell'}>*/}
                {/*    <Text type={'secondary'}>*/}
                {/*        Общий доход <Tint description={'Общий доход за предоставленные услуги'} />*/}
                {/*    </Text>*/}
                {/*    <Text className={'cellValue'}>{statistics.totalSum}</Text>*/}
                {/*</div>*/}
            </div>
        </>
    );
});
