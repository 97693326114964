import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';
import { notification } from 'antd';

interface Props {
    value: string;
}

export const Copy: React.FC<Props> = ({ value }) => {
    return (
        <CopyToClipboard
            text={value}
            onCopy={() =>
                notification.success({
                    message: 'Текст скопирован!',
                    duration: 1,
                })
            }
        >
            <CopyOutlined className={'copyIcon'} style={{ paddingLeft: 8 }} />
        </CopyToClipboard>
    );
};
