import { CreateParams, DownloadParams } from './interfaces';
import { BaseService } from '../base/BaseService';

export class FileService extends BaseService {
    private readonly url: string = `${this.baseUrl}/attachment`;

    public get = (params: DownloadParams) =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public upload = (params: CreateParams) =>
        this.http.post(`${this.url}/upload`, params).then((res) => res.data);

    public disable = (_id: string) =>
        this.http.patch(`${this.url}/disable`, { params: { _id } }).then((res) => res.data);
}
