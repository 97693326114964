import * as React from 'react';
import { useEffect, useState } from 'react';
import serviceInitializer from "../../../../modules/api/serviceInitializer";
import {Product} from "../../../../modules/api/product/interfaces";
import {isAdmin} from "../../../../context/UserProvider";
import {createReactModal, Modal} from "../../../ModalWindow/AntModal/createAntModal";
import {AccountService} from "../../../../modules/api/account";
import {
    RemoveCategoryStore,
    defaultRemoveCategoryStoreSnapshot
} from "../../../../modules/business-logic/stores/Category/Remove";
import {CategoryService} from "../../../../modules/api/category";
import {Content} from "./Content";

interface Params {
    _id: string;
    updateList: () => void;
}

type Props = Params & Modal<Product | {}>;

export const RemoveCategoryModal: React.FC<Props> = (props) => {
    const onClose = () => props.modal.onClose({});
    const [store, setStore] = useState<any>(null);
    useEffect(() => {
        const store = RemoveCategoryStore.create(defaultRemoveCategoryStoreSnapshot, {
            categoryService: serviceInitializer<CategoryService>(CategoryService),
        });
        setStore(store);
    }, []);

    if (!store) return null;

    const submit = async () => {
        try {
            const res = await store.remove(props._id);
            props.modal.onClose(res);
            props.updateList();
        } catch (err) {
            console.error(err)
        }
    };

    return <Content onClose={onClose} store={store} onSubmit={submit} />;
};

//@ts-ignore
export const removeCategory = (params: Params) => createReactModal(RemoveCategoryModal, params);
