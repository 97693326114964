import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';

import serviceInitializer from '../../../modules/api/serviceInitializer';
import './styles.css';
import { List } from './components/List';
import {
    defaultAccountListSnapshot,
    Environment,
    AccountList as AccountListStore,
} from '../../../modules/business-logic/stores/Users';
import { PlusOutlined } from '@ant-design/icons';
import { CreateAccountModal } from './Create';
import { AccountService } from '../../../modules/api/account';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export const AccountList: React.FC = () => {
    const { t } = useTranslation();
    const [isVisibleCreateModal, openCreateModal] = useState(false);
    const [store, setStore] = useState<any>(defaultAccountListSnapshot);
    useEffect(
        () =>
            setStore(
                AccountListStore.create(defaultAccountListSnapshot, {
                    accountService: serviceInitializer<AccountService>(AccountService),
                } as Environment),
            ),
        [],
    );

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('accountList:accounts')}</Title>
                <Button
                    className={'titleButton'}
                    icon={<PlusOutlined />}
                    onClick={() => openCreateModal(!isVisibleCreateModal)}
                    size={'large'}
                >
                    {t('accountList:addAccount')}
                </Button>
            </div>

            <List store={store} />

            {isVisibleCreateModal && (
                <CreateAccountModal
                    onClose={() => openCreateModal(!isVisibleCreateModal)}
                    updateList={store.getList}
                />
            )}
        </div>
    );
};
