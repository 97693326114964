import { SnapshotIn, types } from 'mobx-state-tree';

export const Currency = types.model({
    _id: types.string,
    value: types.string,
    description: types.string,
});

export const defaultCurrencySnapshot: SnapshotIn<typeof Currency> = {
    _id: '',
    value: '',
    description: '',
};
