import {flow, getEnv, SnapshotIn, types} from 'mobx-state-tree';
import {defaultIndicatorsSnapshot, Indicators} from "./models";
import {Environment} from "../Base";

export const BlockedOrganisationStore = types.model({
    indicators: Indicators,
}).actions(self => {
    const { organisationServices } = getEnv<Environment>(self);

    const blocked = flow(function* (_id: string) {
        self.indicators.isSending.set(true);
        try {
            return yield organisationServices.blocked({
                _id
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    const unblocked = flow(function* (_id: string) {
        self.indicators.isSending.set(true);
        try {
            return yield organisationServices.unblocked({
                _id
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    return {
        blocked,
        unblocked
    }
})

export const defaultBlockedOrganisationStoreSnapshot: SnapshotIn<typeof BlockedOrganisationStore> = {
    indicators: defaultIndicatorsSnapshot,
}
