import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import './styles.css';

interface Props {}

export const Spinner: React.FC<Props> = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return (
        <div className={'spinnerContainer'}>
            <Spin indicator={antIcon} />
        </div>
    );
};
