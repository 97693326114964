import { cast, flow, getEnv, Instance, onSnapshot, SnapshotIn, types } from 'mobx-state-tree';
import { Role } from '../../../../api/account/interfaces';
import { Product } from '../../../../api/product/interfaces';
import { ProductService } from '../../../../api/product';
import { FileService } from '../../../../api/attachment';
import { WayBillService } from '../../../../api/waybill';
import { AccountService } from '../../../../api/account';
import { CurrencyService } from '../../../../api/currency';
import { defaultTextFieldSnapshot, TextField } from '../../common/TextField';
import { defaultIndicatorsSnapshot, Indicators, ProductItem } from './models';
import { User } from '../../Users/models';
import { Currency } from '../../Currency';
import { defaultNumberFieldSnapshot, NumberField } from '../../common/NumberField';
import { toJS } from 'mobx';
import {
    defaultMeasuresSnapshot,
    Measures,
} from '../../../../../components/pages/WayBillCreate/common/Measures';

export interface Environment {
    isAdmin: boolean;
    updateList: () => void;
    productService: ProductService;
    fileService: FileService;
    wayBillService: WayBillService;
    accountService: AccountService;
    currencyService: CurrencyService;
}

export const CreateWayBillStore = types
    .model({
        numberOrder: TextField,
        label: TextField,
        totalWeight: NumberField,
        measures: Measures,
        products: types.array(ProductItem),
        currencies: types.array(Currency),
        selectedCurrency: types.maybeNull(types.string),
        responsiblePersons: types.array(User),
        selectedResponsiblePersonId: types.maybeNull(types.string),
        indicators: Indicators,
    })
    .actions((self) => {
        const {
            isAdmin,
            accountService,
            fileService,
            currencyService,
            updateList,
            wayBillService,
        } = getEnv<Environment>(self);

        const getCurrencies = flow(function* () {
            self.indicators.isGettingCurrencies.set(true);
            try {
                self.currencies = yield currencyService.getList();

                const savedCurrencyId = localStorage.getItem('currencyId');
                if (savedCurrencyId) self.selectedCurrency = savedCurrencyId;
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingCurrencies.set(false);
            }
        });

        const setCurrency = async (_id: string) => {
            self.selectedCurrency = _id;
            self.indicators.enableAddProductButton.set(Boolean(self.selectedCurrency));

            if (self.selectedCurrency)
                await localStorage.setItem('currencyId', self.selectedCurrency);
            // if (savedCurrencyId) self.selectedCurrency = savedCurrencyId
        };

        const addProduct = flow(function* (product: Product) {
            self.indicators.isAddingProduct.set(true);
            try {
                const productClone = toJS(product);

                const foundedProduct = self.products.find((p) => p.product._id === product._id);
                if (foundedProduct) {
                    foundedProduct.setCount(foundedProduct.count + 1);
                    return;
                }

                const newProductItem: Instance<typeof ProductItem> = {
                    product: {
                        ...productClone,
                        //@ts-ignore
                        imageIds: productClone.imageIds,
                    },
                    image: yield fileService.get({ _id: productClone.logoId }),
                    count: 1,
                    totalPrice: productClone.price,
                };

                self.products.unshift(cast(newProductItem));
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isAddingProduct.set(false);
            }
        });

        const removeProduct = (_id: string) => {
            const founded = self.products.filter((info) => info.product._id !== _id);
            self.products.replace(founded);
        };

        const getResponsiblePersons = flow(function* () {
            self.indicators.isGettingUsers.set(true);
            try {
                self.responsiblePersons = yield accountService.getList({
                    roles: Role.OPERATOR,
                });
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingUsers.set(false);
            }
        });

        const afterCreate = flow(function* () {
            // self.indicators.isGettingProducts.set(true);
            try {
                yield getCurrencies();
                if (isAdmin) {
                    self.indicators.isVisibleResponsiblePersons.set(true);
                    yield getResponsiblePersons();
                }
                self.indicators.enableCurrencySelect.set(!Boolean(self.products.length));
                onSnapshot(self.products, () =>
                    self.indicators.enableCurrencySelect.set(!Boolean(self.products.length)),
                );
                self.indicators.enableAddProductButton.set(Boolean(self.selectedCurrency));
            } catch (err) {
                console.error(err);
            } finally {
                // self.indicators.isGettingProducts.set(false);
            }
        });

        const selectResponsiblePerson = (id: string) => {
            self.selectedResponsiblePersonId = id;
        };

        const checkValid = () => {
            const isValidNumberOrder = Boolean(self.numberOrder.value.length);
            const isValidTotalWeight = Boolean(self.totalWeight.value);
            const isValidCurrency = Boolean(self.selectedCurrency);
            const isValidSelectedProducts = Boolean(self.products.length);
            const isValidOperators = isAdmin ? self.selectedResponsiblePersonId : true;
            const isValidMeasures = self.measures.checkValid();
            return (
                isValidNumberOrder &&
                isValidTotalWeight &&
                isValidSelectedProducts &&
                isValidCurrency &&
                isValidOperators &&
                isValidMeasures
            );
        };

        const getTotalPrice = () =>
            self.products.reduce((acc, item) => item.product.price * item.count + acc, 0);

        const create = flow(function* () {
            self.indicators.isCreatingWayBill.set(true);
            try {
                const responsiblePersonId = isAdmin
                    ? self.selectedResponsiblePersonId?.toString()
                    : undefined;
                const res = yield wayBillService.create({
                    numberOrder: self.numberOrder.value.trim(),
                    label: self.label.value.trim(),
                    totalWeight: self.totalWeight.value,
                    currencyId: self.selectedCurrency || '',
                    productIds: self.products.map((info) => ({
                        _id: info.product._id,
                        count: info.count,
                    })),
                    responsiblePersonId,
                    width: self.measures.width.value,
                    height: self.measures.height.value,
                    length: self.measures.length.value,
                });
                updateList();
                return res;
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isCreatingWayBill.set(false);
            }
        });

        return {
            afterCreate,
            addProduct,
            getTotalPrice,
            removeProduct,
            selectResponsiblePerson,
            setCurrency,
            checkValid,
            create,
        };
    });

export const defaultCreateWayBillStore: SnapshotIn<typeof CreateWayBillStore> = {
    numberOrder: defaultTextFieldSnapshot,
    label: defaultTextFieldSnapshot,
    totalWeight: defaultNumberFieldSnapshot,
    products: [],
    measures: defaultMeasuresSnapshot,
    // selectedProducts: [],
    indicators: defaultIndicatorsSnapshot,
};
