import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Select, Typography } from 'antd';
import { ListIcon } from '../Icons/List';
import './styles.css';
import { useState } from 'react';
import i18next from 'i18next';
import { Languages } from '../../modules/i18next/interfaces';
import { EnFlagIcon } from './IconLanguages/en';
import { RuFlagIcon } from './IconLanguages/ru';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const getMenuItems = (t: any) => [
    {
        label: t('header:products'),
        icon: <ListIcon />,
        href: '/product-list',
    },
];

export const NotAuthorizedHeader: React.FC = () => {
    const { t } = useTranslation();
    const [lang, setLang] = useState(i18next.language);

    const changeLanguage = async (newLang: Languages | string) => {
        try {
            await localStorage.setItem('language', newLang);
            await i18next.changeLanguage(newLang);
            setLang(i18next.language);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className={'header'}>
            <div className={'headercontent'}>
                <div className={'menu'}>
                    {getMenuItems(t).map((item, index) => (
                        <NavLink
                            key={index}
                            to={item.href}
                            className={'item link'}
                            style={({ isActive }) =>
                                isActive
                                    ? {
                                          background: '#191d30',
                                          boxShadow: 'inset 0px -4px 0px 0px #FF8551',
                                          textDecoration: 'none',
                                      }
                                    : {}
                            }
                        >
                            <div className={'icon'}>{item.icon}</div>
                            <Text className={'label'}>{item.label}</Text>
                        </NavLink>
                    ))}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        style={{ marginTop: 8 }}
                        // showSearch
                        // placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={changeLanguage}
                        // onSearch={onSearch}
                        // filterOption={filterOption}
                        // defaultValue={lang}
                        value={lang}
                        bordered={false}
                        options={[
                            {
                                value: Languages.English,
                                label: <EnFlagIcon />,
                            },
                            {
                                value: Languages.Russian,
                                label: <RuFlagIcon />,
                            },
                        ]}
                    />
                    <div className={'user'}>
                        <Link to="/auth" className={'nameHeader link'}>
                            <UserOutlined />
                            <Text style={{ color: '#fff', marginLeft: 8 }}>
                                {i18next.t('header:login')}
                            </Text>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
