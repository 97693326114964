import { useEffect, useState, useRef } from 'react';
import type { MouseEventHandler } from "react";
import { getMainRole, useUser } from '../../../context/UserProvider';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Typography } from 'antd';
import './styles.css';
import { Roles } from '../../../modules/api/auth/interfaces';


const { Text } = Typography;

type MenuItems = {
  label: string,
  icon: JSX.Element,
  href: string,
  roles: Roles[]
}


interface IDropDownMenuProps {
    onClose?: () => void;
    menuData: MenuItems[];
}


const DropDownMenu = ((props: IDropDownMenuProps) => {
    const { onClose } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { user, setUser } = useUser();
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
          const { target } = event;
          if (target instanceof Node && !rootRef.current?.contains(target)) {
            isOpen && onClose?.();
            setIsOpen(false);
          }
        };

        window.addEventListener('click', handleClick);

        return () => {
          window.removeEventListener('click', handleClick);
        };
      }, [isOpen, onClose]);

      useEffect(() => {
        const userFromCookies = Cookies.get('user');
        if (!userFromCookies) return;
        const savedUser = JSON.parse(userFromCookies);
        setUser(savedUser);
    }, [setUser]);
    if (!user) return null;

    const handleButtonClick: MouseEventHandler<HTMLDivElement> = () => {
        setIsOpen((prev) => !prev);
      };

  return (
      <div className={'dropdown'}>
        {(props.menuData.length > 0) && <div
          className={'dropdownBtn'}
          ref={rootRef}
          onClick={(e) => handleButtonClick(e)}>
        </div>}
        <div className={"dropdownContent"}>
            {isOpen && (props.menuData.map(
                (item, index) =>
                item.roles.includes(getMainRole(user)) && (
                    <NavLink
                        key={index + Math.random()}
                        to={item.href}
                        className={'dropdownItem'}
                        style={({ isActive }) =>
                            isActive
                                ? {
                                    background: '#191d30',
                                    boxShadow: 'inset 0px -4px 0px 0px #FF8551',
                                    textDecoration: 'none',
                                }
                                : {}
                        }
                    >
                        <div className={'dropdownIcon'}>{item.icon}</div>
                        <Text className={'dropdownLabel'}>{item.label}</Text>
                    </NavLink>
                ),
            ))}
        </div>
      </div>
  );
}
);

export default DropDownMenu;
