export interface Account {
    _id: string;
    username: string;
    firstName: string;
    secondName: string;
    roles: string[];
    isBlocked: boolean;
    organisationId?: boolean;
}

export enum Role {
    DEVICE = 'device',
    OPERATOR = 'operator',
    ADMIN = 'admin',
}

export type Accounts = Account[];

export interface CreateParams {
    username: string;
    firstName: string;
    secondName: string;
    organisationId: string;
}

export interface LastLoginDate {
    _id: string;
    lastLoginDate: string;
}

export interface GetParams {
    _id: string;
}

export interface BlockParams {
    isBlocked: boolean;
}

export interface GetListParams {
    roles: Role;
}

export type EditParams = CreateParams & GetParams;

export type ResetPasswordParams = GetParams;

export interface Credentials {
    username: string;
    password: string;
}
