import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { Input, Modal, Select, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import {User} from "../../../../modules/business-logic/stores/Users/models";
import {Spinner} from "../../../Page/components/Spinner";
import {isAdmin, useUser} from "../../../../context/UserProvider";
import {OrganisationSelect} from "../../../OrganisationSelect";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
    store: any;
    editMode?: boolean;
    isAdmin: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({ store, onClose, onSubmit, isAdmin }) => {
    const { t } = useTranslation();
    const { title, description } = store;
    const isValid = store.isValid && store.isValid();

    // const getAccountsOptions = (): { value: string; label: string }[] => {
    //     const options = store.responsiblePersons.map((account: Instance<typeof User>) => ({
    //         value: account._id,
    //         label: `${account.firstName} ${account.secondName}`,
    //     }));
    //
    //     options.unshift({
    //         value: 0,
    //         label: t('common:notAssigned'),
    //     });
    //
    //     return options;
    // };
    //
    // const options = getAccountsOptions();

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('category:editingCategory')}
                </Text>
            }
            open
            onOk={onSubmit}
            onCancel={onClose}
            okButtonProps={{ disabled: !isValid }}
            cancelText={t('common:cancel')}
            okText={t('common:approve')}
            confirmLoading={store.indicators.isSending.value}
        >
            <div className={'modalBody'}>
                {!store.indicators.isGettingData.value && (
                    <>
                        <Input
                            placeholder={t('productCreate:title')}
                            size={'large'}
                            value={title.value}
                            onChange={(e) => title.change(e.target.value)}
                        />

                        <TextArea
                            placeholder={t('productCreate:description')}
                            size={'large'}
                            autoSize={{ minRows: 3, maxRows: 7 }}
                            value={description.value}
                            onChange={(e) => description.change(e.target.value)}
                            allowClear
                        />

                        {/*{isAdmin && (*/}
                        {/*    <OrganisationSelect*/}
                        {/*        store={store.organisations}*/}
                        {/*        isLoading={store.indicators.isGettingOrganisations.value}*/}
                        {/*        disabled={store.indicators.isGettingOrganisations.value}*/}
                        {/*        value={store.selectedResponsiblePersonId}*/}
                        {/*        set={store.setOrganisationId}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </>
                )}
                {store.indicators.isGettingData.value && <Spinner />}
            </div>
        </Modal>
    );
});
