import React, { useEffect, useState } from 'react';
import { isAdmin, useUser } from '../../../context/UserProvider';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { ProductService } from '../../../modules/api/product';
import { CurrencyService } from '../../../modules/api/currency';
import { FileService } from '../../../modules/api/attachment';
import { AccountService } from '../../../modules/api/account';
import { WayBillService } from '../../../modules/api/waybill';
import {
    CreateWayBillStore,
    defaultCreateWayBillStore,
    Environment,
} from '../../../modules/business-logic/stores/Consignment/Create';
import { Content } from './Content';
import './styles.css';

export const WayBillCreate = () => {
    const { user } = useUser();
    const [store, setStore] = useState<any>(defaultCreateWayBillStore);
    useEffect(
        () =>
            setStore(
                CreateWayBillStore.create(defaultCreateWayBillStore, {
                    isAdmin: isAdmin(user),
                    updateList: () => console.log('updateList'),
                    productService: serviceInitializer<ProductService>(ProductService),
                    fileService: serviceInitializer<FileService>(FileService),
                    wayBillService: serviceInitializer<WayBillService>(WayBillService),
                    accountService: serviceInitializer<AccountService>(AccountService),
                    currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                } as Environment),
            ),
        [],
    );

    if (!store) return null;

    return <Content store={store} />;
};
