import * as React from 'react';
import {CartItem} from "../../../context/CartProvider";
import {Button, Col, Image, InputNumber, Row, Typography} from "antd";
import {config} from "../../../modules/api/serviceInitializer";
import './styles.css';
import {useTranslation} from "react-i18next";

export interface UserReciever {
    name: string;
    secondName: string;
    thirdName: string;
    phone: string;
    mail: string;
    address: string;
}

interface Props {
    products: CartItem[];
    setUnpaid: () => void;
    date: string;
    user: UserReciever;
    totalPrice: number;
}

const { Text, Paragraph, Title } = Typography;

export const PaidCart: React.FC<Props> = ({ products, setUnpaid, date, user, totalPrice }) => {
    const { t } = useTranslation();

    const formatDate = (isoString: string): string => {
        const date: Date = new Date(isoString);

        const day: string = date.getDate().toString().padStart(2, '0');
        const month: string = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year: string = date.getFullYear().toString();
        const hours: string = date.getHours().toString().padStart(2, '0');
        const minutes: string = date.getMinutes().toString().padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    const HintValue: React.FC<{ hint: string, value: string }> = ({ hint, value }) => (
        <div className={'hintValue'}>
            <Text type={'secondary'} className={'hintPaidCart'}>{hint}</Text>
            <Text className={'valuePaidCart'}>{value}</Text>
        </div>
    )

    const totalBlockTextStyle = { fontSize: 14 };

    return (
        <div className={'paidCartBlock'}>
            <div className={'paidCartUserInfo'}>
                <Text strong>{t('cart:recieverInfo')}</Text>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <HintValue hint={t('cart:firstName')} value={user.name} />
                        <HintValue hint={t('cart:secondName')} value={user.secondName} />
                        <HintValue hint={t('cart:thirdName')} value={user.thirdName} />
                    </Col>
                    <Col span={16}>
                        <HintValue hint={t('cart:phoneNumber')} value={user.phone} />
                        <HintValue hint={t('cart:mailAddress')} value={user.mail} />
                        <HintValue hint={t('cart:deliveryAddress')} value={user.address} />
                    </Col>
                </Row>
            </div>

            <div className={'paidCartListProducts'}>
                <div className={'paidCardListItem'}>
                    <Text type={'secondary'} className={'paidCardListItemImage'}></Text>
                    <Text type={'secondary'} className={'paidCardListItemText'}>
                        {t('cart:title')}
                    </Text>
                    <Text type={'secondary'} className={'paidCardListItemPrice'}>
                        {t('cart:price')}
                    </Text>
                    <Text type={'secondary'} className={'paidCardListItemCount'}>
                        {t('cart:quantity')}
                    </Text>
                    <Text type={'secondary'} className={'paidCardListItemTotalPrice'}>
                        {t('cart:totalPrice')}
                    </Text>
                </div>
                {products.map((product, index) => (
                    <div className={'paidCardListItem'}>
                        <Image
                            className={'paidCardListItemImage'}
                            height={40}
                            src={`${config.baseURL + '/' + product.imageUrl}`}
                        />
                        <Paragraph
                            className={'paidCardListItemText'}
                            ellipsis={{ rows: 2 }}
                        >
                            {product.title}
                        </Paragraph>
                        <Text
                            className={'paidCardListItemPrice'}
                        >{`${product.price} THB`}</Text>
                        <Text
                            className={'paidCardListItemCount'}
                        >{`${product.count}`}</Text>
                        <div className={'paidCardListItemTotalPrice'}>
                            <Text>{`${product.price * product.count} THB`}</Text>
                        </div>
                    </div>
                ))}
            </div>
            <div className={'paidCartTotal'}>
                <Text strong>{t('cart:total')}</Text>
                <Row gutter={[16, 0]} style={{ fontSize: 10 }}>
                    <Col span={8}>
                        <Text style={totalBlockTextStyle}>{t('cart:toPayment')}</Text>
                    </Col>
                    <Col span={16}>
                        <Text style={totalBlockTextStyle}>{totalPrice} THB</Text>
                    </Col>
                    <Col span={8}>
                        <Text style={totalBlockTextStyle}>{t('cart:sumDelivery')}</Text>
                    </Col>
                    <Col span={16}>
                        <Text style={totalBlockTextStyle}>0 THB</Text>
                    </Col>
                    <Col span={8}>
                        <Text style={totalBlockTextStyle}>{t('cart:paymentType')}</Text>
                    </Col>
                    <Col span={16}>
                        <Text style={totalBlockTextStyle}>{t('cart:visaMasterCard')}</Text>
                    </Col>
                    <Col span={8}>
                        <Text style={totalBlockTextStyle}>{t('cart:datePayment')}</Text>
                    </Col>
                    <Col span={16}>
                        <Text style={totalBlockTextStyle}>{formatDate(date)}</Text>
                    </Col>
                    <Col span={8}>
                        <Text style={totalBlockTextStyle} strong>{t('cart:paid')}</Text>
                    </Col>
                    <Col span={16}>
                        <Text style={totalBlockTextStyle} strong>{totalPrice} THB</Text>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
