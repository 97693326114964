import { SnapshotIn, types } from 'mobx-state-tree';
import {defaultIndicatorSnapshot, Indicator} from "../common/Indicator";

export const Category = types.model({
    _id: types.string,
    value: types.string,
    description: types.string,
    blocked: types.boolean,
    createdAt: types.string,
    organisationId: types.maybe(types.string),
});

export const defaultCategorySnapshot: SnapshotIn<typeof Category> = {
    _id: '',
    value: '',
    description: '',
    blocked: false,
    createdAt: ''
};

export const Indicators = types.model({
    isLoading: Indicator,
    isEmpty: Indicator,
})

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
    isEmpty: defaultIndicatorSnapshot,
};
