import {flow, getEnv, SnapshotIn} from 'mobx-state-tree';
import {defaultTextFieldSnapshot} from "../../common/TextField";
import {defaultIndicatorsSnapshot} from "../Base/models";
import {Base, Environment} from "../Base";

export const CreateCountryStore = Base.actions(self => {
    const { isAdmin, countryService } = getEnv<Environment>(self);

    const create = flow(function* () {
        if (!isAdmin) {
            console.log('permission denied!');
            return;
        }
        self.indicators.isSending.set(true);
        try {
            return yield countryService.create({
                value: self.title.value,
                code: self.code.value,
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    return {
        create,
    }
})

export const defaultCreateCountryStoreSnapshot: SnapshotIn<typeof CreateCountryStore> = {
    title: defaultTextFieldSnapshot,
    code: defaultTextFieldSnapshot,
    disabled: false,
    indicators: defaultIndicatorsSnapshot,
}
