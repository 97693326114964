import * as React from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import { Button, DatePicker, Result, Typography, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { config } from '../../../../modules/api/serviceInitializer';
import { CurrencySelect } from '../../../CurrencySelect';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import enLocale from 'antd/es/date-picker/locale/en_US';
import { TransferWayBillsTable } from './TransferWayBillsTable';
import { ExportWayBillStore } from '../../../../modules/business-logic/stores/Consignment/Export';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Languages } from '../../../../modules/i18next/interfaces';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

interface Props {
    store: any;
}

export const Content: React.FC<Props> = observer(({ store }) => {
    const { t } = useTranslation();
    const locale = i18next.language === Languages.Russian ? ruLocale : enLocale;

    const { wayBills, selectedWayBillsIds, selectWayBills }: Instance<typeof ExportWayBillStore> =
        store;

    const disabledDate = (current: any) => {
        return current && current.isAfter(new Date(), 'day');
    };

    const onChangeDates = (_: any, dates: [string, string]) => {
        store.setDates(dates[0], dates[1]);
    };

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('waybill:unloadingRegistryInvoices')}</Title>
            </div>
            <div className={'waybillViewContent'}>
                <div style={{ display: 'flex', gap: 32 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 8 }}>
                        <Text type={'secondary'}>{t('common:chooseDate')}</Text>
                        <RangePicker
                            size={'large'}
                            disabledDate={disabledDate}
                            locale={locale}
                            onChange={onChangeDates}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 8 }}>
                        <Text type={'secondary'}>{t('common:chooseCurrency')}</Text>
                        <CurrencySelect
                            store={store.currencies}
                            isLoading={store.indicators.isGettingCurrencies.value}
                            setCurrency={store.setCurrency}
                            value={store.selectedCurrencyId}
                            disabled={false}
                        />
                    </div>
                </div>
                <div>
                    <Text type={'secondary'}>{t('waybill:chooseWaybills')}</Text>
                    <div style={{ padding: 4 }}></div>
                    <TransferWayBillsTable
                        currencies={store.currencies}
                        //@ts-ignore
                        wayBills={wayBills}
                        selectedWayBills={selectedWayBillsIds}
                        loading={store.indicators.isGettingWayBills.value}
                        selectWayBills={selectWayBills}
                    />
                </div>
                <div>
                    <Text type={'secondary'}>{t('waybill:addUnloadedFile')}</Text>
                    <div style={{ padding: 4 }}></div>
                    <Upload
                        action={`${config.baseURL}/api/way-bill/upload`}
                        listType="picture"
                        disabled={!store.indicators.enabledUploadButton.value}
                        defaultFileList={[]}
                        onChange={(e) => {
                            const _id = e.file.response?.response._id;
                            if (_id) store.uploadInputfile(_id);
                        }}
                        maxCount={1}
                        className="upload-list-inline"
                    >
                        <Button
                            icon={<UploadOutlined />}
                            size={'large'}
                            disabled={!store.indicators.enabledUploadButton.value}
                        >
                            {t('common:choosefile')}
                        </Button>
                    </Upload>
                    {store.inputFileId && (
                        <Text type={'secondary'}>{t('waybill:fileWasUploaded')}</Text>
                    )}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 48,
                    marginBottom: 24,
                }}
            >
                <Button
                    size={'large'}
                    type={'primary'}
                    disabled={!store || !store.checkValid || !store.checkValid()}
                    onClick={store.getExportFileLink}
                    loading={store.indicators.isExporting.value}
                >
                    {t('waybill:generateFile')}
                </Button>
            </div>
            {store.outputFilePath && (
                <Result
                    status="success"
                    title={
                        <Text style={{ fontWeight: 600, fontSize: 24, color: '#333' }}>
                            {`${t('common:success')}!`}
                        </Text>
                    }
                    subTitle={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 36 }}>
                            <Text type={'secondary'}>{t('waybill:downloadHint')}</Text>
                            <Button
                                style={{ height: 60, fontSize: 18 }}
                                icon={<DownloadOutlined />}
                                size={'large'}
                                type={'primary'}
                                onClick={() => window.open(store.outputFilePath, '_blank')}
                            >
                                {t('waybill:downloadUnloadFile')}
                            </Button>
                        </div>
                    }
                />
            )}
        </div>
    );
});
