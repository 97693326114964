import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import serviceInitializer from '../../../modules/api/serviceInitializer';

import {List} from "./components/List";
import './styles.css';
import {createCategory} from "../../modals/Category/Create";
import {isAdmin, useUser} from "../../../context/UserProvider";
import {
    Environment,
    CountriesStore,
    defaultCountriesStoreSnapshot
} from "../../../modules/business-logic/stores/Country/List";
import {CountryService} from "../../../modules/api/country";
import {createCountry} from "../../modals/Country/Create";

const { Title } = Typography;

export const CountryList: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useUser();
    const [store, setStore] = useState<any>(defaultCountriesStoreSnapshot);

    useEffect(
        () => {
            const store = CountriesStore.create(defaultCountriesStoreSnapshot, {
                isAdmin: isAdmin(user),
                countryService: serviceInitializer<CountryService>(CountryService),
            } as Environment);

            store.loadData();
            setStore(store);
        },
        [],
    );

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('Страны')}</Title>
                <Button
                    className={'titleButton'}
                    icon={<PlusOutlined />}
                    onClick={() => createCountry({
                        updateList: store.loadData,
                        user,
                    })}
                    size={'large'}
                >
                    {t('Добавить страну')}
                </Button>
            </div>

            <List store={store} updateList={store.loadData} />
        </div>
    );
};
