import {BaseService} from '../base/BaseService';
import {CreateParams, CreateResponse, GetByIdParams, GetByIdResponse, PaymentStatuses} from "./interfaces";

export class PaymentStatusService extends BaseService {
    private readonly url: string = `${this.baseUrl}/payment-status`;

    public create = (params: CreateParams): Promise<CreateResponse> =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public getById = (params: GetByIdParams): Promise<GetByIdResponse> =>
        this.http.get(`${this.url}/?id=${params.id}`).then((res) => res.data);

    public getList = (): Promise<PaymentStatuses> =>
        this.http.get(`${this.url}/list`).then((res) => res.data);
}
