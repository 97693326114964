import { flow, getEnv, SnapshotIn } from 'mobx-state-tree';
import { defaultTextFieldSnapshot } from '../../common/TextField';
import { defaultIndicatorsSnapshot } from '../Export/models';
import { CurrencyService } from '../../../../api/currency';
import { WayBillService } from '../../../../api/waybill';
import { config } from '../../../../api/serviceInitializer';
import { ExportWayBillStore } from '../Export';

interface Environment {
    currencyService: CurrencyService;
    wayBillService: WayBillService;
}

export const ExportWayBillStorePackList = ExportWayBillStore.actions((self) => {
    const { currencyService, wayBillService } = getEnv<Environment>(self);

    const checkValid = () => {
        const isValidDates =
            Boolean(self.dateFrom.value.length) && Boolean(self.dateTo.value.length);
        const isSelectedWayBills = Boolean(self.selectedWayBillsIds.length);
        const selectedCurrency = Boolean(self.selectedCurrencyId)
        const hasWayBills = Boolean(self.wayBills.length)

        return [isValidDates, selectedCurrency, hasWayBills].every((r) => Boolean(r));
    };

    const getExportFileLink = flow(function* () {
        self.indicators.isExporting.set(true);
        try {
            const { response } = yield wayBillService.exportPackList({
                wayBillIds: self.selectedWayBillsIds.reduce(
                    (acc, id) => `${acc}${acc.length ? ',' : ''}${id}`,
                    '',
                ),
            });

            self.outputFilePath = `${config.baseURL}/${response.outputFilePath}`;
        } catch (err) {
            console.log(err);
        } finally {
            self.indicators.isExporting.set(false);
        }
    });

    return {
        checkValid,
        getExportFileLink,
    };
});

export const defaultExportWayBillStorePackList: SnapshotIn<typeof ExportWayBillStore> = {
    dateFrom: defaultTextFieldSnapshot,
    dateTo: defaultTextFieldSnapshot,
    currencies: [],
    wayBills: [],
    selectedWayBillsIds: [],
    indicators: defaultIndicatorsSnapshot,
    totalCount: 0
};
