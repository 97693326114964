import * as React from 'react';
import { Select } from 'antd';
import { Instance } from 'mobx-state-tree';
import { Currency } from '../../modules/business-logic/stores/Currency';
import { useTranslation } from 'react-i18next';

interface Props {
    store: any;
    isLoading: boolean;
    disabled: boolean;
    value: string;
    setCurrency: (id: string) => void;
}

export const CurrencySelect: React.FC<Props> = ({
    store,
    isLoading,
    setCurrency,
    value,
    disabled,
}) => {
    const { t } = useTranslation();

    if (!store) return null;
    const getCurrenciesOptions = (): { value: string; label: string }[] => {
        const options = store.map((currency: Instance<typeof Currency>) => ({
            value: currency._id,
            label: `${currency.description} - ${currency.value}`,
        }));

        options.unshift({
            value: '',
            label: t('common:noSelect'),
        });

        return options;
    };

    return (
        <Select
            size={'large'}
            options={getCurrenciesOptions()}
            value={value}
            onChange={setCurrency}
            style={{ width: '100%' }}
            loading={isLoading}
            disabled={isLoading || disabled}
            placeholder={t('common:noSelect')}
        />
    );
};
