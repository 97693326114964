import React from 'react';

export const UserIcon: React.FC = () => {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 11C14.3 11 13.7083 10.7583 13.225 10.275C12.7417 9.79167 12.5 9.2 12.5 8.5C12.5 7.8 12.7417 7.20833 13.225 6.725C13.7083 6.24167 14.3 6 15 6C15.7 6 16.2917 6.24167 16.775 6.725C17.2583 7.20833 17.5 7.8 17.5 8.5C17.5 9.2 17.2583 9.79167 16.775 10.275C16.2917 10.7583 15.7 11 15 11ZM10 16V14.6C10 14.2 10.1042 13.8292 10.3125 13.4875C10.5208 13.1458 10.8167 12.9 11.2 12.75C11.8 12.5 12.4208 12.3125 13.0625 12.1875C13.7042 12.0625 14.35 12 15 12C15.65 12 16.2958 12.0625 16.9375 12.1875C17.5792 12.3125 18.2 12.5 18.8 12.75C19.1833 12.9 19.4792 13.1458 19.6875 13.4875C19.8958 13.8292 20 14.2 20 14.6V16H10ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.141667 12.1125 0.425 11.6375C0.708333 11.1625 1.1 10.8 1.6 10.55C2.6 10.05 3.6375 9.66667 4.7125 9.4C5.7875 9.13333 6.88333 9 8 9C8.58333 9 9.16667 9.05 9.75 9.15C10.3333 9.25 10.9167 9.36667 11.5 9.5L10.65 10.35L9.8 11.2C9.5 11.1167 9.2 11.0625 8.9 11.0375C8.6 11.0125 8.3 11 8 11C7.03333 11 6.0875 11.1167 5.1625 11.35C4.2375 11.5833 3.35 11.9167 2.5 12.35C2.33333 12.4333 2.20833 12.55 2.125 12.7C2.04167 12.85 2 13.0167 2 13.2V14H8V16H0ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z"
                fill="#B9B9B9"
            />
        </svg>
    );
};
