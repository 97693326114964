import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { Input, Modal, Select, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import {User} from "../../../../modules/business-logic/stores/Users/models";
import {Spinner} from "../../../Page/components/Spinner";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
    store: any;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({ store, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const isValid = store.isValid && store.isValid();

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('Удаление категории')}
                </Text>
            }
            open
            onOk={onSubmit}
            onCancel={onClose}
            cancelText={t('common:cancel')}
            okText={t(`Удалить`)}
            confirmLoading={store.indicators.isSending.value}
        >
            <div className={'modalBody'}>
                Вы действительно хотите удалить категорию?
            </div>
        </Modal>
    );
});
