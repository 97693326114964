import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { Button, Col, Input, InputNumber, Modal, Row, Select, Typography } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '../../../../../modules/business-logic/stores/Users/models';
import { Currency } from '../../../../../modules/business-logic/stores/Currency';

import { OnUploadAttachment, UploadManager } from '../../../../UploadManager';
import { SimilarNames } from '../SimilarNames';
import { config } from '../../../../../modules/api/serviceInitializer';
import { Spinner } from '../../../../Page/components/Spinner';
import { Category } from '../../../../../modules/business-logic/stores/Category/models';
import './styles.css';

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
    store: any;
    editMode?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({ store, onClose, onSubmit, editMode }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { t } = useTranslation();
    const { title, description, price, barcode, externalLink, weight } = store;
    const isValid = store.isValid && store.isValid();
    const titleInputRef = useRef(null);

    useEffect(() => {
        if (!store.images?.length) return;
        setFileList(
            store.images.map((image: any) => ({
                uid: image._id,
                name: image.name,
                status: 'done',
                url: config.baseURL + '/' + image.url,
            })),
        );
    }, [store.isLoadingData.value, store.images]);

    const getAccountsOptions = (): { value: string; label: string }[] => {
        const options = store.responsiblePersons.map((account: Instance<typeof User>) => ({
            value: account._id,
            label: `${account.firstName} ${account.secondName}`,
        }));

        options.unshift({
            value: 0,
            label: t('common:notAssigned'),
        });

        return options;
    };

    const getCurrenciesOptions = (): { value: string; label: string }[] => {
        const options = store.currencies.map((currency: Instance<typeof Currency>) => ({
            value: currency._id,
            label: `${currency.description} - ${currency.value}`,
        }));

        options.unshift({
            value: null,
            label: t('common:currency'),
        });

        return options;
    };

    const getCategoriesOptions = (): { value: string; label: string }[] => {
        const options = store.categories.map((category: Instance<typeof Category>) => ({
            value: category._id,
            label: `${category.value}`,
        }));

        options.unshift({
            value: 0,
            label: t('Выберите категорию'),
        });

        return options;
    };

    const setCurrency = (v: number) => {
        store.setCurrency(v);
    };

    const options = getAccountsOptions();
    const categoriesOptions = getCategoriesOptions();

    const onUpload = ({ list, active }: OnUploadAttachment) => {
        store.setLogoId(active);
        store.setAttachmentList(list);
        //@ts-ignore
        titleInputRef?.current?.focus();
    };

    const getActiveImage = (): string | null => {
        const file = fileList.find((file) => file.uid === store.logoId);
        if (!file || !file.url) return null;
        return file.url;
    };

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('productCreate:addingProduct')}
                </Text>
            }
            open={true}
            onOk={onSubmit}
            onCancel={onClose}
            okButtonProps={{ disabled: !isValid }}
            cancelText={t('common:cancel')}
            okText={t(`common:${editMode ? 'approve' : 'create'}`)}
            confirmLoading={store.isSending.value}
        >
            <div className={'modalBody'}>
                {!store.isLoadingData.value && (
                    <>
                        <UploadManager
                            onUpload={onUpload}
                            fileList={fileList}
                            setFileList={setFileList}
                            activeImage={store.logoId}
                            setActiveImage={store.setLogoId}
                        />
                        <Input
                            placeholder={t('productCreate:title')}
                            size={'large'}
                            value={title.value}
                            onChange={(e) => title.change(e.target.value)}
                            ref={titleInputRef}
                        />
                        <SimilarNames
                            onSelect={store.title.change}
                            disabledButton={store.yandexNames.disabledButton.value && !store.logoId}
                            url={getActiveImage()}
                        />

                        <div className={'divider'} />

                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <InputNumber<number>
                                    size={'large'}
                                    placeholder={t('productCreate:price')}
                                    max={1000001}
                                    min={1}
                                    style={{ width: '100%' }}
                                    value={price.value}
                                    onChange={(e) => price.change(e || 0)}
                                    disabled={store.isGettingProduct?.value}
                                />
                            </Col>
                            <Col span={16}>
                                <Select
                                    size={'large'}
                                    options={getCurrenciesOptions()}
                                    value={store.selectedCurrency}
                                    onChange={setCurrency}
                                    style={{ width: '100%' }}
                                    loading={store.isGettingCurrencies.value}
                                    disabled={
                                        store.isGettingCurrencies.value ||
                                        store.disabledCurrencySelect.value ||
                                        store.isGettingProduct?.value
                                    }
                                    placeholder={t('productCreate:currency')}
                                />
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} className={'createProductWeightLabel'}>
                            <Col span={8}>
                                <Text>{`${t('productCreate:weight')} (${t('common:kg')})`}</Text>
                            </Col>
                            <Col span={16}>
                                <InputNumber<number>
                                    size={'large'}
                                    placeholder={t('Вес')}
                                    max={1000001}
                                    min={0}
                                    style={{ width: '100%' }}
                                    value={weight.value}
                                    decimalSeparator={','}
                                    formatter={(value) => `${value}`.replace(/,/g, '.')}
                                    onChange={(value) => weight.change(value || 0)}
                                    disabled={store.isGettingProduct?.value}
                                />
                            </Col>
                        </Row>

                        <div className={'divider'} />

                        <Select
                            size={'large'}
                            options={categoriesOptions}
                            loading={store.isGettingCategories.value}
                            disabled={store.isGettingCategories.value}
                            placeholder={t('Категория')}
                            value={store.selectedCategory}
                            onChange={store.setCategory}
                        />

                        <Input
                            placeholder={t('productCreate:externalLink') + '(необязательно)'}
                            type={'url'}
                            size={'large'}
                            value={externalLink.value}
                            onChange={(e) => externalLink.change(e.target.value)}
                            disabled={store.isGettingProduct?.value}
                        />

                        <Input
                            placeholder={t('productCreate:barcode')}
                            type={'number'}
                            size={'large'}
                            value={barcode.value}
                            onChange={(e) => barcode.change(e.target.value)}
                            disabled={store.isGettingProduct?.value}
                        />

                        <div className={'divider'} />

                        <TextArea
                            placeholder={t('productCreate:description')}
                            size={'large'}
                            autoSize={{ minRows: 3, maxRows: 7 }}
                            value={description.value}
                            onChange={(e) => description.change(e.target.value)}
                            disabled={store.isGettingProduct?.value}
                            allowClear
                        />
                        <div className={'createProductActions'}>
                            <Button
                                onClick={store.generateDescription}
                                loading={store.isLoadingGenerateNameButton.value}
                                disabled={
                                    !store.title.value.length ||
                                    store.isLoadingGenerateNameButton.value
                                }
                            >
                                {t('productCreate:generate')}
                            </Button>
                        </div>

                        <div className={'divider'} />

                        {store.isVisibleOperatorsSelect.value && (
                            <Select
                                size={'large'}
                                options={options}
                                loading={store.isGettingUsers.value}
                                disabled={store.isGettingUsers.value}
                                placeholder={t('common:operator')}
                                value={store.selectedResponsiblePersonId}
                                onChange={store.setResponsiblePerson}
                            />
                        )}
                    </>
                )}
                {store.isLoadingData.value && <Spinner />}
            </div>
        </Modal>
    );
});
