import { DatePicker, Select } from 'antd';
import React from 'react';
import { Instance } from 'mobx-state-tree';
import { StatisticsView } from '../../../../modules/business-logic/stores/Statistics';
import { observer } from 'mobx-react';
import '../styles.css';
import i18next from 'i18next';
import { Languages } from '../../../../modules/i18next/interfaces';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import enLocale from 'antd/es/date-picker/locale/en_US';

interface Props {
    store: any;
}

const { RangePicker } = DatePicker;

export const Filter: React.FC<Props> = observer(({ store }) => {
    const { filter }: Instance<typeof StatisticsView> = store;
    const locale = i18next.language === Languages.Russian ? ruLocale : enLocale;

    const usersOptions = [
        {
            label: 'Все пользователи',
            value: null,
        },
        ...(store.filter.responsiblePersons
            ? store.filter.responsiblePersons.map((responsiblePerson: any) => ({
                  label: `${responsiblePerson.firstName} ${responsiblePerson.secondName}`,
                  value: responsiblePerson._id,
              }))
            : []),
    ];

    const getUserValue = () => {
        const selectedBranch = filter.selectedUser;
        if (typeof selectedBranch === 'string') {
            const option = usersOptions.find((option) => option.value === selectedBranch);
            return option || usersOptions[0];
        }
        return usersOptions[0];
    };

    const disabledDate = (current: any) => {
        return current && current.isAfter(new Date(), 'day');
    };

    const onChangeDates = (_: any, dates: [string, string]) => {
        store.onChangeDates(dates[0], dates[1]);
    };

    return (
        <div className={'statsFilter'}>
            <Select
                size={'large'}
                defaultValue={usersOptions[0]}
                loading={filter.indicators.isLoading.value}
                style={{ width: 228 }}
                value={getUserValue()}
                onChange={store.onSelectUser}
                options={usersOptions}
            />
            <RangePicker
                size={'large'}
                disabledDate={disabledDate}
                locale={locale}
                onChange={onChangeDates}
            />
        </div>
    );
});
