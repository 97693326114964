import {
    BlockParams,
    CreateParams,
    Product,
    GetParams,
    UpdateParams,
    GetListParams,
    ProductList,
} from './interfaces';
import { BaseService } from '../base/BaseService';

export class ProductService extends BaseService {
    private readonly url: string = `${this.baseUrl}/product`;

    public get = (params: GetParams): Promise<Product> =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public getList = (params?: GetListParams): Promise<ProductList> =>
        this.http.get(`${this.url}/list`, { params }).then((res) => res.data);

    public create = (params: CreateParams) =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data.response);

    public update = (number: number, params: UpdateParams) =>
        this.http.put(`${this.url}/update/${number}`, params).then((res) => res.data);

    public block = (number: number, params: BlockParams) =>
        this.http.patch(`${this.url}/block/${number}`, params).then((res) => res.data);
}
