import { isNull } from "lodash";
import { useEffect, useState } from "react";
import { Roles } from "../modules/api/auth/interfaces";
import { getMenuItems } from "../components/Header";
import { useTranslation } from "react-i18next";



type MenuItems = {
    label: string,
    icon: JSX.Element,
    href: string,
    roles: Roles[]
}

interface MenuManagementResponse {
    menuItemsLang: MenuItems[]
    dropDownLang: MenuItems[]
    isLoading: boolean
}

interface Params {
    menu:  MenuItems[]
    reductionThreshold: number
    additionThreshold: number
    marginRight: number
}

type UseMenuManagement = (params: Params) => MenuManagementResponse

export const useMenuManagement: UseMenuManagement = ({ menu, reductionThreshold, additionThreshold, marginRight}) => {
    const { t } = useTranslation();
    const [dropDown, setDropDown] = useState<MenuItems[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItems[]>(menu);
    const [isLoading, setLoading] = useState<boolean>(true);
    
    const handleResize = () => {
        if (!isNull(marginRight) && marginRight < reductionThreshold) {
            if (menuItems.length > 1) {
                const overflowItems = menuItems.slice(-1);
                setMenuItems(prev => prev.slice(0, -1));
                setDropDown(prev => prev.concat(overflowItems));
            }
        } 
        if (!isNull(marginRight) && marginRight > additionThreshold+200) {
            if (dropDown.length > 0) {
                const missingItems = dropDown.slice(-1);
                setDropDown(prev => prev.slice(0, -1))
                setMenuItems(prev => prev.concat(missingItems))
            }
        }
    };

    useEffect(() => {
        setLoading(true)

        handleResize();
        
        setLoading(false)
    }, [marginRight]);

  const menuItemsLang = menuItems.map((item, index) => { return getMenuItems(t)[index]})
  const dropDownLang = dropDown.map((item, index) => { return getMenuItems(t).reverse()[index]})
    return { menuItemsLang, dropDownLang, isLoading };
}