import {Empty} from 'antd';
import React from 'react';
import {observer} from 'mobx-react';
import './styles.css';
import {Spinner} from '../../../Page/components/Spinner';
import {OrganisationListItem} from './Item';
import {useTranslation} from 'react-i18next';
import {Instance} from "mobx-state-tree";
import {OrganisationStore} from "../../../../modules/business-logic/stores/Organisations/List";
import {toJS} from "mobx";

interface Props {
    store: Instance<typeof OrganisationStore>;
    updateList: () => void;
}

export const OrganisationList: React.FC<Props> = observer(({store, updateList}) => {
    const {t} = useTranslation();
    const isLoading = store.indicators.isLoading.value;
    const isEmpty = store.indicators.isEmpty.value;

    if (isLoading) {
        return <Spinner/>;
    }

    if (isEmpty) return <Empty style={{marginTop: 50}} description={t('common:noData')}/>;

    return (
        <div className={'list'}>
            <div className={'categoryListItemHeader'}>
                <div className={'value'}>{t('category:title')}</div>
                <div className={'actions'}>{''}</div>
            </div>
            {store.organisations &&
                store.organisations.map((item, index) => (
                    <OrganisationListItem
                        key={index}
                        _id={item._id}
                        value={item.title}
                        updateList={updateList}
                        isBlocked={item.isBlocked}
                    />
            ))}
        </div>
    );
});
