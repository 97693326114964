import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Select, Typography } from 'antd';
import Cookies from 'js-cookie';
import { setToken } from '../../modules/api/serviceInitializer';
import { Roles } from '../../modules/api/auth/interfaces';
import { getMainRole, useUser } from '../../context/UserProvider';
import { ConsignmentIcon } from '../Icons/Consignment';
import useAuth from '../../hooks/useAuth';
import { UserIcon } from '../Icons/User';
import { ListIcon } from '../Icons/List';
import './styles.css';
import i18next from 'i18next';
import { RuFlagIcon } from './IconLanguages/ru';
import { EnFlagIcon } from './IconLanguages/en';
import { Languages } from '../../modules/i18next/interfaces';
import { useTranslation } from 'react-i18next';
import { ChartIcon } from '../Icons/Chart';
import {CategoryIcon} from "../Icons/Categories";
import {OrganisationsIcon} from "../Icons/OrganisationsIcon";
import DropDownMenu from './DropDownMenu/DropDownMenu';
import { useMenuItemPaddingBetweenNextElement } from '../../hooks/UseMenuItemPaddingToNextElement';
import { useMenuManagement } from '../../hooks/UseMenuManagement';

const { Text } = Typography;

export const getMenuItems = (t: any) => [
    {
        label: t('header:products'),
        icon: <ListIcon />,
        href: '/product-list',
        roles: [Roles.ADMIN, Roles.OPERATOR],
    },
    {
        label: t('header:waybills'),
        icon: <ConsignmentIcon />,
        href: '/consignment-list',
        roles: [Roles.ADMIN, Roles.OPERATOR],
    },
    {
        label: t('category:categories'),
        icon: <CategoryIcon />,
        href: 'category-list',
        roles: [Roles.ADMIN, Roles.OPERATOR],
    },
    { label: t('header:accounts'), icon: <UserIcon />, href: '/users', roles: [Roles.ADMIN] },
    { label: t('header:statistics'), icon: <ChartIcon />, href: '/statistics', roles: [Roles.ADMIN] },
    { label: t('Страны'), icon: <ChartIcon />, href: '/country-list', roles: [Roles.ADMIN] },
    { label: t('header:organisations'), icon: <OrganisationsIcon />, href: '/organisations', roles: [Roles.ADMIN] },
    // { label: 'Статистика', icon: <ChartIcon />, href: '/statistics', roles: [Roles.ADMIN] },
];

export const Header: React.FC = () => {
    const { t } = useTranslation();
    const { user, setUser } = useUser();
    const [isOpenedMenu, openMenu] = useState<boolean>(false);
    const { isAuthenticated } = useAuth();
    const [lang, setLang] = useState(i18next.language);
    const [widthMenuItem, setWidthMenuItem] = useState<number>(0);
    const menuRef = useRef<HTMLDivElement>(null);
    const spacing = useMenuItemPaddingBetweenNextElement(menuRef);

    useEffect(() => {
    const children = menuRef.current?.children;
    if (children) {
        Array.from(children).map((item) => {
            return setWidthMenuItem(item.clientWidth)
        })
    }

    }, [spacing])

    const currentMarginInfo = {
        menu: getMenuItems(t),
        reductionThreshold: 50,
        additionThreshold: widthMenuItem,
        marginRight: spacing
    }

    const menuData = useMenuManagement(currentMarginInfo);

    const changeLanguage = async (newLang: Languages | string) => {
        try {
            await localStorage.setItem('language', newLang);
            await i18next.changeLanguage(newLang);
            setLang(i18next.language);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        try {
            const savedLanguage = localStorage.getItem('language');
            if (!savedLanguage) return;
            changeLanguage(savedLanguage);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        const userFromCookies = Cookies.get('user');
        if (!userFromCookies) return;
        const savedUser = JSON.parse(userFromCookies);
        setUser(savedUser);
    }, [setUser]);
    if (!user) return null;

    return (
        <div className={'header'}>
            <div className={'headercontent'}>
                <div ref={menuRef} className={'menu'}>
                    {menuData.menuItemsLang.map(
                        (item, index) =>
                            item.roles.includes(getMainRole(user)) && (
                                <NavLink
                                    key={index}
                                    to={item.href}
                                    className={'item link'}
                                    style={({ isActive }) =>
                                        isActive
                                            ? {
                                                  background: '#191d30',
                                                  boxShadow: 'inset 0px -4px 0px 0px #FF8551',
                                                  textDecoration: 'none',
                                              }
                                            : {}
                                    }
                                >
                                    <div className={'icon'}>{item.icon}</div>
                                    <Text className={'label'}>{item.label}</Text>
                                </NavLink>
                            ),
                    )}
                    <DropDownMenu menuData={menuData.dropDownLang}/>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        style={{ marginTop: 8 }}
                        // showSearch
                        // placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={changeLanguage}
                        // onSearch={onSearch}
                        // filterOption={filterOption}
                        // defaultValue={lang}
                        value={lang}
                        bordered={false}
                        options={[
                            {
                                value: Languages.English,
                                label: <EnFlagIcon />,
                            },
                            {
                                value: Languages.Russian,
                                label: <RuFlagIcon />,
                            },
                        ]}
                    />
                    <div className={'user'} onClick={() => openMenu(!isOpenedMenu)}>
                        {isAuthenticated && (
                            <>
                                <div className={'avatar'}></div>
                                <div
                                    className={'nameHeader'}
                                >{`${user?.firstName} ${user?.secondName}`}</div>
                                {isOpenedMenu && (
                                    <Link
                                        to="/logout"
                                        className={'headerMenuItem link'}
                                        onClick={() => {
                                            Cookies.remove('auth_token');
                                            Cookies.remove('user');
                                            setToken('');
                                        }}
                                    >
                                        <Text className={'headerMenuItemText'}>
                                            {t('header:logout')}
                                        </Text>
                                        <UploadOutlined rotate={90} />
                                    </Link>
                                )}
                            </>
                        )}
                        {!isAuthenticated && (
                            <Link to="/auth" className={'nameHeader link'}>
                                {i18next.t('header:login')}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
