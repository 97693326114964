import { useEffect, useState } from "react";

type UseMenuItemPaddingToNextElement = (ref: React.RefObject<HTMLDivElement>) => number

export const useMenuItemPaddingBetweenNextElement: UseMenuItemPaddingToNextElement = (ref) => {
    const [spacing, setSpacing] = useState<number>(30);

    useEffect(() => {
        const updateSpacing = () => {
            const element = ref.current;
            if (element) {
                const boundingRect = element.getBoundingClientRect();
                const nextSibling = element.nextElementSibling;

                if (nextSibling) {
                    const nextSiblingRect = nextSibling.getBoundingClientRect();
                    const marginRight = nextSiblingRect.left - boundingRect.right;
                    setSpacing(marginRight);
                }
            }
        };
        window.addEventListener("resize", updateSpacing);
        
        updateSpacing();
        const timeoutId = setTimeout(updateSpacing, 100);
        
        return () => {
            clearTimeout(timeoutId)
            window.removeEventListener("resize", updateSpacing)
        };
    }, [ref]);

    
    return Math.round(spacing/10)*10;   
};