import { types } from 'mobx-state-tree';

export const File = types.model({
    deletedAt: types.maybeNull(types.string),
    mimetype: types.string,
    name: types.string,
    size: types.string,
    uploadedAt: types.string,
    url: types.string,
    _id: types.maybe(types.string),
});
