import * as React from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import {Button, Empty, Image, Input, InputNumber, notification, Typography} from 'antd';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { config } from '../../../../modules/api/serviceInitializer';
import { createProduct } from '../../ProductList/components/Create';
import { isAdmin, useUser } from '../../../../context/UserProvider';
import { ProductItem as ProductItemModel } from '../../../../modules/business-logic/stores/Consignment/Create/models';
import { CurrencySelect } from '../../../CurrencySelect';
import { UserSelect } from '../../../UserSelect';
import { createModalProductList } from '../../ProductList/Modal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useCart} from "../../../../context/CartProvider";

const { Title, Text, Paragraph } = Typography;

interface Props {
    store: any;
}

export const Content: React.FC<Props> = observer(({ store }) => {
    const { user } = useUser();
    const { t } = useTranslation();
    const { addItemToCart, replaceItemsCart } = useCart();

    const totalPrice = store.getTotalPrice ? store.getTotalPrice() : 0;

    const createProductItem = async () => {
        const { result }: any = await createProduct({
            user,
            updateList: () => console.log('updateList'),
            currency: store.selectedCurrency,
        });
        //@ts-ignore
        const newProduct = result.value;
        if (newProduct) await store.addProduct(newProduct);
    };

    const addProductItem = async () => {
        const { result }: any = await createModalProductList({
            currencyId: store.selectedCurrency,
        });
        if (!result.value._id) return;
        await store.addProduct(result.value);
    };

    const getcurrencyById = (id: string) => {
        return store.currencies?.find((currency: any) => currency._id === id)?.value;
    };

    const addAllItemsToCartList = () => {
        // clearCart();
        console.log(store.toJSON())
        const items = store.products.map( ({ product, count, image, totalPrice }: Instance<typeof ProductItemModel>) => {
            return ({
                _id: product._id,
                title: product.title,
                price: product.price,
                currency: product.currencyId,
                imageUrl: image.url || '',
                count,
                totalPrice,
            });
        })

        console.log(items)

        replaceItemsCart(items);

        notification.success({
            message: t('common:success') + '!',
            description: t('productView:productWasAddedToCart'),
            duration: 2,
        });
    };

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{`${t('waybill:waybill')} #${store.number}`}</Title>
            </div>
            <div className={'waybillViewContent'}>
                <div className={'waybillFields'}>
                    <div className={'waybillViewFieldsLeft'}>
                        <div className={'waybillViewField'}>
                            <Text type={'secondary'}>{`${t('common:title')} (${t(
                                'common:optional',
                            )})`}</Text>
                            <Input
                                size={'large'}
                                value={store.label.value}
                                onChange={(e) => store.label.change(e.target.value)}
                                placeholder={t('common:enterData')}
                            />
                        </div>
                        <div className={'waybillViewField'}>
                            <Text type={'secondary'}>{t('common:numberOrder')}</Text>
                            <Input
                                size={'large'}
                                type={'number'}
                                value={store.numberOrder.value}
                                onChange={(e) => store.numberOrder.change(e.target.value)}
                                placeholder={t('common:enterData')}
                            />
                        </div>

                        {isAdmin(user) && (
                            <div className={'waybillViewField'}>
                                <Text type={'secondary'}>{t('common:operator')}</Text>
                                <UserSelect
                                    store={store.responsiblePersons}
                                    isLoading={store.indicators.isGettingUsers.value}
                                    set={store.selectResponsiblePerson}
                                    value={store.selectedResponsiblePersonId}
                                    disabled={false}
                                />
                            </div>
                        )}
                    </div>
                    <div className={'waybillViewFieldsRight'}>
                        <div className={'waybillViewField'}>
                            <Text type={'secondary'}>{t('common:chooseCurrency')}</Text>
                            <CurrencySelect
                                store={store.currencies}
                                isLoading={store.indicators.isGettingCurrencies.value}
                                setCurrency={store.setCurrency}
                                value={store.selectedCurrency}
                                disabled={!store.indicators.enableCurrencySelect.value}
                            />
                        </div>

                        <div className={'waybillViewField waybillViewFieldSize'}>
                            <div>
                                <Text type={'secondary'}>{`${t('waybill:totalWeight')} (${t(
                                    'common:kg',
                                )})`}</Text>
                                <InputNumber
                                    size={'large'}
                                    value={store.totalWeight.value}
                                    onChange={(e) => store.totalWeight.change(e || 0)}
                                    placeholder={t('common:enterData')}
                                    min={0}
                                    max={9000000}
                                />
                            </div>
                            <div className={'waybillViewFieldCloseIcon'}></div>
                            <div>
                                <Text type={'secondary'}>{`${t('waybill:height')} (${t(
                                    'common:cm',
                                )})`}</Text>
                                <InputNumber
                                    size={'large'}
                                    value={store.measures.height.value}
                                    onChange={(e) => store.measures.height.change(e || 0)}
                                    placeholder={t('common:enterData')}
                                    min={0}
                                    max={10000}
                                />
                            </div>
                            <div className={'waybillViewFieldCloseIcon'}>
                                <CloseOutlined />
                            </div>
                            <div>
                                <Text type={'secondary'}>{`${t('waybill:width')} (${t(
                                    'common:cm',
                                )})`}</Text>
                                <InputNumber
                                    size={'large'}
                                    value={store.measures.width.value}
                                    onChange={(e) => store.measures.width.change(e || 0)}
                                    placeholder={t('common:enterData')}
                                    min={0}
                                    max={10000}
                                />
                            </div>
                            <div className={'waybillViewFieldCloseIcon'}>
                                <CloseOutlined />
                            </div>
                            <div>
                                <Text type={'secondary'}>{`${t('waybill:length')} (${t(
                                    'common:cm',
                                )})`}</Text>
                                <InputNumber
                                    size={'large'}
                                    value={store.measures.length.value}
                                    onChange={(e) => store.measures.length.change(e || 0)}
                                    placeholder={t('common:enterData')}
                                    min={0}
                                    max={10000}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: 16,
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                        <Button
                            icon={<PlusOutlined />}
                            size={'large'}
                            onClick={createProductItem}
                            disabled={!store.indicators.enableAddProductButton.value}
                        >
                            {t('productList:addProduct')}
                        </Button>
                        <Button
                            size={'large'}
                            onClick={addProductItem}
                            disabled={!store.indicators.enableAddProductButton.value}
                        >
                            {t('common:chooseFromExists')}
                        </Button>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                        <Button size={'large'} onClick={addAllItemsToCartList}>{t('waybill:addAllItemsToCart')}</Button>
                        <Button
                            size={'large'}
                            type={'primary'}
                            disabled={!store || !store.checkValid || !store.checkValid()}
                            onClick={store.update}
                            loading={store.indicators.isCreatingWayBill.value}
                        >
                            {t('waybill:updateData')}
                        </Button>
                    </div>
                </div>
                {!store.products.length && (
                    <Empty style={{ margin: '50px 0 50px' }} description={t('common:listEmpty')} />
                )}
                {Boolean(store.products.length) && (
                    <div className={'waybillViewProducts'}>
                        <div className={'waybillViewProductHeader'}>
                            <div className={'waybillViewProductImage'} />
                            <Text className={'waybillViewProductText'}>{t('common:title')}</Text>
                            <Text className={'waybillViewProductPrice'}>{t('cart:price')}</Text>
                            <Text className={'waybillViewProductCount'}>{t('cart:quantity')}</Text>
                            <Text className={'waybillViewProductTotalPrice'}>
                                {t('cart:totalPrice')}
                            </Text>
                            <div className={'waybillViewProductActions'} />
                        </div>
                        {store.products.map((info: Instance<typeof ProductItemModel>) => (
                            <div className={'waybillViewProduct'}>
                                <Image
                                    className={'waybillViewProductImage'}
                                    height={60}
                                    src={`${config.baseURL}/${info.image.url}`}
                                />
                                <Paragraph
                                    className={'waybillViewProductText'}
                                    ellipsis={{ rows: 2 }}
                                >
                                    <Link
                                        to={'/product-view/' + info.product._id}
                                        style={{ color: '#333' }}
                                        target={'_blank'}
                                    >
                                        {info.product.title}
                                    </Link>
                                </Paragraph>
                                <Text className={'waybillViewProductPrice'}>{`${
                                    info.product.price
                                } ${getcurrencyById(info.product.currencyId)}`}</Text>
                                <InputNumber
                                    size={'large'}
                                    defaultValue={1}
                                    className={'waybillViewProductCount'}
                                    value={info.count}
                                    onChange={info.setCount}
                                />
                                <div className={'waybillViewProductTotalPrice'}>
                                    <Text>{`${info.totalPrice} ${getcurrencyById(
                                        info.product.currencyId,
                                    )}`}</Text>
                                </div>
                                <div className={'waybillViewProductActions'}>
                                    <DeleteOutlined
                                        onClick={() => store.removeProduct(info.product._id)}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className={'waybillViewProductFooter'}>
                            <div className={'waybillViewProductImage'} />
                            <div className={'waybillViewProductText'} />
                            <div className={'waybillViewProductPrice'} />
                            <div className={'waybillViewProductCount'}>
                                <Text strong>{t('cart:totalPrice')}</Text>
                            </div>
                            <div className={'waybillViewProductTotalPrice'}>
                                <Text strong>{`${totalPrice} ${getcurrencyById(
                                    store.selectedCurrency,
                                )}`}</Text>
                            </div>
                            <div className={'waybillViewProductActions'} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
