import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
    title?: string;
    description: string;
}

export const Tint: React.FC<Props> = ({ title, description }: Props) => (
    <Popover
        content={<Text>{description}</Text>}
        title={title}
        trigger="hover"
        overlayStyle={{
            maxWidth: 400,
        }}
    >
        <QuestionCircleOutlined />
    </Popover>
);
