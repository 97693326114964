import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { SnapshotIn } from 'mobx-state-tree';
import {
    defaultStatisticsViewSnapshot,
    StatisticsView,
} from '../../../modules/business-logic/stores/Statistics';
import { Content } from './Content';
import { Filter } from './Filter';
import './styles.css';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { StatisticsService } from '../../../modules/api/statistics';
import { AccountService } from '../../../modules/api/account';

const { Title } = Typography;

export const Statistics: React.FC = () => {
    const [store, setStore] = useState<SnapshotIn<typeof StatisticsView>>(
        defaultStatisticsViewSnapshot,
    );

    useEffect(
        () =>
            setStore(
                StatisticsView.create(defaultStatisticsViewSnapshot, {
                    statisticsService: serviceInitializer<StatisticsService>(StatisticsService),
                    accountService: serviceInitializer<AccountService>(AccountService),
                }),
            ),
        [],
    );
    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>Статистика</Title>
            </div>
            <Filter store={store} />
            <Content store={store} />
        </div>
    );
};
