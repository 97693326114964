import * as React from 'react';
import {Select} from 'antd';
import {Instance} from 'mobx-state-tree';
import {useTranslation} from 'react-i18next';
import {Country} from "../../modules/business-logic/stores/Country/country";

interface Props {
    store: Instance<typeof Country>[];
    isLoading: boolean;
    disabled: boolean;
    value: string;
    setCountry: (id: string) => void;
}

export const CountrySelect: React.FC<Props> = ({
                                                   store,
                                                   isLoading,
                                                   setCountry,
                                                   value,
                                                   disabled,
                                               }) => {
    const {t} = useTranslation();

    if (!store) return null;
    const getCountriesOptions = (): { value: string; label: string }[] => {
        const options = store.map((country: Instance<typeof Country>) => ({
            value: country._id,
            label: `${country.value} - ${country.code}`,
        }));

        options.unshift({
            value: '',
            label: t('common:noSelect'),
        });

        return options;
    };

    const options = getCountriesOptions()


    return (
        <Select
            size={'large'}
            options={options}
            value={value}
            onChange={setCountry}
            style={{width: '100%'}}
            loading={isLoading}
            disabled={isLoading || disabled}
            placeholder={t('common:noSelect')}
        />
    );
};
