import { SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorSnapshot, Indicator } from '../common/Indicator';

export const Statistics = types.model({
    usersCount: types.number,
    totalWayBillsCount: types.number,
    totalProductsCount: types.number,
});

export const IndicatorsFilter = types.model({
    isLoading: Indicator,
    isGettingUsers: Indicator,
});

export const Indicators = types.model({
    isLoading: Indicator,
    isGettingUsers: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
    isGettingUsers: defaultIndicatorSnapshot,
};

export const defaultIndicatorsFilterSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
    isGettingUsers: defaultIndicatorSnapshot,
};
