import * as React from 'react';
import {useState} from 'react';
import {
    Button,
    Col,
    DatePicker,
    Drawer,
    Empty,
    FloatButton,
    Image,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Typography,
} from 'antd';
import './styles.css';
import {useCart} from '../../context/CartProvider';
import {config} from '../../modules/api/serviceInitializer';
import {
    ArrowLeftOutlined,
    DeleteOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import useAuth from "../../hooks/useAuth";
import {PaidCart, UserReciever} from "./Paid";
import i18next from "i18next";
import {Languages} from "../../modules/i18next/interfaces";
import ruLocale from "antd/es/date-picker/locale/ru_RU";
import enLocale from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";

const { Text, Paragraph, Title } = Typography;

export const Cart: React.FC = () => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuth();
    const locale = i18next.language === Languages.Russian ? ruLocale : enLocale;

    const [isOpenedCart, openCart] = useState(false);
    const { cartItems, cartTotalPrice, removeItemFromCart, updateItemCount } = useCart();

    const [ paid, setPaid ] = useState(false);
    const [ date, setDate ] = useState<string>('2024-01-08T15:50:43.591Z');

    const [user, setUser] = useState<UserReciever>({
        name: '',
        secondName: '',
        thirdName: '',
        phone: '',
        mail: '',
        address: '18, 6 Udom Suk 31 Alley, Khwaeng Bang Chak, Khet Phra Khanong, Bangkok 10260',
    });

    const generateOrderString = () => {
        return Math.floor(Math.random() * 9000000000) + 1000000000;
    }

    const onPay = () => {
        if (isAuthenticated) {
            setPaid(true);
        } else {
            notification.error({
                message: t('common:error') + '!',
                description: `${t('common:smthWrong')}. ${t('common:gotoSupport')}!`,
                duration: 2,
            });
        }
    };

    return (
        <div>
            <FloatButton
                icon={<ShoppingCartOutlined />}
                tooltip={t('cart:cart')}
                onClick={() => openCart(true)}
            />
            <Drawer
                title={paid ? <div><Button icon={<ArrowLeftOutlined />} type={'default'} style={{ marginRight: 12 }} onClick={() => setPaid(false)}/>{t('cart:order')} {generateOrderString()}</div> : t('cart:cart')}
                width={'100%'}
                placement="right"
                onClose={() => openCart(false)}
                open={isOpenedCart}
            >
                {paid && (
                    <PaidCart products={cartItems} setUnpaid={() => setPaid(false)} date={date} user={user} totalPrice={cartTotalPrice}/>
                )}
                {!paid && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            {Boolean(!cartItems.length) && (
                                <Empty style={{ marginTop: 50 }} description={t('common:listEmpty')} />
                            )}
                            {Boolean(cartItems.length) && (
                                <div>
                                    <Title
                                        style={{
                                            margin: '0 0 16px',
                                            padding: 0,
                                            fontWeight: 900,
                                            fontSize: 32,
                                            color: '#333',
                                        }}
                                        level={4}
                                    >
                                        {t('cart:recieverInfo')}
                                    </Title>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} className={'cartContactInfoCol'}>
                                            <Input placeholder={t('cart:firstName')} size={'large'} value={user.name} onChange={(e) => setUser({ ...user, name: e.target.value })}/>
                                            <Input placeholder={t('cart:secondName')} size={'large'} value={user.secondName} onChange={(e) => setUser({ ...user, secondName: e.target.value })}/>
                                            <Input placeholder={t('cart:thirdName')} size={'large'} value={user.thirdName} onChange={(e) => setUser({ ...user, thirdName: e.target.value })}/>
                                        </Col>
                                        <Col span={12} className={'cartContactInfoCol'}>
                                            <Input placeholder={t('cart:phoneNumber')} size={'large'} value={user.phone} onChange={(e) => setUser({ ...user, phone: e.target.value })} />
                                            <Input placeholder={t('cart:mailAddress')} size={'large'} value={user.mail} onChange={(e) => setUser({ ...user, mail: e.target.value })} />
                                        </Col>
                                    </Row>
                                    <div className={'deliveryAddress'}>
                                        <Text type={'secondary'}>{t('cart:deliveryAddress')}</Text>
                                        <Select
                                            size={'large'}
                                            options={[{
                                                value: '18, 6 Udom Suk 31 Alley, Khwaeng Bang Chak, Khet Phra Khanong, Bangkok 10260',
                                                label: '18, 6 Udom Suk 31 Alley, Khwaeng Bang Chak, Khet Phra Khanong, Bangkok 10260'
                                            },{
                                                value: '15, 9 E Chaofah Rd, Talat Nuea, Mueang Phuket District, Phuket 83000',
                                                label: '15, 9 E Chaofah Rd, Talat Nuea, Mueang Phuket District, Phuket 83000'
                                            }]}
                                            defaultValue={'18, 6 Udom Suk 31 Alley, Khwaeng Bang Chak, Khet Phra Khanong, Bangkok 10260'}
                                            style={{ width: '100%' }}
                                            placeholder={t('cart:deliveryAddress')}
                                            onChange={(e) => setUser({ ...user, address: e })}
                                        />
                                    </div>
                                    {isAuthenticated && (
                                        <div className={'orderDate'}>
                                            <Text type={'secondary'}>{t('cart:dateOrder')}</Text>
                                            <DatePicker
                                                locale={locale}
                                                size={'large'}
                                                showTime
                                                onChange={e => setDate(e?.toDate().toISOString() || '')}
                                                value={dayjs(date)}
                                            />
                                        </div>
                                    )}
                                    <Title
                                        style={{
                                            margin: '48px 0 16px',
                                            padding: 0,
                                            fontWeight: 900,
                                            fontSize: 32,
                                            color: '#333',
                                        }}
                                    >
                                        {t('cart:selectedProducts')}
                                    </Title>
                                    <div className={'waybillViewProducts'}>
                                        <div className={'waybillViewProductHeader'}>
                                            <div className={'waybillViewProductImage'} />
                                            <Text className={'waybillViewProductText'}>
                                                {t('cart:title')}
                                            </Text>
                                            <Text className={'waybillViewProductPrice'}>
                                                {t('cart:price')}
                                            </Text>
                                            <Text className={'waybillViewProductCount'}>
                                                {t('cart:quantity')}
                                            </Text>
                                            <Text className={'waybillViewProductTotalPrice'}>
                                                {t('cart:totalPrice')}
                                            </Text>
                                            <div className={'waybillViewProductActions'} />
                                        </div>
                                        {cartItems.map((item: any) => (
                                            <div className={'waybillViewProduct'}>
                                                <Image
                                                    className={'waybillViewProductImage'}
                                                    height={60}
                                                    src={`${config.baseURL + '/' + item.imageUrl}`}
                                                />
                                                <Paragraph
                                                    className={'waybillViewProductText'}
                                                    ellipsis={{ rows: 2 }}
                                                >
                                                    {item.title}
                                                </Paragraph>
                                                <Text
                                                    className={'waybillViewProductPrice'}
                                                >{`${item.price} THB`}</Text>
                                                <InputNumber
                                                    size={'large'}
                                                    defaultValue={1}
                                                    className={'waybillViewProductCount'}
                                                    value={item.count}
                                                    onChange={(e) => updateItemCount(item._id, e)}
                                                    min={1}
                                                    max={100}
                                                />
                                                <div className={'waybillViewProductTotalPrice'}>
                                                    <Text>{`${item.price * item.count} THB`}</Text>
                                                </div>
                                                <div className={'waybillViewProductActions'}>
                                                    <DeleteOutlined
                                                        onClick={() => removeItemFromCart(item._id)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className={'waybillViewProductFooter'}>
                                            <div className={'waybillViewProductImage'} />
                                            <div className={'waybillViewProductText'} />
                                            <div className={'waybillViewProductPrice'} />
                                            <div className={'waybillViewProductCount'}>
                                                <Text strong>{t('common:total')}</Text>
                                            </div>
                                            <div className={'waybillViewProductTotalPrice'}>
                                                <Text strong>{`${cartTotalPrice} THB`}</Text>
                                            </div>
                                            <div className={'waybillViewProductActions'} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {Boolean(cartItems.length) && (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
                                <Button type={'primary'} size={'large'} onClick={onPay}>
                                    {t('cart:pay')}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Drawer>
        </div>
    );
};
