import React from 'react';
import { Button, ConfigProvider, Modal as AntModal, Result, Typography } from 'antd';
import { Product } from '../../../modules/api/product/interfaces';
import { createReactModal, Modal } from '../../ModalWindow/AntModal/createAntModal';
import { FileDoneOutlined, FormOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Params {
    navigate?: any;
}

type Props = Params & Modal<Product | {}>;
const { Text } = Typography;

export const WayBillExportModal: React.FC<Props> = (props) => {
    // const navigate = useNavigate();
    const { t } = useTranslation();

    const onClose = () => props.modal.onClose({});
    const exportRegistry = async () => {
        props.navigate('/waybill-export-registry');
        onClose();
    };
    const exportPackList = async () => {
        props.navigate('/waybill-export-pack-list');
        onClose();
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#ef5151',
                    colorInfo: '#14424d',
                    colorSuccess: '#c0ffa1',
                    colorWarning: '#ffe6b5',
                    colorError: '#ff5759',
                    colorLink: '#ffa654',
                    fontSize: 16,
                    wireframe: false,
                },
            }}
        >
            <AntModal
                open={true}
                // onOk={store.create}
                onCancel={onClose}
                footer={null}
            >
                <Result
                    icon={<FileDoneOutlined />}
                    title={
                        <Text style={{ fontWeight: 600, fontSize: 24, color: '#333' }}>
                            {t('waybill:unload')}
                        </Text>
                    }
                    subTitle={
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text type={'secondary'}>{t('waybill:unloadHint')}</Text>
                            <Button
                                style={{ height: 60, fontSize: 18, marginTop: 36 }}
                                icon={<FormOutlined />}
                                size={'large'}
                                type={'primary'}
                                onClick={exportRegistry}
                            >
                                Printing forms
                            </Button>
                            <Button
                                style={{ height: 60, fontSize: 18, marginTop: 12 }}
                                icon={<OrderedListOutlined />}
                                size={'large'}
                                onClick={exportPackList}
                            >
                                Packing list
                            </Button>
                        </div>
                    }
                />
                {/*<Space direction={'vertical'}>*/}
                {/*    <Text type={'secondary'}>Выгрузка накладных на основе файла из ЕК5 СДЕК</Text>*/}
                {/*    <Button size={'large'} type={'primary'}>Выгрузка реестра</Button>*/}
                {/*    <div></div>*/}
                {/*    <Text type={'secondary'}>Выгрузка списка товаров из накладных</Text>*/}
                {/*    <Button size={'large'}>Выгрузка списка товаров</Button>*/}
                {/*</Space>*/}
            </AntModal>
        </ConfigProvider>
    );
};

//@ts-ignore
export const createWayBillExportModal = (params: Params) =>
    createReactModal(WayBillExportModal, params);
