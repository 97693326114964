import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
import serviceInitializer from '../../../modules/api/serviceInitializer';
import {createOrganisations} from "../../modals/Organisations/Create";
import {isAdmin, useUser} from "../../../context/UserProvider";

import './styles.css';
import {OrganisationList} from "./components/List";
import {
    defaultOrganisationStoreSnapshot,
    OrganisationStore
} from "../../../modules/business-logic/stores/Organisations/List";
import {Instance} from "mobx-state-tree";
import {OrganisationService} from "../../../modules/api/organisation";

const {Title} = Typography;

export const Organisations: React.FC = () => {
    const {t} = useTranslation();
    const {user} = useUser();


    const [store, setStore] = useState<Instance<typeof OrganisationStore> | null>(null);

    useEffect(
        () => {
            const store = OrganisationStore.create(defaultOrganisationStoreSnapshot, {
                isAdmin: isAdmin(user),
                organisationServices: serviceInitializer<OrganisationService>(OrganisationService),
            });


            setStore(store);
        },
        [],
    );

    if(!store) return <div />

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('organisations:title')}</Title>
                <Button
                    className={'titleButton'}
                    icon={<PlusOutlined/>}
                    onClick={() => createOrganisations({
                        user,
                        updateList: store.getList
                    })}
                    size={'large'}
                >
                    {t('organisations:createOrganisation')}
                </Button>
            </div>

            <OrganisationList store={store} updateList={store.getList}/>
        </div>
    );
};
