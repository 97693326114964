import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Instance } from 'mobx-state-tree';
import { Card, Empty, List as ListAnt } from 'antd';
import { useTranslation } from 'react-i18next';

import { ProductListItem } from '../../../../modules/business-logic/stores/Product/models';
import { DEFAULT_PAGE_LIST_LENGTH } from '../../../../modules/business-logic/stores/Product/list';
import { config } from '../../../../modules/api/serviceInitializer';

import './styles.css';

interface Props {
    store: any;
}

const { Meta } = Card;

export const List: React.FC<Props> = observer(({ store }) => {
    const { t } = useTranslation();

    const isLoading = store.indicators.isLoading.value;
    const isEmpty = store.indicators.isEmpty.value;

    if (isEmpty)
        return <Empty style={{ margin: '50px 0 100px' }} description={t('common:noData')} />;

    const data = store.list.map(
        ({ product, logo, currency }: Instance<typeof ProductListItem>) => ({
            _id: product._id,
            price: `${product.price} ${currency.value}`,
            logoUrl: `${config.baseURL}/${logo.url}`,
            title: product.title,
        }),
    );

    return (
        <div className={'list'}>
            <ListAnt
                grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }}
                dataSource={data}
                loading={isLoading}
                pagination={{
                    onChange: async (e) => {
                        await store.getList(e);
                        window.scrollTo({ top: 0 });
                    },
                    total: store.totalCount,
                    pageSize: DEFAULT_PAGE_LIST_LENGTH,
                    showSizeChanger: false,
                }}
                renderItem={(item: any, index: any) => (
                    <ListAnt.Item key={index}>
                        <Link to={'/product-view/' + item._id}>
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt="example"
                                        src={item.logoUrl}
                                        style={{ maxHeight: '174px', objectFit: 'cover' }}
                                    />
                                }
                                style={{
                                    opacity: item.isBlocked ? 0.5 : 1,
                                }}
                            >
                                <Meta title={item.title} description={item.price} />
                            </Card>
                        </Link>
                    </ListAnt.Item>
                )}
            />
        </div>
    );
});
