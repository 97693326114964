import {flow, getEnv, SnapshotIn, types} from 'mobx-state-tree';
import {defaultIndicatorsSnapshot, Indicators} from "./models";
import {Country} from "../country";
import {CountryService} from "../../../../api/country";
import {isAdmin} from "../../../../../context/UserProvider";

export interface Environment {
    isAdmin: boolean;
    countryService: CountryService;
}

export const CountriesStore = types.model({
    indicators: Indicators,
    disablingId: types.string,
    list: types.array(Country),
}).actions(self => {
    const env = getEnv<Environment>(self);

    const disable = flow(function* () {
        try {
            yield env.countryService.getList();
        } catch (err) {
            console.error(err);
        }
    });

    const loadData = flow(function* () {
        if (!isAdmin) return;
        self.indicators.isLoading.set(true);
        try {
            self.indicators.isLoading.set(true);
            const data = yield env.countryService.getList();
            self.list.replace(data);

            self.indicators.isEmpty.set(!Boolean(self.list.length));
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isLoading.set(false);
        }
    });

    return {
        loadData
    }
})

export const defaultCountriesStoreSnapshot: SnapshotIn<typeof CountriesStore> = {
    indicators: defaultIndicatorsSnapshot,
    disablingId: '',
    list: []
};
