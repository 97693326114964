import {
    BlockParams,
    CreateParams,
    WayBill,
    WayBills,
    GetParams,
    UpdateParams,
    GetListParams,
    ExportParams,
    ExportPackListParams,
} from './interfaces';
import { BaseService } from '../base/BaseService';

export class WayBillService extends BaseService {
    private readonly url: string = `${this.baseUrl}/consignment`;

    public get = (params: GetParams): Promise<WayBill> =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public getList = (params?: GetListParams): Promise<WayBills> =>
        this.http.get(`${this.url}/list`, { params }).then((res) => res.data);

    public create = (params: CreateParams) =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public exportRegistry = (params: ExportParams) =>
        this.http.post(`${this.url}/export-registry`, params).then((res) => res.data);

    public exportPackList = (params: ExportPackListParams) =>
        this.http.post(`${this.url}/export-pack-list`, params).then((res) => res.data);

    public update = (number: number, params: UpdateParams) =>
        this.http.put(`${this.url}/update/${number}`, params).then((res) => res.data);

    public block = (number: number, params: BlockParams) =>
        this.http.patch(`${this.url}/block/${number}`, params).then((res) => res.data);

    public download = (filename: string) =>
        this.http.get(`${this.url}/download/${filename}`).then((res) => res.data);
}
