import { SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';

export const Indicators = types.model({
    isSending: Indicator,
    isGettingCurrencies: Indicator,
    enabledUploadButton: Indicator,
    isExporting: Indicator,
    isGettingWayBills: Indicator,
    isDatesSetted: Indicator,
    enableCurrencySelect: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isSending: defaultIndicatorSnapshot,
    isGettingCurrencies: defaultIndicatorSnapshot,
    isGettingWayBills: defaultIndicatorSnapshot,
    isDatesSetted: defaultIndicatorSnapshot,
    enableCurrencySelect: defaultIndicatorSnapshot,
    isExporting: defaultIndicatorSnapshot,
    enabledUploadButton: defaultIndicatorSnapshot,
};
