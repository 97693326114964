import { CreateParams, GetParams, Currency, Currencies } from './interfaces';
import { BaseService } from '../base/BaseService';

export class CurrencyService extends BaseService {
    private readonly url: string = `${this.baseUrl}/currency`;

    public get = (params: GetParams): Promise<Currency> =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public getList = (): Promise<Currencies> =>
        this.http.get(`${this.url}/list`).then((res) => res.data);

    public create = (params: CreateParams) =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public disable = (_id: string) =>
        this.http.patch(`${this.url}/disable`, { params: { _id } }).then((res) => res.data);
}
