import {SnapshotIn, types} from "mobx-state-tree";
import {defaultIndicatorSnapshot, Indicator} from "../../common/Indicator";

export const Indicators = types.model({
    isLoading: Indicator,
    isEmpty: Indicator,
})

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
    isEmpty: defaultIndicatorSnapshot,
};
