import {createContext, useEffect, useState} from 'react';
import serviceInitializer from "../modules/api/serviceInitializer";
import {AuthService} from "../modules/api/auth";
import Cookies from "js-cookie";

type AuthContextType = {
    isAuthenticated: boolean;
    setAuth: (auth: boolean) => void;
};

const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    setAuth: () => {},
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const [isAuthenticated, setAuth] = useState<boolean>(false);

    useEffect( () => {
        const checkAuth = async () => {
            const userStr = Cookies.get('user');
            if (!userStr) {
                setAuth(false);
                return
            }

            const user = JSON.parse(userStr);
            const { authorized } = await serviceInitializer<AuthService>(AuthService).ping({
                userId: user._id
            });

            setAuth(authorized)
        }
        checkAuth();
    }, [])

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuth }}>{children}</AuthContext.Provider>
    );
};

export default AuthContext;
