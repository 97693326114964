import React, { useEffect, useState } from 'react';
import { useUser } from '../../../context/UserProvider';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { CurrencyService } from '../../../modules/api/currency';
import { FileService } from '../../../modules/api/attachment';
import { WayBillService } from '../../../modules/api/waybill';
import { Environment } from '../../../modules/business-logic/stores/Consignment/View';
import { Content } from './Content';
import {
    defaultExportWayBillStorePackList,
    ExportWayBillStorePackList,
} from '../../../modules/business-logic/stores/Consignment/ExportPackList';
import './styles.css';

export const WayBillExportPackList = () => {
    const { user } = useUser();

    const [store, setStore] = useState<any>(defaultExportWayBillStorePackList);
    useEffect(
        () =>
            setStore(
                ExportWayBillStorePackList.create(defaultExportWayBillStorePackList, {
                    fileService: serviceInitializer<FileService>(FileService),
                    wayBillService: serviceInitializer<WayBillService>(WayBillService),
                    currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                } as Environment),
            ),
        [],
    );

    if (!store) return null;

    return <Content store={store} />;
};
