import { cast, flow, getEnv, Instance, onSnapshot, SnapshotIn, types } from 'mobx-state-tree';
import { Role } from '../../../../api/account/interfaces';
import { Product } from '../../../../api/product/interfaces';
import { ProductService } from '../../../../api/product';
import { FileService } from '../../../../api/attachment';
import { WayBillService } from '../../../../api/waybill';
import { AccountService } from '../../../../api/account';
import { CurrencyService } from '../../../../api/currency';
import { WayBill } from '../../../../api/waybill/interfaces';
import { defaultTextFieldSnapshot, TextField } from '../../common/TextField';
import { defaultIndicatorsSnapshot, Indicators, ProductItem } from './models';
import { File as FileModel } from '../../common/File';
import { User } from '../../Users/models';
import { Currency } from '../../Currency';
import { defaultNumberFieldSnapshot, NumberField } from '../../common/NumberField';
import { toJS } from 'mobx';
import {
    defaultMeasuresSnapshot,
    Measures,
} from '../../../../../components/pages/WayBillCreate/common/Measures';

export interface Environment {
    isAdmin: boolean;
    _id: string;
    updateList: () => void;
    productService: ProductService;
    fileService: FileService;
    wayBillService: WayBillService;
    accountService: AccountService;
    currencyService: CurrencyService;
}

export const ViewWayBillStore = types
    .model({
        number: types.number,
        createdAt: types.string,
        isDeleted: types.boolean,
        numberOrder: TextField,
        totalWeight: NumberField,
        label: TextField,
        products: types.array(ProductItem),
        images: types.array(FileModel),
        currencies: types.array(Currency),
        selectedCurrency: types.maybeNull(types.string),
        responsiblePersons: types.array(User),
        selectedResponsiblePersonId: types.maybeNull(types.string),
        indicators: Indicators,
        measures: Measures,
    })
    .actions((self) => {
        const {
            isAdmin,
            _id,
            accountService,
            productService,
            fileService,
            currencyService,
            updateList,
            wayBillService,
        } = getEnv<Environment>(self);

        const getCurrencies = flow(function* () {
            self.indicators.isGettingCurrencies.set(true);
            try {
                self.currencies = yield currencyService.getList();
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingCurrencies.set(false);
            }
        });

        const setCurrency = (_id: string) => {
            self.selectedCurrency = _id;
            self.indicators.enableAddProductButton.set(Boolean(self.selectedCurrency));
        };

        const addProduct = flow(function* (product: Product) {
            self.indicators.isAddingProduct.set(true);
            try {
                const productClone = toJS(product);

                const foundedProduct = self.products.find((p) => p.product._id === product._id);
                if (foundedProduct) {
                    foundedProduct.setCount(foundedProduct.count + 1);
                    return;
                }

                const newProductItem: Instance<typeof ProductItem> = {
                    product: {
                        ...productClone,
                        //@ts-ignore
                        imageIds: productClone.imageIds,
                    },
                    image: yield fileService.get({ _id: productClone.logoId }),
                    count: 1,
                    totalPrice: productClone.price,
                };

                self.products.unshift(cast(newProductItem));
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isAddingProduct.set(false);
            }
        });

        const removeProduct = (_id: string) => {
            const founded = self.products.filter((info) => info.product._id !== _id);
            self.products.replace(founded);
        };

        const getResponsiblePersons = flow(function* () {
            self.indicators.isGettingUsers.set(true);
            try {
                self.responsiblePersons = yield accountService.getList({
                    roles: Role.OPERATOR,
                });
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingUsers.set(false);
            }
        });

        const loadData = flow(function* () {
            self.indicators.isGettingData.set(true);
            try {
                const waybill: WayBill = yield wayBillService.get({ _id });

                self.number = waybill.number;
                self.numberOrder.change(waybill.numberOrder);
                self.label.change(waybill.label || '');
                self.createdAt = waybill.createdAt;
                self.totalWeight.change(waybill.totalWeight || 0);
                self.createdAt = waybill.createdAt;
                self.measures.width.change(waybill.width || 0);
                self.measures.height.change(waybill.height || 0);
                self.measures.length.change(waybill.length || 0);
                self.selectedResponsiblePersonId = waybill.responsiblePersonId;

                const products = yield Promise.all(
                    waybill.productIds.map(async (info) => ({
                        product: await productService.get({ _id: info._id }),
                    })),
                );

                const productItems = yield Promise.all(
                    products.map(async (info: any) => {
                        const count = waybill.productIds.find(
                            (item) => item._id === info.product._id,
                        )?.count;
                        return {
                            product: info.product,
                            image: await fileService.get({ _id: info.product.logoId }),
                            count,
                            totalPrice: count ? info.product.price * count : 0,
                        };
                    }),
                );

                self.selectedCurrency = products[0]?.product.currencyId;
                self.products.replace(productItems);
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingData.set(false);
            }
        });

        const afterCreate = flow(function* () {
            try {
                yield getCurrencies();
                if (_id) yield loadData();
                if (isAdmin) {
                    self.indicators.isVisibleResponsiblePersons.set(true);
                    yield getResponsiblePersons();
                }
                self.indicators.enableCurrencySelect.set(!Boolean(self.products.length));
                self.indicators.enableAddProductButton.set(true);
                onSnapshot(self.products, () =>
                    self.indicators.enableCurrencySelect.set(!Boolean(self.products.length)),
                );
            } catch (err) {
                console.error(err);
            } finally {
            }
        });

        const selectResponsiblePerson = (id: string) => {
            self.selectedResponsiblePersonId = id;
        };

        const checkValid = () => {
            const isValidNumberOrder = Boolean(self.numberOrder.value.length);
            const isValidTotalWeight = Boolean(self.totalWeight.value);
            const isValidCurrency = Boolean(self.selectedCurrency);
            const isValidSelectedProducts = Boolean(self.products.length);
            const isValidOperators = isAdmin ? self.selectedResponsiblePersonId : true;
            const isValidMeasures = self.measures.checkValid();
            return (
                isValidNumberOrder &&
                isValidTotalWeight &&
                isValidSelectedProducts &&
                isValidCurrency &&
                isValidOperators &&
                isValidMeasures
            );
        };

        const getTotalPrice = () =>
            self.products.reduce((acc, item) => item.product.price * item.count + acc, 0);

        const update = flow(function* () {
            self.indicators.isCreatingWayBill.set(true);
            try {
                const responsiblePersonId = isAdmin
                    ? self.selectedResponsiblePersonId?.toString()
                    : undefined;
                const res = yield wayBillService.update(self.number, {
                    numberOrder: self.numberOrder.value.trim(),
                    label: self.label.value.trim(),
                    totalWeight: self.totalWeight.value,
                    currencyId: self.selectedCurrency || '',
                    productIds: self.products.map((info) => ({
                        _id: info.product._id,
                        count: info.count,
                    })),
                    responsiblePersonId,
                    width: self.measures.width.value,
                    height: self.measures.height.value,
                    length: self.measures.length.value,
                });
                updateList();
                return res;
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isCreatingWayBill.set(false);
            }
        });

        return {
            afterCreate,
            addProduct,
            getTotalPrice,
            removeProduct,
            selectResponsiblePerson,
            setCurrency,
            checkValid,
            update,
        };
    });

export const defaultViewWayBillStore: SnapshotIn<typeof ViewWayBillStore> = {
    numberOrder: defaultTextFieldSnapshot,
    label: defaultTextFieldSnapshot,
    totalWeight: defaultNumberFieldSnapshot,
    createdAt: '',
    isDeleted: false,
    products: [],
    number: 0,
    // selectedProducts: [],
    indicators: defaultIndicatorsSnapshot,
    measures: defaultMeasuresSnapshot,
};
