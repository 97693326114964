import {flow, getEnv, SnapshotIn, types} from "mobx-state-tree";
import {Category, defaultIndicatorsSnapshot, Indicators} from "./models";
import {CategoryService} from "../../../api/category";

export interface Environment {
    categoryService: CategoryService
}

export const CategoryListStore = types.model({
    items: types.array(Category),
    totalCount: types.number,
    indicators: Indicators,
}).actions(self => {
    const { categoryService } = getEnv<Environment>(self)

    const getList = flow(function *() {
        self.indicators.isLoading.set(true);
        try {
            const { items } = yield categoryService.getList();
            self.items.replace(items);
            self.indicators.isEmpty.set(!items.length);
        } catch (err) {
            console.error(err)
        } finally {
            self.indicators.isLoading.set(false);
        }
    })

    const afterCreate = flow(function *() {
        yield getList();
    })

    return {
        afterCreate,
        getList
    }
});

export const defaultCategoryListSnapshot: SnapshotIn<typeof CategoryListStore> = {
    items: [],
    totalCount: 0,
    indicators: defaultIndicatorsSnapshot
}
