import {cast, flow, getEnv, SnapshotIn, types} from 'mobx-state-tree';
import {defaultIndicatorsSnapshot, Indicators} from "./models";
import {Organisation} from "../organisation";
import {Environment} from "../Base";
import {Organisations} from "../../../../api/organisation/interfaces";

export const OrganisationStore = types.model({
    indicators: Indicators,
    organisations: types.array(Organisation),
}).actions(self => {
    const {organisationServices} = getEnv<Environment>(self);

    const getList = flow(function* () {
        self.indicators.isLoading.set(true)
        try {
            const data: Organisations = yield organisationServices.getList();
            self.organisations = cast(data)

            self.organisations && self.indicators.isEmpty.set(!Boolean(self.organisations.length));
        } catch (err) {
            console.error(err);
        }  finally {
            self.indicators.isLoading.set(false);
        }
    });

    const afterCreate = flow(function* () {
        self.indicators.isLoading.set(true);
        try {
            yield getList()
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isLoading.set(false);
        }
    });

    return {
        afterCreate,
        getList
    }
})

export const defaultOrganisationStoreSnapshot: SnapshotIn<typeof OrganisationStore> = {
    indicators: defaultIndicatorsSnapshot
};
