import {flow, getEnv, SnapshotIn} from 'mobx-state-tree';
import {defaultTextFieldSnapshot} from "../../common/TextField";
import {defaultIndicatorsSnapshot} from "../Base/models";
import {Base, Environment} from "../Base";

export const CreateCategoryStore = Base.actions(self => {
    const { isAdmin, categoryService } = getEnv<Environment>(self);

    const afterCreate = flow(function* () {
        if (isAdmin) {
            yield self.getOrganisations();
        }
    });

    const create = flow(function* () {
        self.indicators.isSending.set(true);
        const selectedOrganisationId = isAdmin ? self.selectedOrganisationId : undefined;
        try {
            return yield categoryService.create({
                value: self.title.value,
                description: self.description.value,
                organisationId: selectedOrganisationId
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    return {
        afterCreate,
        create,
    }
})

export const defaultCreateCategoryStoreSnapshot: SnapshotIn<typeof CreateCategoryStore> = {
    title: defaultTextFieldSnapshot,
    description: defaultTextFieldSnapshot,
    indicators: defaultIndicatorsSnapshot,
}
