import {flow, getEnv, types} from "mobx-state-tree";
import {TextField} from "../../common/TextField";
import {Indicators} from "./models";
import {OrganisationService} from "../../../../api/organisation";

export interface Environment {
    isAdmin: boolean;
    organisationServices: OrganisationService
}

export const Base = types.model({
    name: TextField,
    description: TextField,
    indicators: Indicators,
}).actions(self => {
    return {}
}).views(self => {
    return ({
        isValid: () => {
            const isValidTitle = self.name.value.length;
            const isValidDescription = self.description.value.length;
            const isValidCurrency = self.indicators.noCurrencySelected.value
            const isValidCountry= self.indicators.noCountrySelected.value

            return isValidTitle && isValidDescription && isValidCurrency && isValidCountry
        }
    })
})
