import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';
import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { ProductService } from '../../../../api/product';
import { defaultIndicatorsSnapshot, Indicators } from './indicators';
import { defaultTextFieldSnapshot, TextField } from '../../common/TextField';

interface Environment {
    productService: ProductService;
}

export const FilterProductsStore = types
    .model({
        query: TextField,
        startDate: types.maybeNull(types.string),
        endDate: types.maybeNull(types.string),
        indicators: Indicators,
    })
    .actions((self) => {
        const { productService } = getEnv<Environment>(self);

        const setDates = (startDate: string | null, endDate: string | null) => {
            self.startDate = startDate && startDate.length ? startDate : null;
            self.endDate = endDate && endDate.length ? endDate : null;
        };

        return {
            setDates,
        };
    });

export const defaultFilterProductsStore: SnapshotIn<typeof FilterProductsStore> = {
    query: defaultTextFieldSnapshot,
    indicators: defaultIndicatorsSnapshot,
};
