import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { isAdmin, useUser } from '../../../context/UserProvider';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { ProductService } from '../../../modules/api/product';
import { CurrencyService } from '../../../modules/api/currency';
import { FileService } from '../../../modules/api/attachment';
import {
    defaultProductViewStoreSnapshot,
    ProductViewStore,
} from '../../../modules/business-logic/stores/Product/View';
import { AccountService } from '../../../modules/api/account';
import { Content } from './Content';
import './styles.css';

export const ProductView = () => {
    const { user } = useUser();
    const { id } = useParams();
    const [store, setStore] = useState<any>(defaultProductViewStoreSnapshot);
    useEffect(
        () =>
            setStore(
                ProductViewStore.create(defaultProductViewStoreSnapshot, {
                    id: id,
                    userId: user?._id,
                    isAdmin: isAdmin(user),
                    accountService: serviceInitializer<AccountService>(AccountService),
                    productService: serviceInitializer<ProductService>(ProductService),
                    currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                    fileService: serviceInitializer<FileService>(FileService),
                }),
            ),
        [id, user],
    );

    return <Content store={store} />;
};
