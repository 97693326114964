import React from 'react';
import {observer} from 'mobx-react';
import {Button} from "antd";
import {blockOrganisation} from "../../../modals/Organisations/Block";
import {BlockedIcon} from "../../../Icons/BlockedIcon";

import './styles.css'
import {useTranslation} from "react-i18next";
import {unblockOrganisation} from "../../../modals/Organisations/Unblock";

export interface Props {
    _id: string;
    value: string;
    updateList: () => void;
    isBlocked: boolean;
}

export const OrganisationListItem: React.FC<Props> = observer(
    ({_id, value, updateList, isBlocked}) => {
        const { t } = useTranslation()

        const handleCallback = isBlocked ? unblockOrganisation : blockOrganisation

        return (
            <>
                <div className={`categoryListItem`}>
                    <div className={'value'}>{value}</div>
                    <div className={'actions'}>
                            <Button type="default" onClick={() => handleCallback({
                                _id,
                                updateList,
                            })}>{isBlocked ? t('organisations:unblock') : t('organisations:block')}</Button>
                    </div>
                </div>
            </>
        );
    },
);
