import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorsSnapshot, Indicators } from './models';
import { User } from '../../Users/models';
import { AccountService } from '../../../../api/account';
import { Role } from '../../../../api/account/interfaces';
import { ProductService } from '../../../../api/product';
import { CurrencyService } from '../../../../api/currency';
import { FileService } from '../../../../api/attachment';
import { Product } from '../models';
import { Currency, defaultCurrencySnapshot } from '../../Currency';
import { File } from '../../common/File';
import { defaultNumberFieldSnapshot, NumberField } from '../../common/NumberField';

export interface Environment {
    isAdmin: boolean;
    userId: string;
    id: string;
    accountService: AccountService;
    productService: ProductService;
    currencyService: CurrencyService;
    fileService: FileService;
}

export const ProductViewStore = types
    .model({
        product: types.maybeNull(Product),
        currency: Currency,
        images: types.array(File),
        responsiblePersons: types.array(User),
        count: NumberField,
        indicators: Indicators,
    })
    .actions((self) => {
        const {
            id,
            productService,
            currencyService,
            accountService,
            fileService,
            isAdmin,
        }: Environment = getEnv(self);

        const getImages = flow(function* () {
            if (!self.product) return;
            self.indicators.isLoadingImages.set(true);
            try {
                const imagePromises = self.product.imageIds.map((_id) => fileService.get({ _id }));
                const images = yield Promise.all(imagePromises);
                self.images.replace(images);
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isLoadingImages.set(false);
            }
        });

        const getProduct = flow(function* () {
            self.indicators.isLoadingProduct.set(true);
            try {
                self.product = yield productService.get({
                    _id: id,
                });
                self.indicators.isVisibleEditButton.set(isAdmin); // || self.product?.responsiblePersonId === userId)
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isLoadingProduct.set(false);
            }
        });

        const getCurrency = flow(function* () {
            if (!self.product) return;
            self.indicators.isLoadingCurrency.set(true);
            try {
                self.currency = yield currencyService.get({
                    _id: self.product.currencyId,
                });
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isLoadingCurrency.set(false);
            }
        });

        const getResponsibles = flow(function* () {
            if (!self.product) return;
            self.indicators.isLoadingResponsiblePerson.set(true);
            try {
                self.responsiblePersons = yield accountService.getList({
                    roles: Role.OPERATOR,
                });
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isLoadingResponsiblePerson.set(false);
            }
        });

        const getResponsiblePersonName = (id: string) => {
            const responsiblePerson = self.responsiblePersons.find((person) => person._id === id);
            if (!responsiblePerson) return;
            const { firstName, secondName } = responsiblePerson;
            return `${firstName} ${secondName}`;
        };

        const changeResponsiblePerson = flow(function* (id: string | null) {
            if (!self.product) return;
            self.indicators.isChangingResponsiblePerson.set(true);
            try {
                yield productService.update(self.product.number, {
                    responsiblePersonId: id,
                });
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isChangingResponsiblePerson.set(false);
            }
        });

        const setManageMode = (value: boolean) => {
            self.indicators.isManageMode.set(value);
        };

        const reload = flow(function* () {
            self.count.change(1);
            yield getProduct();
            Promise.all([getCurrency(), getImages(), isAdmin && getResponsibles()]);
        });

        const afterCreate = flow(function* () {
            yield reload();
        });

        const getTotalPrice = () => {
            return Number(self.product?.price) * self.count.value;
        };

        return {
            reload,
            afterCreate,
            setManageMode,
            getTotalPrice,
            changeResponsiblePerson,
            getResponsiblePersonName,
        };
    });

export const defaultProductViewStoreSnapshot: SnapshotIn<typeof ProductViewStore> = {
    indicators: defaultIndicatorsSnapshot,
    currency: defaultCurrencySnapshot,
    count: defaultNumberFieldSnapshot,
};
