import * as React from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    Carousel,
    Col,
    Row,
    Image,
    InputNumber,
    notification,
    Space,
    Spin,
    Typography,
} from 'antd';
import {
    EditOutlined,
    LoadingOutlined,
    ShoppingCartOutlined,
    StarFilled,
    StarOutlined,
} from '@ant-design/icons';
import { config } from '../../../../modules/api/serviceInitializer';
import { Spinner } from '../../../Page/components/Spinner';
import { useCart } from '../../../../context/CartProvider';
import { isAdmin, useUser } from '../../../../context/UserProvider';
import { editProduct } from '../../ProductList/components/Create/Edit';
import { useTranslation } from 'react-i18next';

const { Title, Text, Link } = Typography;

const contentStyle: React.CSSProperties = {
    margin: 0,
    maxHeight: '300px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

interface Props {
    store: any;
}

export const Content: React.FC<Props> = observer(({ store }) => {
    const { addItemToCart } = useCart();
    const { user } = useUser();
    const { t } = useTranslation();
    const isLoadingProduct = !store || !store.product || store.indicators.isLoadingProduct.value;
    const isLoadingImages = isLoadingProduct || store.indicators.isLoadingImages.value;
    const isLoadingCurrency = isLoadingProduct || store.indicators.isLoadingCurrency.value;
    const isVisibleEditButton = isAdmin(user) || store.product?.organisationId === user?.organisationId;

    if (isLoadingProduct) return <Spinner />;

    const { product } = store;
    const { currency } = store;
    const { images } = store;
    const { title, description, price, barcode, externalLink, weight } = product;

    const addItemToCartList = () => {
        addItemToCart({
            _id: store.product._id,
            title: store.product.title,
            price: store.product.price,
            count: store.count.value,
            currency: store.currency.value,
            totalPrice: store.getTotalPrice(),
            imageUrl: store.images.find((image: any) => image._id === store.product.logoId)?.url,
        });

        notification.success({
            message: t('common:success') + '!',
            description: t('productView:productWasAddedToCart'),
            duration: 2,
        });
    };

    const editProductItem = () =>
        editProduct({
            user,
            onEdit: () => store.reload(),
            number: store.product.number,
        });

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{title}</Title>
                {isVisibleEditButton && (
                    <Button
                        className={'productEditButton'}
                        size={'large'}
                        icon={<EditOutlined />}
                        onClick={editProductItem}
                    >
                        {t('common:edit')}
                    </Button>
                )}
            </div>
            <div className={'productViewContent'}>
                <div className={'productViewGallery'} style={{ textAlign: 'center' }}>
                    <Carousel autoplay>
                        {isLoadingImages && <Spinner />}
                        {!isLoadingImages &&
                            images.map((image: any, index: number) => {
                                const url = config.baseURL + '/' + image.url;
                                return (
                                    <div style={contentStyle} key={index}>
                                        <Image
                                            src={url}
                                            style={{ maxHeight: '300px', objectFit: 'cover' }}
                                        />
                                    </div>
                                );
                            })}
                    </Carousel>
                </div>
                <div className={'productViewInfo'}>
                    <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
                        <div className={'productViewColumn'}>
                            <Text type={'secondary'}>{t('productView:description')}</Text>
                            {!description && <Text>{t('productView:noDescription')}</Text>}
                            {description && (
                                <Text onChange={(e) => console.log(e)}>{description}</Text>
                            )}
                        </div>

                        {/*<Row gutter={[16, 16]} className={'createProductWeightLabel'}>*/}
                        {/*    <Col span={8}>*/}
                        {/*        <div className={'productViewColumn'}>*/}
                        {/*            <Text type={'secondary'}>{`${t('productCreate:weight')}`}</Text>*/}
                        {/*            <Text>{weight}</Text>*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*    <Col span={16}>*/}
                        {/*        <div className={'productViewColumn'}>*/}
                        {/*            <Text type={'secondary'}>{t('productView:barcode')}</Text>*/}
                        {/*            {!barcode && <Text>{t('common:noData')}</Text>}*/}
                        {/*            {barcode && <Text>{barcode}</Text>}*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        {externalLink && (
                            <div className={'productViewColumn'}>
                                <Text type={'secondary'}>{t('productView:externalLink')}</Text>
                                <Link href={externalLink} target={'_blank'} ellipsis={true}>
                                    {externalLink}
                                </Link>
                            </div>
                        )}

                        <div className={'productViewColumn'}>
                            <Text type={'secondary'}>{t('productView:rating')}</Text>
                            <div
                                style={{
                                    color: 'orange',
                                    fontSize: 28,
                                    display: 'flex',
                                    gap: 4,
                                    alignItems: 'center',
                                }}
                            >
                                <StarFilled />
                                <StarFilled />
                                <StarFilled />
                                <StarFilled />
                                <StarOutlined />
                                <Text style={{ fontSize: 24 }}>4.1 / 5</Text>
                            </div>
                        </div>

                        <div className={'productViewColumn'}>
                            <Text type={'secondary'}>{t('productView:reviews')}</Text>
                            <Text>{t('productView:noReviews')}</Text>
                        </div>

                        <div className={'productViewPriceRow'}>
                            <div className={'productViewColumn'}>
                                <Text type={'secondary'}>{t('productView:price')}</Text>
                                {isLoadingCurrency && (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined style={{ fontSize: 32 }} spin />
                                        }
                                    />
                                )}
                                {!isLoadingCurrency && (
                                    <Text
                                        className={'productViewPrice'}
                                    >{`${price} ${currency.value}`}</Text>
                                )}
                            </div>
                            <div className={'productViewColumn'}>
                                <Text type={'secondary'}>{t('productView:quantity')}</Text>
                                <div className={'productViewCount'}>
                                    <InputNumber
                                        defaultValue={1}
                                        size={'large'}
                                        value={store.count.value}
                                        onChange={store.count.change}
                                    />
                                </div>
                            </div>
                            <div className={'productViewColumn'}>
                                <Text type={'secondary'}>{t('productView:totalPrice')}</Text>
                                {isLoadingCurrency && (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined style={{ fontSize: 32 }} spin />
                                        }
                                    />
                                )}
                                {!isLoadingCurrency && (
                                    <Text
                                        className={'productViewTotalPrice'}
                                    >{`${store.getTotalPrice()} ${currency.value}`}</Text>
                                )}
                            </div>
                        </div>

                        <Space
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Button
                                icon={<ShoppingCartOutlined />}
                                size={'large'}
                                type={'primary'}
                                onClick={addItemToCartList}
                            >
                                {t('productView:addToCart')}
                            </Button>
                        </Space>
                    </Space>
                </div>
            </div>
        </div>
    );
});
