import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { Input, Modal, Select, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import {User} from "../../../../modules/business-logic/stores/Users/models";
import {Spinner} from "../../../Page/components/Spinner";
import {OrganisationSelect} from "../../../OrganisationSelect";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
    store: any;
    isAdmin: boolean;
    editMode?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({ store, onClose, onSubmit, isAdmin }) => {
    const { t } = useTranslation();
    const editMode = false;
    const { title, description } = store;
    const isValid = store.isValid && store.isValid();

    // const getAccountsOptions = (): { value: string; label: string }[] => {
    //     const options = store.responsiblePersons.map((account: Instance<typeof User>) => ({
    //         value: account._id,
    //         label: `${account.firstName} ${account.secondName}`,
    //     }));
    //
    //     options.unshift({
    //         value: 0,
    //         label: t('common:notAssigned'),
    //     });
    //
    //     return options;
    // };

    // const options = getAccountsOptions();

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('category:creatingCategory')}
                </Text>
            }
            open
            onOk={onSubmit}
            onCancel={onClose}
            okButtonProps={{ disabled: !isValid }}
            cancelText={t('common:cancel')}
            okText={t(`common:${editMode ? 'approve' : 'create'}`)}
            confirmLoading={store.indicators.isSending.value}
        >
            <div className={'modalBody'}>
                {!store.indicators.isLoading.value && (
                    <>
                        <Input
                            placeholder={t('productCreate:title')}
                            size={'large'}
                            value={title.value}
                            onChange={(e) => title.change(e.target.value)}
                        />

                        <TextArea
                            placeholder={t('productCreate:description')}
                            size={'large'}
                            autoSize={{ minRows: 3, maxRows: 7 }}
                            value={description.value}
                            onChange={(e) => description.change(e.target.value)}
                            allowClear
                        />

                        {/*{isAdmin && (*/}
                        {/*    <Select*/}
                        {/*        size={'large'}*/}
                        {/*        options={options}*/}
                        {/*        loading={store.indicators.isGettingUsers.value}*/}
                        {/*        disabled={store.indicators.isGettingUsers.value}*/}
                        {/*        placeholder={t('common:operator')}*/}
                        {/*        value={store.selectedResponsiblePersonId}*/}
                        {/*        onChange={store.setResponsiblePerson}*/}
                        {/*    />*/}
                        {/*)}*/}

                        {isAdmin && (
                            <OrganisationSelect
                                store={store.organisations}
                                isLoading={store.indicators.isGettingOrganisations.value}
                                disabled={store.indicators.isGettingOrganisations.value}
                                value={store.selectedResponsiblePersonId}
                                set={store.setOrganisationId}
                            />
                        )}
                    </>
                )}
                {store.indicators.isLoading.value && <Spinner />}
            </div>
        </Modal>
    );
});
