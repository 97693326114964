import {cast, flow, getEnv, SnapshotIn, types} from 'mobx-state-tree';
import {defaultTextFieldSnapshot} from "../../common/TextField";
import {defaultIndicatorsSnapshot} from "../Base/models";
import {Base, Environment as Env} from "../Base";
import {PaymentStatusService} from "../../../../api/payment-status";
import {PaymentStatuses} from "../../../../api/payment-status/interfaces";
import {CurrencyService} from "../../../../api/currency";
import {Currency} from "../../Currency";
import {CountryService} from "../../../../api/country";
import {isAdmin} from "../../../../../context/UserProvider";
import {CountryList} from "../../../../../components/pages/CountrytList";
import {Country} from "../../Country/country";
import {Organisations} from "../../../../api/organisation/interfaces";


// Пока что передаем в тупую paymentStatusServices и делаем харкодом PaymentStatus для его передачи в создание организации
interface Environment extends Env {
    paymentStatusServices: PaymentStatusService;
    currencyService: CurrencyService,
    countryService: CountryService;
}

export const CreateOrganisationStore = types.compose(
    Base,
    types.model({
        paymentStatusId: types.string,
        selectedCurrency: types.maybeNull(types.string),
        selectedCountry: types.maybeNull(types.string),
        currencies: types.array(Currency),
        countries: types.array(Country)
    })).actions(self => {
    const {isAdmin, organisationServices, paymentStatusServices, currencyService, countryService} = getEnv<Environment>(self);

    // Пока что хардкодом
    const setPaymentStatus = flow(function* () {
        self.indicators.isLoading.set(true)
        try {
            yield paymentStatusServices.create({
                value: 'paid'
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isLoading.set(false);
        }
    });

    // Пока что хардкодом. Получает наш paymentId
    const getPaymentStatus = flow(function* () {
        self.indicators.isLoading.set(true);
        try {
            const paidIdArray: PaymentStatuses = yield paymentStatusServices.getList()
            if (paidIdArray && paidIdArray[0]) {
                const paymentId: string = paidIdArray[0]._id
                self.paymentStatusId = paymentId
            }
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isLoading.set(false);
        }
    });

    const create = flow(function* () {
        self.indicators.isSending.set(true);
        try {
            return yield organisationServices.create({
                title: self.name.value,
                description: self.description.value,
                countryId: self.selectedCountry ?? '',
                currencyId: self.selectedCurrency ?? '',
                paymentStatusId: self.paymentStatusId
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    const getCountries = flow(function* () {
        self.indicators.isLoadingCountries.set(true);
        try {
            const countries = yield countryService.getList();
            self.countries.replace(countries);

            self.indicators.isEmpty.set(!Boolean(self.countries.length));
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isLoadingCountries.set(false);
        }
    });


    const getCurrencies = flow(function* () {
        self.indicators.isGettingCurrencies.set(true);
        try {
            self.currencies = yield currencyService.getList();
        } catch (err) {
            console.log('err')
            console.error(err);
        } finally {
            self.indicators.isGettingCurrencies.set(false);
        }
    });

    const setCurrency = async (_id: string) => {
        self.selectedCurrency = _id;

        self.indicators.noCurrencySelected.set(Boolean(self.selectedCurrency));
    };

    const setCountry = async (_id: string) => {
        self.selectedCountry = _id;

        self.indicators.noCountrySelected.set(Boolean(self.selectedCountry));
    };


    const afterCreate = flow(function* () {
        self.indicators.isLoading.set(true);
        try {
            yield getPaymentStatus()

            if (!self.paymentStatusId) {
                yield setPaymentStatus()
            }
            yield getCurrencies()
            yield getCountries()
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isLoading.set(false);
        }
    });

    return {
        create,
        afterCreate,
        setCurrency,
        setCountry
    }
})

export const defaultCreateOrganisationStoreSnapshot: SnapshotIn<typeof CreateOrganisationStore> = {
    name: defaultTextFieldSnapshot,
    description: defaultTextFieldSnapshot,
    indicators: defaultIndicatorsSnapshot,
    paymentStatusId: ''
}
