import * as React from 'react';
import './styles.css';
import { AuditOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { config } from '../../modules/api/serviceInitializer';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={'footerBlock'}>
            <div className={'oferta'}>
                <AuditOutlined />
                <Text className={'ofertaHint'}>
                    {t('common:footerOfferHint')}
                    <Link to={`${config.baseURL}/files/offers/thailand.pdf`} target={'_blank'}>
                        {t('common:footerOfferLink')}
                    </Link>
                </Text>
            </div>
        </div>
    );
};
