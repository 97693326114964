import React from 'react';

interface Props {
    width?: number;
    height?: number;
}

export const ListIcon: React.FC<Props> = ({ width = 28, height = 28 }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.55556 15.5556C5.87037 15.5556 6.13426 15.4491 6.34722 15.2361C6.56019 15.0231 6.66667 14.7593 6.66667 14.4444C6.66667 14.1296 6.56019 13.8657 6.34722 13.6528C6.13426 13.4398 5.87037 13.3333 5.55556 13.3333C5.24074 13.3333 4.97685 13.4398 4.76389 13.6528C4.55093 13.8657 4.44444 14.1296 4.44444 14.4444C4.44444 14.7593 4.55093 15.0231 4.76389 15.2361C4.97685 15.4491 5.24074 15.5556 5.55556 15.5556ZM5.55556 11.1111C5.87037 11.1111 6.13426 11.0046 6.34722 10.7917C6.56019 10.5787 6.66667 10.3148 6.66667 10C6.66667 9.68519 6.56019 9.4213 6.34722 9.20833C6.13426 8.99537 5.87037 8.88889 5.55556 8.88889C5.24074 8.88889 4.97685 8.99537 4.76389 9.20833C4.55093 9.4213 4.44444 9.68519 4.44444 10C4.44444 10.3148 4.55093 10.5787 4.76389 10.7917C4.97685 11.0046 5.24074 11.1111 5.55556 11.1111ZM5.55556 6.66667C5.87037 6.66667 6.13426 6.56019 6.34722 6.34722C6.56019 6.13426 6.66667 5.87037 6.66667 5.55556C6.66667 5.24074 6.56019 4.97685 6.34722 4.76389C6.13426 4.55093 5.87037 4.44444 5.55556 4.44444C5.24074 4.44444 4.97685 4.55093 4.76389 4.76389C4.55093 4.97685 4.44444 5.24074 4.44444 5.55556C4.44444 5.87037 4.55093 6.13426 4.76389 6.34722C4.97685 6.56019 5.24074 6.66667 5.55556 6.66667ZM8.88889 15.5556H15.5556V13.3333H8.88889V15.5556ZM8.88889 11.1111H15.5556V8.88889H8.88889V11.1111ZM8.88889 6.66667H15.5556V4.44444H8.88889V6.66667ZM2.22222 20C1.61111 20 1.08796 19.7824 0.652778 19.3472C0.217593 18.912 0 18.3889 0 17.7778V2.22222C0 1.61111 0.217593 1.08796 0.652778 0.652778C1.08796 0.217593 1.61111 0 2.22222 0H17.7778C18.3889 0 18.912 0.217593 19.3472 0.652778C19.7824 1.08796 20 1.61111 20 2.22222V17.7778C20 18.3889 19.7824 18.912 19.3472 19.3472C18.912 19.7824 18.3889 20 17.7778 20H2.22222ZM2.22222 17.7778H17.7778V2.22222H2.22222V17.7778Z"
                fill="#B9B9B9"
            />
        </svg>
    );
};
