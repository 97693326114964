import {CreateParams, GetParams, Category, Categories, EditParams, GetListParams} from './interfaces';
import { BaseService } from '../base/BaseService';

export class CategoryService extends BaseService {
    private readonly url: string = `${this.baseUrl}/category`;

    public get = (params: GetParams): Promise<Category> =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public getList = (params?: GetListParams): Promise<Categories> =>
        this.http.get(`${this.url}/list`, { params }).then((res) => res.data);

    public create = (params: CreateParams) =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public edit = (params: EditParams) =>
        this.http.patch(`${this.url}/edit`, params).then((res) => res.data);
}
