import {SnapshotIn, types} from "mobx-state-tree";
import {defaultIndicatorSnapshot, Indicator} from "../../common/Indicator";

export const Indicators = types.model({
    isLoading: Indicator,
    isSending: Indicator,
    isGettingCurrencies: Indicator,
    noCurrencySelected: Indicator,
    noCountrySelected: Indicator,
    isLoadingCountries: Indicator,
    isEmpty: Indicator
})

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
    isSending: defaultIndicatorSnapshot,
    isGettingCurrencies: defaultIndicatorSnapshot,
    noCurrencySelected: defaultIndicatorSnapshot,
    isLoadingCountries: defaultIndicatorSnapshot,
    isEmpty: defaultIndicatorSnapshot,
    noCountrySelected: defaultIndicatorSnapshot
};
