import * as React from 'react';
import './styles.css';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AuthStore,
    defaultAuthStoreSnapshot,
    Environment,
} from '../../../modules/business-logic/stores/Auth';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { AuthService } from '../../../modules/api/auth';
import { AuthForm } from './components/Form';
import Cookies from 'js-cookie';
import { Page } from '../../Page';

export const Auth: React.FC = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [store, setStore] = useState(defaultAuthStoreSnapshot);
    useEffect(
        () =>
            setStore(
                AuthStore.create(defaultAuthStoreSnapshot, {
                    authService: serviceInitializer<AuthService>(AuthService),
                } as Environment),
            ),
        [],
    );

    const from = location.state?.from?.pathname || '/product-list';

    const onAuth = () => {
        setAuth(true);
        navigate(from, { replace: true });
    };

    if (Cookies.get('auth_token')) {
        setAuth(true);
        const from = location.state?.from?.pathname || '/product-list';
        navigate(from, { replace: true });
    }

    return (
        <div className={'authPage'}>
            <AuthForm store={store} onAuth={onAuth} />
        </div>
    );
};
