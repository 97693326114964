import { BaseService } from '../base/BaseService';

export class SimilarNamesService extends BaseService {
    private readonly url: string = `${this.baseUrl}/similar-names`;

    public getYandex = (url: string): Promise<any> =>
        this.http.get(`${this.url}/yandex`, { params: { url } }).then((res) => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(res.data.data, 'text/html');
            const similarPageContent = dom.getElementsByClassName('cbir-similar-page')[0];
            const content = similarPageContent?.children[0]?.children[0];

            if (!content) {
                console.error('empty data from yandex');
                return null;
            }
            const dateState = content.getAttribute('data-state');
            if (!dateState) {
                console.error('empty date-state from yandex');
                return null;
            }
            const answer = JSON.parse(dateState);
            const items = Object.values(answer.initialState.serpList.items.entities);

            // @ts-ignore
            return items.map((i) => {
                // @ts-ignore
                return {
                    // @ts-ignore
                    name: i.alt,
                    // @ts-ignore
                    src: i.origUrl,
                };
            });
        });
}
