import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorsSnapshot, Indicators, Statistics } from './models';
import { StatisticsService } from '../../../api/statistics';
import { defaultFilterSnapshot, Filter, StatisticsFilter } from './filter';

export interface Environment extends StatisticsFilter {
    statisticsService: StatisticsService;
}

export const StatisticsView = types
    .model({
        statistics: types.maybeNull(Statistics),
        filter: Filter,
        indicators: Indicators,
    })
    .actions((self) => {
        const { statisticsService }: Environment = getEnv(self);

        const getStatistics = flow(function* () {
            self.indicators.isLoading.set(true);
            try {
                self.statistics = yield statisticsService.get({
                    responsiblePersonId: self.filter.selectedUser,
                    startDate: self.filter.startDate,
                    endDate: self.filter.endDate,
                });
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isLoading.set(false);
            }
        });

        const afterCreate = flow(function* () {
            yield getStatistics();
            yield self.filter.getAccounts();
        });

        const onSelectUser = (branchId: string) => {
            self.filter.selectedUser = branchId;
            getStatistics();
        };

        const onChangeDates = (startDate: string, endDate: string) => {
            self.filter.setDates(startDate, endDate);
            getStatistics();
        };

        return {
            afterCreate,
            onSelectUser,
            onChangeDates,
        };
    });

export const defaultStatisticsViewSnapshot: SnapshotIn<typeof StatisticsView> = {
    statistics: null,
    indicators: defaultIndicatorsSnapshot,
    filter: defaultFilterSnapshot,
};
