import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultTextFieldSnapshot, TextField } from '../../common/TextField';
import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';
import { AccountService } from '../../../../api/account';
import {defaultOrganisationStoreSnapshot, OrganisationStore} from "../../Organisations/List";
import { Environment as OrganisationsEnv } from "../../Organisations/Base";
import {Organisation} from "../../Organisations/organisation";
import {OrganisationService} from "../../../../api/organisation";

export interface Environment extends OrganisationsEnv {
    accountService: AccountService;
    organisationService: OrganisationService;
}

export const CreateAccountStore = types
    .model({
        firstName: TextField,
        secondName: TextField,
        username: TextField,
        isSending: Indicator,
        hasCredentials: Indicator,
        password: types.string,
        organisations: types.array(Organisation),
        selectedOrganisationId: types.maybe(types.string),
        isGettingOrganisations: Indicator,
    })
    .actions((self) => {
        const { accountService, organisationService }: Environment = getEnv<Environment>(self);

        const create = flow(function* () {
            if (!self.selectedOrganisationId) {
                console.error('OrganisationId was not setted!');
                return;
            }
            self.isSending.set(true);

            try {
                console.log({
                    firstName: self.firstName.value.trim(),
                    secondName: self.secondName.value.trim(),
                    username: self.username.value.trim(),
                    organisationId: self.selectedOrganisationId,
                });

                const response = yield accountService.create({
                    firstName: self.firstName.value.trim(),
                    secondName: self.secondName.value.trim(),
                    username: self.username.value.trim(),
                    organisationId: self.selectedOrganisationId,
                });

                if (response.credentials) {
                    self.hasCredentials.set(true);
                    self.password = response.credentials.password;
                }
            } catch (err) {
                console.log(err);
            } finally {
                self.isSending.set(false);
            }
        });

        const getOrganisations = flow(function* () {
            self.isGettingOrganisations.set(true);
            try {
                self.organisations = yield organisationService.getList();
            } catch (err) {
                console.error(err);
            } finally {
                self.isGettingOrganisations.set(false);
            }
        });

        const setOrganisationId = (value: string | 0) => {
            const foundedOrganisation = value
                ? self.organisations.find((account) => account._id === value)
                : undefined;
            self.selectedOrganisationId = foundedOrganisation
                ? foundedOrganisation._id
                : undefined;

            console.log(self.selectedOrganisationId)
        };

        const isValid = () => {
            const isValidFirstName = self.firstName.value.trim().length;
            const isValidSecondName = self.secondName.value.trim().length;
            const isValidUserName = self.username.value.trim().length;
            const isValidOrg = Boolean(self.selectedOrganisationId);
            return isValidFirstName && isValidSecondName && isValidUserName && isValidOrg;
        };

        return {
            getOrganisations,
            setOrganisationId,
            create,
            isValid,
        };
    });

export const defaultCreateAccountStore: SnapshotIn<typeof CreateAccountStore> = {
    firstName: defaultTextFieldSnapshot,
    secondName: defaultTextFieldSnapshot,
    username: defaultTextFieldSnapshot,
    isSending: defaultIndicatorSnapshot,
    hasCredentials: defaultIndicatorSnapshot,
    isGettingOrganisations: defaultIndicatorSnapshot,
    organisations: [],
    password: '',
};
