import React, { ReactNode, useEffect, useState } from 'react';
import {
    CheckOutlined,
    CloseCircleOutlined,
    KeyOutlined,
    LoadingOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { ResetPasswordModal } from './ResetPassword';
// import { defaultBlockDeviceStore } from '../../../../modules/business-logic/stores/Device/Block';
import {
    BlockAccountStore,
    defaultBlockAccountStore,
} from '../../../../modules/business-logic/stores/Users/Block';
import serviceInitializer from '../../../../modules/api/serviceInitializer';
import { AccountService } from '../../../../modules/api/account';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export interface Props {
    _id: string;
    number: number;
    firstName: string;
    secondName: string;
    username: string;
    isBlocked: boolean;
    lastLoginDate: string | null;
    updateList?: () => void;
    onBlock: (isBlocked: boolean) => void;
}

export const AccountListItem: React.FC<Props> = observer(
    ({ firstName, secondName, username, lastLoginDate, isBlocked, number, _id, onBlock }) => {
        const [store, setStore] = useState<any>(defaultBlockAccountStore);
        const [isVisibleResetModal, openResetModal] = useState(false);
        const { t } = useTranslation();

        useEffect(() => {
            const store = BlockAccountStore.create(defaultBlockAccountStore, {
                number,
                accountService: serviceInitializer<AccountService>(AccountService),
                onChange: onBlock,
            });
            setStore(store);
        }, [number, onBlock]);
        const onClose = () => openResetModal(false);
        const openModal = () => openResetModal(true);

        const getIconState = (icon: ReactNode, isLoading: boolean) =>
            isLoading ? <LoadingOutlined /> : icon;

        const blockUser = async () => store.send(true);
        const unblockUser = async () => store.send(false);

        const formatDate = (date: string | null) => {
            return date ? format(new Date(date), 'dd.MM.yyyy HH:mm:ss', { locale: ru }) : '-';
        };

        const getTag = () => {
            if (store.isSending.value) {
                return (
                    <Tag color="#f6b96f" icon={<SyncOutlined spin />}>
                        {t('accountList:status.loading').toUpperCase()}
                    </Tag>
                );
            } else if (isBlocked) {
                return (
                    <Tag
                        color="red"
                        className={'clickableTag'}
                        icon={<CloseCircleOutlined />}
                        onClick={unblockUser}
                    >
                        {t('accountList:status.blocked').toUpperCase()}
                    </Tag>
                );
            } else {
                return (
                    <Tag
                        color="green"
                        className={'clickableTag'}
                        icon={<CheckOutlined />}
                        onClick={blockUser}
                    >
                        {t('accountList:status.active').toUpperCase()}
                    </Tag>
                );
            }
        };

        return (
            <>
                <div className={'accountListItem'}>
                    <div className={'id'}>#{number}</div>
                    <div className={'username'}>{username}</div>
                    <div className={'name'}>{`${firstName} ${secondName}`}</div>
                    <div className={'lastLoginDate'}>{formatDate(lastLoginDate)}</div>
                    <div className={'status'}>{getTag()}</div>
                    <div className={'accountActions'}>
                        <div className={'action-icon action-edit-icon'} onClick={openModal}>
                            {getIconState(<KeyOutlined />, isVisibleResetModal)}
                        </div>
                    </div>
                </div>

                {isVisibleResetModal && <ResetPasswordModal onClose={onClose} _id={_id} />}
            </>
        );
    },
);
