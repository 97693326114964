import { flow, getEnv } from 'mobx-state-tree';
import { Base, Environment as BaseEnvironment } from './Base';
import { Product } from '../../../../api/product/interfaces';

interface Environment extends BaseEnvironment {
    number: number;
}

export const EditProductStore = Base.actions((self) => {
    const { number, userId, productService, fileService }: Environment = getEnv<Environment>(self);

    const loadImages = flow(function* (ids: string[]) {
        self.isGettingImages.set(true);
        try {
            self.images = yield Promise.all(ids.map(async (_id) => await fileService.get({ _id })));
        } catch (err) {
            console.error(err);
        } finally {
            self.isGettingImages.set(false);
        }
    });

    const afterCreate = flow(function* () {
        self.isLoadingData.set(true);
        try {
            yield getProduct();
            yield loadImages(self.imageIds);
            yield self.loadData();
        } catch (err) {
            console.error(err);
        } finally {
            self.isLoadingData.set(false);
        }
    });

    const edit = flow(function* () {
        if (!self.number || !self.isValid() || !self.logoId || !self.selectedCurrency) return;
        self.isSending.set(true);

        try {
            const { response } = yield productService.update(self.number, {
                title: self.title.value.trim(),
                description: self.description.value.trim(),
                logoId: self.logoId,
                currencyId: self.selectedCurrency,
                categoryId: self.selectedCategory,
                imageIds: self.imageIds,
                barcode: self.barcode.value.length ? self.barcode.value : undefined,
                externalLink: self.externalLink.value.length ? self.externalLink.value : undefined,
                price: self.price.value,
                weight: self.weight.value,
                responsiblePersonId: self.selectedResponsiblePersonId || userId,
            });

            return response;
        } catch (err) {
            console.error(err);
        } finally {
            self.isSending.set(false);
        }
    });

    const getProduct = flow(function* () {
        if (!number) return;
        self.isGettingProduct.set(true);
        try {
            const product: Product = yield productService.get({ number });
            self.title.change(product.title);
            self.description.change(product.description);
            self.logoId = product.logoId;
            self.selectedCurrency = product.currencyId;
            self.imageIds.replace(product.imageIds);
            self.barcode.change(product.barcode || '');
            self.price.change(product.price);
            self.weight.change(product.weight);
            self.barcode.change(product.barcode || '');
            self.externalLink.change(product.externalLink || '');
            self.number = product.number;
            self.selectedCategory = product.categoryId;
            self.selectedResponsiblePersonId = product.responsiblePersonId;
        } catch (err) {
            console.error(err);
        } finally {
            self.isGettingProduct.set(false);
        }
    });

    return {
        edit,
        afterCreate,
    };
});
