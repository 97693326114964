import { SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';

export const Indicators = types.model({
    isLoading: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
};
