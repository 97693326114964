import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { AccountService } from '../../../api/account';
import { Role } from '../../../api/account/interfaces';
import { defaultIndicatorsSnapshot, Indicators, User } from './models';

export interface Environment {
    accountService: AccountService;
}

const composeList = async (services: Environment) => {
    const { accountService }: Environment = services;

    return await accountService.getList({ roles: Role.OPERATOR }).then(async (accounts) => {
        const accountPromises = accounts.map(async (account) => ({
            ...account,
            lastLoginDate: await accountService
                .getLastLoginDate({ _id: account._id })
                .then((res) => res?.lastLoginDate),
        }));

        return Promise.all(accountPromises);
    });
};

export const AccountList = types
    .model({
        list: types.array(
            types.compose(User, types.model({ lastLoginDate: types.maybeNull(types.string) })),
        ),
        indicators: Indicators,
    })
    .actions((self) => {
        const { accountService }: Environment = getEnv(self);

        const getList = flow(function* () {
            self.indicators.isLoading.set(true);
            try {
                self.list = yield composeList({ accountService });
                //getLastLoginDate
                self.indicators.isEmpty.set(!self.list.length);
            } catch (err) {
                console.log(err);
            } finally {
                self.indicators.isLoading.set(false);
            }
        });

        const afterCreate = () => {
            getList();
        };

        const onBlock = (number: number, value: boolean) => {
            const user = self.list.find((user) => user.number === number);
            if (!user) return;
            user.isBlocked = value;
        };

        return {
            afterCreate,
            getList,
            onBlock,
        };
    });

export const defaultAccountListSnapshot: SnapshotIn<typeof AccountList> = {
    list: [],
    indicators: defaultIndicatorsSnapshot,
};
