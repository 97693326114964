import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Input, Modal, Typography} from 'antd';

import {useTranslation} from 'react-i18next';
import {Instance} from "mobx-state-tree";
import {BlockedOrganisationStore} from "../../../../modules/business-logic/stores/Organisations/Blocked";

const {Text} = Typography;

interface Props {
    store: Instance<typeof BlockedOrganisationStore>;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({store, onClose, onSubmit}) => {
    const {t} = useTranslation();

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('organisations:unblockOrganisation')}
                </Text>
            }
            open
            onOk={onSubmit}
            onCancel={onClose}
            cancelText={t('common:cancel')}
            okText={t(`organisations:unblock`)}
            confirmLoading={store.indicators.isSending.value}
        >
            <div className={'modalBody'}>
                Вы действительно хотите разблокировать организацию?
            </div>
        </Modal>
    );
});
