import {flow, getEnv, SnapshotIn} from 'mobx-state-tree';
import {defaultTextFieldSnapshot} from "../../common/TextField";
import {defaultIndicatorsSnapshot} from "../Base/models";
import {Base, Environment as BaseEnvironment} from "../Base";

export interface Environment extends BaseEnvironment {
    _id: string;
}

export const EditCategoryStore = Base.actions(self => {
    const { _id, isAdmin, categoryService } = getEnv<Environment>(self);

    const afterCreate = flow(function* () {
        yield loadData();
        // if (isAdmin) yield self.getOrganisations();
    });

    const loadData = flow(function* () {
        self.indicators.isGettingData.set(true);
        try {
            const { value, description, organisationId } = yield categoryService.get({_id})
            self.title.change(value);
            self.description.change(description);
            self.selectedOrganisationId = organisationId;
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isGettingData.set(false);
        }
    });

    const edit = flow(function* () {
        self.indicators.isSending.set(true);
        // const selectedOrganisationId = isAdmin ? self.selectedOrganisationId : undefined;
        try {
            return yield categoryService.edit({
                _id,
                value: self.title.value,
                description: self.description.value,
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    return {
        afterCreate,
        edit,
    }
})

export const defaultEditCategoryStoreSnapshot: SnapshotIn<typeof EditCategoryStore> = {
    title: defaultTextFieldSnapshot,
    description: defaultTextFieldSnapshot,
    indicators: defaultIndicatorsSnapshot,
}
