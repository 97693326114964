import { Empty } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import './styles.css';
import { Spinner } from '../../../Page/components/Spinner';
import { CategoryListItem } from './Item';
import { useTranslation } from 'react-i18next';

interface Props {
    store: any;
    updateList: () => void;
}

export const List: React.FC<Props> = observer(({ store, updateList }) => {
    const { t } = useTranslation();
    const isLoading = store.indicators.isLoading.value;
    const isEmpty = store.indicators.isEmpty.value;

    if (isLoading) {
        return <Spinner />;
    }

    if (isEmpty) return <Empty style={{ marginTop: 50 }} description={t('common:noData')} />;

    return (
        <div className={'list'}>
            <div className={'categoryListItemHeader'}>
                <div className={'value'}>{t('category:title')}</div>
                <div className={'description'}>{t('Код')}</div>
                <div className={'actions'}>{''}</div>
            </div>
            {store.list &&
                store.list.map((item: any, index: number) => (
                    <CategoryListItem
                        key={index}
                        _id={item._id}
                        value={item.value}
                        code={item.code}
                        disabled={item.disabled}
                        updateList={updateList}
                    />
                ))}
        </div>
    );
});
