import { ProductListItem } from './models';
import { defaultIndicatorsSnapshot, Indicators } from './indicators';
import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { ProductService } from '../../../api/product';
import { CurrencyService } from '../../../api/currency';
import { FileService } from '../../../api/attachment';
import { GetListParams } from '../../../api/product/interfaces';
import { defaultFilterProductsStore, FilterProductsStore } from './Filter';
import { debounce } from 'lodash';

export interface Environment {
    currencyId?: string;
    productService: ProductService;
    currencyService: CurrencyService;
    fileService: FileService;
}

export const DEFAULT_PAGE_LIST_LENGTH = 12;

const composeList = async (services: Environment, params: GetListParams) => {
    const { productService, currencyService, fileService }: Environment = services;
    return await productService.getList(params).then(async ({items, totalCount}) => {
        const productPromises = items.map(async (product) => ({
            product,
            currency: await currencyService.get({
                _id: product.currencyId,
            }),
            logo: await fileService.get({
                _id: product.logoId,
            }),
        }));

        return {
            items: await Promise.all(productPromises),
            totalCount,
        };
    });
};

export const ProductListStore = types
    .model({
        list: types.array(ProductListItem),
        totalCount: types.number,
        page: types.number,
        indicators: Indicators,
        filters: FilterProductsStore,
    })
    .actions((self) => {
        const environment: Environment = getEnv(self);

        const getList = flow(function* (page: number = 1) {
            self.page = page;
            self.indicators.isLoading.set(true);
            try {
                const query = self.filters.query.value;
                const startDate = self.filters.startDate;
                const endDate = self.filters.endDate;

                const { items, totalCount } = yield composeList(environment, {
                    currencyId: environment.currencyId || void 0,
                    limit: DEFAULT_PAGE_LIST_LENGTH,
                    offset: (page - 1) * DEFAULT_PAGE_LIST_LENGTH,
                    query,
                    startDate,
                    endDate,
                });
                self.list = items;
                self.totalCount = totalCount;
                self.indicators.isEmpty.set(!self.list.length);
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isLoading.set(false);
            }
        });

        const afterCreate = () => {
            getList(self.page);
        };

        const searchProducts = debounce(
            flow(function* () {
                yield getList(self.page);
            }),
            500,
        );

        const onChangeQuery = (query: string) => {
            self.filters.query.change(query);
            searchProducts();
        };

        const onChangeDates = (startDate: string | null, endDate: string | null) => {
            self.filters.startDate = startDate;
            self.filters.endDate = endDate;
            searchProducts();
        };

        return {
            afterCreate,
            onChangeQuery,
            getList,
            onChangeDates,
        };
    });

export const defaultProductListSnapshot: SnapshotIn<typeof ProductListStore> = {
    list: [],
    page: 1,
    totalCount: 0,
    indicators: defaultIndicatorsSnapshot,
    filters: defaultFilterProductsStore,
};
