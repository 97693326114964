import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { createProduct } from './components/Create';
import { List } from './components/List';
import {
    defaultProductListSnapshot,
    ProductListStore,
} from '../../../modules/business-logic/stores/Product/list';
import { ProductService } from '../../../modules/api/product';
import { CurrencyService } from '../../../modules/api/currency';
import { FileService } from '../../../modules/api/attachment';
import { useUser } from '../../../context/UserProvider';
import './styles.css';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Filters } from './components/Filters';
const { Title } = Typography;

export const ProductList: React.FC = () => {
    const { user } = useUser();
    const { isAuthenticated } = useAuth();
    const [store, setStore] = useState<any>(defaultProductListSnapshot);
    const { t } = useTranslation();

    useEffect(
        () =>
            setStore(
                ProductListStore.create(defaultProductListSnapshot, {
                    productService: serviceInitializer<ProductService>(ProductService),
                    currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                    fileService: serviceInitializer<FileService>(FileService),
                }),
            ),
        [],
    );

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('productList:products')}</Title>
                {isAuthenticated && (
                    <Button
                        className={'titleButton'}
                        icon={<PlusOutlined />}
                        onClick={() =>
                            createProduct({
                                updateList: store.getList,
                                user,
                            })
                        }
                        size={'large'}
                    >
                        {t('productList:addProduct')}
                    </Button>
                )}
            </div>

            <Filters store={store} />
            <List store={store} />
        </div>
    );
};
