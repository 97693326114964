import React from 'react';
import { Instance } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import {
    Avatar,
    Empty,
    Space,
    DatePicker,
    List,
    List as ListAnt,
    Modal,
    Typography,
    Input,
    Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { config } from '../../../../modules/api/serviceInitializer';
import { ProductListItem } from '../../../../modules/business-logic/stores/Product/models';
import { DEFAULT_PAGE_LIST_LENGTH } from '../../../../modules/business-logic/stores/Product/list';

import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import enLocale from 'antd/es/date-picker/locale/en_US';

import './styles.css';
import debounce from 'lodash/debounce';
import i18next from 'i18next';
import { Languages } from '../../../../modules/i18next/interfaces';
import Compact from 'antd/es/space/Compact';

const { Text } = Typography;
const { RangePicker } = DatePicker;

interface Props {
    store: any;
    onClose: () => void;
    onSubmit: (item: any) => void;
}

export const ModalProductListContent: React.FC<Props> = observer(({ store, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const isLoading = store.indicators.isLoading.value;
    const isEmpty = store.indicators.isEmpty.value;
    const locale = i18next.language === Languages.Russian ? ruLocale : enLocale;

    const data = store.list.map(
        ({ product, logo, currency }: Instance<typeof ProductListItem>) => ({
            _id: product._id,
            price: `${product.price} ${currency.value}`,
            logoUrl: `${config.baseURL}/${logo.url}`,
            title: product.title,
        }),
    );

    const onSelect = (item: any) => {
        const founded = store.list.find((i: any) => i.product._id === item._id);
        if (!founded) return null;
        onSubmit(founded.product);
    };

    const onChangeDates = (_: any, dates: string[]) => {
        store.filters.setDates(dates[0], dates[1]);
        store.getList(store.page);
    };

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('productCreate:selectProduct')}
                </Text>
            }
            open={true}
            onCancel={onClose}
            footer={null}
        >
            <div className={'modalBody'}>
                <div className={'chooseProductFilter'}>
                    <Space direction={'vertical'} style={{ width: '100%' }}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input
                                placeholder={'Введите запрос'}
                                size={'large'}
                                onChange={(e) => {
                                    store.filters.query.change(e.target.value);
                                }}
                                value={store.filters.query.value}
                                allowClear
                            />
                            <Button
                                size={'large'}
                                loading={isLoading}
                                onClick={() => store.getList(store.page)}
                            >
                                Поиск
                            </Button>
                        </Space.Compact>
                        <RangePicker
                            size={'large'}
                            style={{ width: '100%' }}
                            locale={locale}
                            onChange={onChangeDates}
                        />
                    </Space>
                </div>
                <div className={'list'}>
                    {isLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Text type={'secondary'}>Загрузка...</Text>
                        </div>
                    )}
                    {!isEmpty && (
                        <ListAnt
                            itemLayout="horizontal"
                            dataSource={data}
                            loading={isLoading}
                            pagination={{
                                onChange: async (e) => {
                                    await store.getList(e);
                                    window.scrollTo({ top: 0 });
                                },
                                total: store.totalCount,
                                pageSize: DEFAULT_PAGE_LIST_LENGTH,
                                showSizeChanger: false,
                                align: 'center',
                            }}
                            size="small"
                            renderItem={(item: any) => (
                                <List.Item
                                    onClick={() => onSelect(item)}
                                    className={'listItemModalProducts'}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size={48} src={item.logoUrl} />
                                        }
                                        title={item.title}
                                        // title={<a href={'/product-view/' + item._id} target={'_blank'}>{item.title}</a>}
                                        description={item.price}
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                    {isEmpty && (
                        <Empty
                            style={{ margin: '50px 0 100px' }}
                            description={t('common:noData')}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
});
