import React from 'react';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { Button, Input } from 'antd';

import { DatePicker } from 'antd/lib';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import enLocale from 'antd/es/date-picker/locale/en_US';

import { Languages } from '../../../../../modules/i18next/interfaces';

interface Props {
    store: any;
}
const { RangePicker } = DatePicker;

export const Filters: React.FC<Props> = observer(({ store }) => {
    const locale = i18next.language === Languages.Russian ? ruLocale : enLocale;
    const isLoading = store.indicators.isLoading.value;

    const searchProducts = (value: string) => store;

    const onChangeDates = (_: any, dates: string[]) => {
        store.filters.setDates(dates[0], dates[1]);
        // store.getList(store.page);
    };
    return (
        <div className={'productListFilters'} style={{ width: '100%', marginBottom: 32 }}>
            <Input
                placeholder={'Введите строку'}
                size={'large'}
                style={{ maxWidth: 240 }}
                onChange={(e) => store.filters.query.change(e.target.value)}
                value={store.filters.query.value}
                allowClear
            />
            {/*<RangePicker*/}
            {/*    size={'large'}*/}
            {/*    style={{ maxWidth: 300 }}*/}
            {/*    locale={locale}*/}
            {/*    onChange={onChangeDates}*/}
            {/*/>*/}
            <Button size={'large'} loading={isLoading} onClick={() => store.getList(store.page)}>
                Поиск
            </Button>
        </div>
    );
});
