import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';
import { SnapshotIn, types } from 'mobx-state-tree';
import { Product } from '../../Product/models';
import { File } from '../../common/File';

export const Indicators = types.model({
    isSending: Indicator,
    isAddingProduct: Indicator,
    isGettingCurrencies: Indicator,
    isGettingUsers: Indicator,
    isVisibleResponsiblePersons: Indicator,
    isCreatingWayBill: Indicator,
    enableAddProductButton: Indicator,
    enableCurrencySelect: Indicator,
    isGettingData: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isSending: defaultIndicatorSnapshot,
    isAddingProduct: defaultIndicatorSnapshot,
    isGettingCurrencies: defaultIndicatorSnapshot,
    isGettingUsers: defaultIndicatorSnapshot,
    isVisibleResponsiblePersons: defaultIndicatorSnapshot,
    isCreatingWayBill: defaultIndicatorSnapshot,
    enableAddProductButton: defaultIndicatorSnapshot,
    isGettingData: defaultIndicatorSnapshot,
    enableCurrencySelect: defaultIndicatorSnapshot,
};

export const ProductItem = types
    .model({
        product: Product,
        image: File,
        count: 1,
        totalPrice: types.number,
    })
    .actions((self) => {
        const setCount = (value: number | null) => {
            self.count = Number(value) >= 1 ? Number(value) : 1;
            self.totalPrice = self.count * self.product.price;
        };

        return {
            setCount,
        };
    });
