import React from 'react';

export const ChartIcon: React.FC = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 20V6.25H5V20H0ZM7.5 20V0H12.5V20H7.5ZM15 20V11.25H20V20H15Z"
                fill="#B9B9B9"
            />
        </svg>
    );
};
