import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from './interfaces';

export const getTranslationsByLanguage = (language: Languages) => {
    return {
        common: require(`./${language}/common.json`),
        header: require(`./${language}/header.json`),
        productList: require(`./${language}/product/list.json`),
        productCreate: require(`./${language}/product/create.json`),
        productView: require(`./${language}/product/view.json`),
        waybillList: require(`./${language}/waybill/list.json`),
        waybill: require(`./${language}/waybill/manage.json`),
        accountList: require(`./${language}/accountList.json`),
        cart: require(`./${language}/cart.json`),
        category: require(`./${language}/category.json`),
        organisations: require((`/${language}/organisations.json`))
    };
};

export const i18n = i18next.use(initReactI18next).init({
    lng: Languages.English,
    returnEmptyString: false,
    resources: {
        [Languages.English]: getTranslationsByLanguage(Languages.English),
        [Languages.Russian]: getTranslationsByLanguage(Languages.Russian),
    },
});
