import { EnvironmentConfig } from './interfaces';

export enum EnvType {
    DEVELOP = 'development',
    PRODUCTION = 'production',
}

const devEnvConfig = {
    port: +(process.env.REACT_APP_DEVELOPMENT_API_PORT || 80),
    baseUrl: process.env.REACT_APP_DEVELOPMENT_API_URL || '',
};

const prodEnvConfig = {
    port: +(process.env.REACT_APP_PRODUCTION_API_PORT || 80),
    baseUrl: process.env.REACT_APP_PRODUCTION_API_URL || '',
};

export const getEnvMode = (mode: string): EnvironmentConfig => {
    switch (mode) {
        case EnvType.DEVELOP:
            return devEnvConfig;
        case EnvType.PRODUCTION:
            return prodEnvConfig;
        default:
            return devEnvConfig;
    }
};

export const environment: EnvironmentConfig = getEnvMode(process.env.NODE_ENV);
