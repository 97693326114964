import { SnapshotIn, types } from 'mobx-state-tree';

export const Country = types.model({
    _id:  types.string,
    value: types.string,
    code: types.string,
});

export const defaultCountrySnapshot: SnapshotIn<typeof Country> = {
    _id: '',
    value: '',
    code: '',
};
