export const RuFlagIcon = () => {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.0864258" width="20.0784" height="6.66667" fill="white" />
            <rect x="0.0864258" y="6.66675" width="20.0784" height="6.66667" fill="#0500FF" />
            <rect x="0.0864258" y="13.3333" width="20.0784" height="6.66667" fill="#D80027" />
        </svg>
    );
};
