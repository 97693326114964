import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isAdmin, useUser } from '../../../context/UserProvider';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import { ProductService } from '../../../modules/api/product';
import { CurrencyService } from '../../../modules/api/currency';
import { FileService } from '../../../modules/api/attachment';
import { AccountService } from '../../../modules/api/account';
import { WayBillService } from '../../../modules/api/waybill';
import {
    defaultViewWayBillStore,
    ViewWayBillStore,
    Environment,
} from '../../../modules/business-logic/stores/Consignment/View';
import { Content } from './Content';
import './styles.css';
import {
    defaultExportWayBillStore,
    ExportWayBillStore,
} from '../../../modules/business-logic/stores/Consignment/Export';

export const WayBillExportRegistry = () => {
    const { user } = useUser();

    const [store, setStore] = useState<any>(defaultExportWayBillStore);
    useEffect(
        () =>
            setStore(
                ExportWayBillStore.create(defaultExportWayBillStore, {
                    fileService: serviceInitializer<FileService>(FileService),
                    wayBillService: serviceInitializer<WayBillService>(WayBillService),
                    currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                } as Environment),
            ),
        [],
    );

    if (!store) return null;

    return <Content store={store} />;
};
