import { SnapshotIn, types } from 'mobx-state-tree';
import {
    defaultNumberFieldSnapshot,
    NumberField,
} from '../../../../modules/business-logic/stores/common/NumberField';

export const Measures = types
    .model({
        width: NumberField,
        height: NumberField,
        length: NumberField,
    })
    .actions((self) => {
        const checkValid = () => {
            const isValidWidth = self.width.value >= 0;
            const isValidHeight = self.height.value >= 0;
            const isValidLength = self.length.value >= 0;

            return isValidWidth && isValidHeight && isValidLength;
        };

        return {
            checkValid,
        };
    });

export const defaultMeasuresSnapshot: SnapshotIn<typeof Measures> = {
    width: defaultNumberFieldSnapshot,
    height: defaultNumberFieldSnapshot,
    length: defaultNumberFieldSnapshot,
};
