import React from 'react';
import { Empty, List as ListAnt } from 'antd';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import { Spinner } from '../../../Page/components/Spinner';
import './styles.css';
import { useTranslation } from 'react-i18next';

interface Props {
    store: any;
}

export const List: React.FC<Props> = observer(({ store }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { list, indicators } = store;
    const isLoading = indicators.isGettingList.value;
    const isEmpty = indicators.isEmpty.value;

    // if (isLoading) {
    //     return <Spinner />;
    // }

    if (isEmpty) return <Empty style={{ marginTop: 50 }} description={t('common:noData')} />;

    const formatDate = (date: string | null) => {
        return date ? format(new Date(date), 'dd.MM.yyyy HH:mm:ss', { locale: ru }) : '-';
    };

    return (
        <div className={'list'}>
            <div className={'listItemHeader'}>
                <div className={'id'}>{t('waybillList:tableHeaders.id')}</div>
                <div className={'numberOrder'}>{t('waybillList:tableHeaders.numberOrder')}</div>
                <div className={'labelWayBill'}>{t('waybillList:tableHeaders.label')}</div>
                <div className={'createdAt'}>{t('waybillList:tableHeaders.createdAt')}</div>
                <div className={'productCountWayBill'}>
                    {t('waybillList:tableHeaders.countProducts')}
                </div>
            </div>
            <div className={'consignmentList'}>
                <ListAnt
                    // grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 5 }}
                    dataSource={store.list}
                    loading={isLoading}
                    itemLayout="vertical"
                    pagination={{
                        onChange: async (e) => {
                            await store.getList(e);
                            window.scrollTo({ top: 0 });
                        },
                        total: store.totalCount,
                        pageSize: 100,
                        showSizeChanger: false,
                        position: 'bottom',
                    }}
                    renderItem={(item: any, index: any) => (
                        <ListAnt.Item key={index}>
                            <div
                                className={'listItem'}
                                onClick={() => navigate('/waybill-view/' + item._id)}
                            >
                                {/*<Link to={'/product-view/' + item._id} className={'listItem'}>*/}
                                <div className={'id'}>{`#${item.number}`}</div>
                                <div className={'numberOrder'}>{item.numberOrder}</div>
                                <div className={'labelWayBill'}>{item.label || '-'}</div>
                                <div className={'createdAt'}>{formatDate(item.createdAt)}</div>
                                <div className={'productCountWayBill'}>
                                    {item.productIds.length}
                                </div>
                                {/*</Link>*/}
                            </div>
                        </ListAnt.Item>
                    )}
                />
            </div>
        </div>
    );
});
