import { Roles, User } from '../modules/api/auth/interfaces';
import { createContext, ReactNode, useContext, useState } from 'react';

interface UserContextType {
    user: User | null;
    setUser: (user: User | null) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<User | null>(null);

    return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

export function useUser(): UserContextType {
    const context = useContext(UserContext);

    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
}

export const isAdmin = (user: User | null) => user?.roles.includes(Roles.ADMIN);
export const isOperator = (user: User | null) => user?.roles.includes(Roles.OPERATOR);

export const getMainRole = (user: User): Roles => {
    if (isAdmin(user)) return Roles.ADMIN;
    else if (isOperator(user)) return Roles.OPERATOR;
    return Roles.GUEST;
};
