import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import {
    CategoryListStore,
    defaultCategoryListSnapshot,
    Environment
} from "../../../modules/business-logic/stores/Category/list";
import serviceInitializer from '../../../modules/api/serviceInitializer';
import {CategoryService} from "../../../modules/api/category";

import {List} from "./components/List";
import './styles.css';
import {createCategory} from "../../modals/Category/Create";
import {useUser} from "../../../context/UserProvider";

const { Title } = Typography;

export const CategoryList: React.FC = () => {
    const { t } = useTranslation();
    const { user } = useUser();
    const [store, setStore] = useState<any>(defaultCategoryListSnapshot);

    useEffect(
        () =>
            setStore(
                CategoryListStore.create(defaultCategoryListSnapshot, {
                    categoryService: serviceInitializer<CategoryService>(CategoryService),
                } as Environment),
            ),
        [],
    );

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('category:categories')}</Title>
                <Button
                    className={'titleButton'}
                    icon={<PlusOutlined />}
                    onClick={() => createCategory({
                        updateList: store.getList,
                        user,
                    })}
                    size={'large'}
                >
                    {t('category:createCategory')}
                </Button>
            </div>

            <List store={store} updateList={store.getList} />
        </div>
    );
};
