import { ConfigProvider } from 'antd';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Maybe } from 'tsmonad';

const styles = require('../styles.css');

interface Deffer<T> {
    resolve?: (p: T) => void;
    reject?: () => void;
    promise?: Promise<T>;
}

type ComponentCtor<T> = { new (v: T): React.Component<T> } | ((p: T) => JSX.Element | null);

export interface ModalResult<T> {
    result: Maybe<T>;
}

export interface Modal<T> {
    modal: {
        onCancel: () => void;
        onClose: (t: T) => void;
    };
}

export function createReactModal<TProps = {}, TResult = {}>(
    C: ComponentCtor<Modal<TResult>>,
    props: TProps,
): Promise<ModalResult<TResult>> {
    const deffer: Deffer<TResult> = {};

    deffer.promise = new Promise((resolve, reject) => {
        deffer.resolve = resolve;
        deffer.reject = reject;
    });

    const element = document.body;
    const modalWrapper = document.createElement('div');
    element.appendChild(modalWrapper);
    const modal = {
        //@ts-ignore
        onCancel: () => deffer.reject(),
        //@ts-ignore
        onClose: (v: TResult) => deffer.resolve(v),
    };
    document.body.classList.add(styles.modalOpen);
    ReactDOM.render(
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#ef5151',
                    colorInfo: '#14424d',
                    colorSuccess: '#c0ffa1',
                    colorWarning: '#ffe6b5',
                    colorError: '#ff5759',
                    colorLink: '#ffa654',
                    fontSize: 16,
                    wireframe: false,
                },
            }}
        >
            <C modal={modal} {...props} />
        </ConfigProvider>,
        modalWrapper,
    );

    return deffer.promise
        .then((v) => {
            ReactDOM.unmountComponentAtNode(modalWrapper);
            element.removeChild(modalWrapper);
            modalWrapper.remove();
            document.body.classList.remove(styles.modalOpen);
            return { result: Maybe.just<TResult>(v) };
        })
        .catch(() => {
            ReactDOM.unmountComponentAtNode(modalWrapper);
            element.removeChild(modalWrapper);
            modalWrapper.remove();
            document.body.classList.remove(styles.modalOpen);
            return { result: Maybe.nothing<TResult>() };
        });
}
