import {
    CreateParams,
    EditParams,
    GetParams,
    Account,
    Accounts,
    GetListParams,
    ResetPasswordParams,
    BlockParams,
    LastLoginDate,
} from './interfaces';
import { BaseService } from '../base/BaseService';

export class AccountService extends BaseService {
    private readonly url: string = `${this.baseUrl}/accounts`;

    public create = (params: CreateParams) =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public get = (params: GetParams): Promise<Account> =>
        this.http.get(`${this.url}`, { params }).then((res) => res.data);

    public getLastLoginDate = (params: { _id: string }): Promise<LastLoginDate | null> =>
        this.http.get(`${this.url}/last-login-date`, { params }).then((res) => res.data);

    public block = (number: number, params: BlockParams): Promise<Account> =>
        this.http.patch(`${this.url}/block/${number}`, params).then((res) => res.data);

    public getList = (params: GetListParams): Promise<Accounts> =>
        this.http.get(`${this.url}/list`, { params }).then((res) => res.data);

    public update = (params: EditParams) =>
        this.http.post(`${this.url}/update`, params).then((res) => res.data);

    public resetPassword = (params: ResetPasswordParams) =>
        this.http.post(`${this.url}/reset-password`, params).then((res) => res.data);
}
