import * as React from 'react';
import { Select } from 'antd';
import { Instance } from 'mobx-state-tree';
import { User } from '../../modules/business-logic/stores/Users/models';
import { useTranslation } from 'react-i18next';

interface Props {
    store: any;
    isLoading: boolean;
    disabled: boolean;
    value: string;
    set: (id: string) => void;
}

export const UserSelect: React.FC<Props> = ({ store, isLoading, set, value }) => {
    const { t } = useTranslation();
    if (!store) return null;
    const getAccountsOptions = (): { value: string; label: string }[] => {
        if (!store) return [];
        const options = store.map((account: Instance<typeof User>) => ({
            value: account._id,
            label: `${account.firstName} ${account.secondName}`,
        }));

        options.unshift({
            value: 0,
            label: t('common:notAssigned'),
        });

        return options;
    };

    return (
        <Select
            size={'large'}
            options={getAccountsOptions()}
            loading={isLoading}
            disabled={isLoading}
            placeholder={t('common:operator')}
            value={value}
            onChange={set}
            style={{ width: '100%' }}
        />
    );
};
