import {flow, getEnv, types} from "mobx-state-tree";
import {TextField} from "../../common/TextField";
import {Indicators} from "./models";
import {CategoryService} from "../../../../api/category";
import {Organisation} from "../../Organisations/organisation";
import {OrganisationService} from "../../../../api/organisation";

export interface Environment {
    isAdmin?: boolean;
    categoryService: CategoryService;
    organisationService: OrganisationService;
}

export const Base = types.model({
    title: TextField,
    description: TextField,
    indicators: Indicators,
    organisations: types.array(Organisation),
    selectedOrganisationId: types.maybe(types.string),
}).actions(self => {
    const { organisationService } = getEnv<Environment>(self);

    const getOrganisations = flow(function* () {
        self.indicators.isGettingOrganisations.set(true);
        try {
            self.organisations = yield organisationService.getList();
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isGettingOrganisations.set(false);
        }
    });

    const setOrganisationId = (value: string | 0) => {
        const foundedOrganisation = value
            ? self.organisations.find((account) => account._id === value)
            : undefined;
        self.selectedOrganisationId = foundedOrganisation
            ? foundedOrganisation._id
            : undefined;
    };

    return {
        getOrganisations,
        setOrganisationId,
    }
}).views(self => {
    const { isAdmin } = getEnv<Environment>(self);

    return ({
        isValid: () => {
            const isValidTitle = self.title.value.length;
            const isValidDescription = self.description.value.length;
            const isValidSelectedOrganisationId = isAdmin ? self.selectedOrganisationId : true;

            return isValidTitle && isValidDescription && isValidSelectedOrganisationId
        }
    })
})
