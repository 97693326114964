import React, { useEffect, useState } from 'react';
import './App.css';
import { Header } from './components/Header';
import { ConfigProvider } from 'antd';
import useRoutes from './routes';
import useAuth from './hooks/useAuth';
import { UserProvider } from './context/UserProvider';
import { CartProvider } from './context/CartProvider';
import { NotAuthorizedHeader } from './components/Header/NotAuthorizedHeader';
import { i18n } from './modules/i18next/init';

function App() {
    const routes = useRoutes();
    const { isAuthenticated } = useAuth();
    const [i18nLoaded, setI18nLoaded] = useState(false);

    useEffect(() => {
        i18n.then(() => setI18nLoaded(true));
    }, []);
    if (!i18nLoaded) return null;
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#ef5151',
                    colorInfo: '#14424d',
                    colorSuccess: '#c0ffa1',
                    colorWarning: '#ffe6b5',
                    colorError: '#ff5759',
                    colorLink: '#43a6a8',
                    fontSize: 16,
                    wireframe: false,
                },
            }}
        >
            <UserProvider>
                <CartProvider>
                    {/*<I18nextProvider i18n={i18next} defaultNS={'translation'}>*/}
                    {isAuthenticated && <Header />}
                    {!isAuthenticated && <NotAuthorizedHeader />}
                    {routes}
                    {/*</I18nextProvider>*/}
                </CartProvider>
            </UserProvider>
        </ConfigProvider>
    );
}

export default App;
