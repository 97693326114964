import {
    createInstance,
    CustomAxiosInstance,
    setHeadersForInstance,
    setInterceptorForInstance,
} from './axiosInstanceProxy';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import { environment } from '../../helpers/getEnvConfig';

export const config = {
    baseURL: `${environment.baseUrl}:${environment.port}`,
};

const instance: CustomAxiosInstance = createInstance(config);

export const setInterceptors = (axiosResolveFn: any, axiosRejectFn: any) =>
    setInterceptorForInstance(axiosResolveFn, axiosRejectFn, instance);

export const setToken = (token: string) =>
    setHeadersForInstance(
        {
            Authorization: `Bearer ${token}`,
        },
        instance,
    );

setToken(Cookies.get('auth_token') || '');

instance.interceptors.response.use(
    (response) => {
        response.data?.message &&
            notification.success({
                message: 'Успешно!',
                description: response.data.message,
                duration: 1,
            });
        return response;
    },
    (error) => {
        notification.error({
            message: 'Ошибка!',
            description: error.response.data.message,
            duration: 2,
        });

        if (error?.response?.status === 401) {
            Cookies.remove('auth_token');
            Cookies.remove('user');
            setToken('');
            setTimeout(() => window.location.replace('/'), 1000)
        }

        return Promise.reject(error);
    },
);

// eslint-disable-next-line
export default <T>(Service: any): T => new Service({ instance }) as T;
