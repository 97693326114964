import { SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorSnapshot, Indicator } from '../common/Indicator';

export const User = types.model({
    _id: types.string,
    username: types.string,
    firstName: types.string,
    secondName: types.string,
    roles: types.array(types.string),
    isBlocked: types.boolean,
    number: types.number,
    organisationId: types.string,
});

export const Indicators = types.model({
    isLoading: Indicator,
    isEmpty: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isLoading: defaultIndicatorSnapshot,
    isEmpty: defaultIndicatorSnapshot,
};
