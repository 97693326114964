export interface LoginParams {
    username: string;
    password: string;
}

export interface PingParams {
    userId: string;
}

export enum Roles {
    GUEST = 'GUEST',
    OPERATOR = 'OPERATOR',
    ADMIN = 'ADMIN',
}

export interface User {
    _id: string;
    username: string;
    firstName: string;
    secondName: string;
    isBlocked: boolean;
    number: number;
    roles: Roles[];
    createdAt: string;
    organisationId: string;
}

export interface LoginResponse {
    token: string;
    user: User;
}

export interface PingResponse {
    authorized: boolean;
    user?: User;
}
