import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ConsignmentList } from '../components/pages/ConsignmentList';
import { ProductList } from '../components/pages/ProductList';
import { Statistics } from '../components/pages/Statistics';
import { AccountList } from '../components/pages/AccountList';
import { ProductView } from '../components/pages/ProductView';
import { PrivateRoute } from '../components/PrivateRoutes';
import Logout from '../components/pages/Auth/Logout';
import { Auth } from '../components/pages/Auth';
import { Page } from '../components/Page';
import { WayBillCreate } from '../components/pages/WayBillCreate';
import { WayBillView } from '../components/pages/WayBillView';
import { WayBillExportPackList } from '../components/pages/WayBillExportPackList';
import { WayBillExportRegistry } from '../components/pages/WayBillExportRegistry';
import {Footer} from "../components/Footer";
import {CategoryList} from "../components/pages/CategorytList";
import {Organisations} from "../components/pages/Organisations";
import {CountryList} from "../components/pages/CountrytList";

export const useRoutes = () => {
    const location = useLocation();
    return (
        <Routes>
            <Route element={<PrivateRoute />}>
                <Route
                    path="/consignment-list"
                    element={
                        <Page>
                            <ConsignmentList />
                        </Page>
                    }
                />
                <Route
                    path="/statistics"
                    element={
                        <Page>
                            <Statistics />
                        </Page>
                    }
                />
                <Route
                    path="/organisations"
                    element={
                        <Page>
                            <Organisations />
                        </Page>
                    }
                />
                <Route
                    path="/category-list"
                    element={
                        <Page>
                            <CategoryList />
                        </Page>
                    }
                />
                <Route
                    path="/country-list"
                    element={
                        <Page>
                            <CountryList />
                        </Page>
                    }
                />
                <Route
                    path="/waybill-create"
                    element={
                        <Page>
                            <WayBillCreate />
                        </Page>
                    }
                />
                <Route
                    path="/waybill-export-registry"
                    element={
                        <Page>
                            <WayBillExportRegistry />
                        </Page>
                    }
                />
                <Route
                    path="/waybill-export-pack-list"
                    element={
                        <Page>
                            <WayBillExportPackList />
                        </Page>
                    }
                />
                <Route
                    path="/waybill-view/:id"
                    element={
                        <Page>
                            <WayBillView />
                        </Page>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <Page>
                            <AccountList />
                        </Page>
                    }
                />
            </Route>
            <Route
                path="/product-list"
                element={
                    <>
                        <Page>
                            <ProductList />
                        </Page>
                        <Footer />
                    </>
                }
            />
            {/*<Route path="/" element={<Main />} />*/}
            <Route
                path="/"
                element={<Navigate to="/product-list" state={{ from: location }} replace />}
            />
            <Route path="/auth" element={<Auth />} />
            <Route path="/logout" element={<Logout />} />
            <Route
                path="/product-view/:id"
                element={
                    <>
                        <Page>
                            <ProductView />
                        </Page>
                        <Footer />
                    </>
                }
            />
        </Routes>
    );
};

export default useRoutes;
