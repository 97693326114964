import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {Button} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {removeCategory} from "../../../modals/Category/Remove";
import {editCategory} from "../../../modals/Category/Edit";
import {isAdmin, useUser} from "../../../../context/UserProvider";

export interface Props {
    _id: string;
    value: string;
    code: string;
    disabled: boolean;
    updateList: () => void;
}

export const CategoryListItem: React.FC<Props> = observer(
    ({ _id, value, code, disabled, updateList }) => {
        const { t } = useTranslation();
        const { user } = useUser();

        return (
            <>
                <div className={`categoryListItem`} style={{
                    background: disabled ? '#eaeaea' : 'transparent'
                }}>
                    <div className={'value'}>{value}</div>
                    <div className={'description'}>{code}</div>
                    {/*<div className={'actions'}>*/}
                    {/*    <Button type="default" icon={<EditOutlined />} onClick={() => editCategory({*/}
                    {/*        _id,*/}
                    {/*        updateList,*/}
                    {/*        user*/}
                    {/*    })}/>*/}
                    {/*    {isAdmin(user) && (*/}
                    {/*        <Button type="default" icon={<DeleteOutlined />} onClick={() => removeCategory({*/}
                    {/*            _id,*/}
                    {/*            updateList,*/}
                    {/*        })}/>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            </>
        );
    },
);
