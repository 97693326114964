import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorsSnapshot, Indicators } from './models';
import { WayBillService } from '../../../../api/waybill';
import { Consignment } from '../models';
import { defaultFiltersStore, Filters } from './Filters';

export interface Environment {
    wayBillService: WayBillService;
}

export const DEFAULT_PAGE_LIST_LENGTH = 100;

export const WayBillListStore = types
    .model({
        indicators: Indicators,
        list: types.array(Consignment),
        totalCount: types.number,
        page: types.number,
        filters: Filters,
    })
    .actions((self) => {
        const environment = getEnv<Environment>(self);

        const getList = flow(function* (page: number = 1) {
            self.page = page;
            self.indicators.isGettingList.set(true);
            try {
                const query = self.filters.query.value.length
                    ? self.filters.query.value
                    : undefined;
                const startDate = self.filters.startDate || null;
                const endDate = self.filters.endDate;

                const { items, totalCount } = yield environment.wayBillService.getList({
                    limit: DEFAULT_PAGE_LIST_LENGTH,
                    offset: (page - 1) * DEFAULT_PAGE_LIST_LENGTH,
                    query,
                    startDate,
                    endDate,
                });

                self.list.replace(items);
                self.totalCount = totalCount;
                self.indicators.isEmpty.set(!self.list.length);
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingList.set(false);
            }
        });

        const afterCreate = () => {
            getList();
        };

        return {
            afterCreate,
            getList,
        };
    });

export const defaultWayBillListStore: SnapshotIn<typeof WayBillListStore> = {
    indicators: defaultIndicatorsSnapshot,
    list: [],
    totalCount: 0,
    page: 1,
    filters: defaultFiltersStore,
};
