import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Instance} from 'mobx-state-tree';
import {Input, Modal, Typography} from 'antd';

import {useTranslation} from 'react-i18next';
import {Spinner} from "../../../Page/components/Spinner";
import {CreateOrganisationStore} from "../../../../modules/business-logic/stores/Organisations/Create";
import {CurrencySelect} from "../../../CurrencySelect";
import {CountrySelect} from "../../../CountrySelect";

const {Text} = Typography;
const {TextArea} = Input;

interface Props {
    store: Instance<typeof CreateOrganisationStore>;
    isAdmin: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Content: React.FC<Props> = observer(({store, onClose, onSubmit, isAdmin}) => {
    const {t} = useTranslation();
    const {name, description} = store;
    const isValid = store.isValid && store.isValid();

    return (
        <Modal
            title={
                <Text strong className={'modalTitle'}>
                    {t('organisations:creatingOrganisation')}
                </Text>
            }
            open
            onOk={onSubmit}
            onCancel={onClose}
            okButtonProps={{disabled: !isValid}}
            cancelText={t('common:cancel')}
            okText={t('common:create')}
            confirmLoading={store.indicators.isSending.value}
        >
            <div className={'modalBody'}>
                {!store.indicators.isLoading.value && (
                    <>
                        <Input
                            placeholder={t('productCreate:title')}
                            size={'large'}
                            value={name.value}
                            onChange={(e) => name.change(e.target.value)}
                        />

                        <TextArea
                            placeholder={t('productCreate:description')}
                            size={'large'}
                            autoSize={{minRows: 3, maxRows: 7}}
                            value={description.value}
                            onChange={(e) => description.change(e.target.value)}
                            allowClear
                        />

                        <div className={'waybillViewField'}>
                            <Text type={'secondary'}>{t('common:chooseCurrency')}</Text>
                            <CurrencySelect
                                store={store.currencies}
                                isLoading={store.indicators.isGettingCurrencies.value}
                                setCurrency={store.setCurrency}
                                value={store.selectedCurrency ?? ''}
                                disabled={false}
                            />
                        </div>

                        <div className={'waybillViewField'}>
                            <Text type={'secondary'}>{t('common:chooseCountry')}</Text>
                            <CountrySelect
                                store={store.countries}
                                isLoading={store.indicators.isLoadingCountries.value}
                                setCountry={store.setCountry}
                                value={store.selectedCountry ?? ''}
                                disabled={false}
                            />
                        </div>
                    </>
                )}
                {store.indicators.isLoading.value && <Spinner/>}
            </div>
        </Modal>
    );
});
