import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorsSnapshot, IndicatorsFilter } from './models';
import { User } from '../Users/models';
import { Role } from '../../../api/account/interfaces';
import { AccountService } from '../../../api/account';

export interface StatisticsFilter {
    accountService: AccountService;
}

export const Filter = types
    .model({
        selectedUser: types.maybeNull(types.string),
        responsiblePersons: types.array(User),
        startDate: types.maybeNull(types.string),
        endDate: types.maybeNull(types.string),
        indicators: IndicatorsFilter,
    })
    .actions((self) => {
        const { accountService } = getEnv<StatisticsFilter>(self);

        const selectUser = (value: string | null) => {
            self.selectedUser = value;
        };

        const getAccounts = flow(function* () {
            self.indicators.isGettingUsers.set(true);
            try {
                self.responsiblePersons = yield accountService.getList({
                    roles: Role.OPERATOR,
                });
            } catch (err) {
                console.error(err);
            } finally {
                self.indicators.isGettingUsers.set(false);
            }
        });

        const setDates = (startDate: string | null, endDate: string) => {
            self.startDate = startDate?.length ? startDate : null;
            self.endDate = endDate?.length ? endDate : null;
        };

        const afterCreate = flow(function* () {});

        return {
            afterCreate,
            getAccounts,
            selectUser,
            setDates,
        };
    });

export const defaultFilterSnapshot: SnapshotIn<typeof Filter> = {
    indicators: defaultIndicatorsSnapshot,
};
