import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import { Cart } from '../components/Cart';

export interface CartItem {
    _id: string;
    title: string;
    price: number;
    count: number;
    totalPrice: number;
    currency: string;
    imageUrl: string;
}

interface ICartContext {
    cartItems: CartItem[];
    cartTotalPrice: number; // Добавляем поле для итоговой стоимости
    addItemToCart: (item: CartItem) => void;
    removeItemFromCart: (itemId: string) => void;
    updateItemCount: (id: string, newCount: number) => void;
    clearCart: () => void;
    replaceItemsCart: (items: CartItem[]) => void;
}

const CartContext = createContext<ICartContext | undefined>(undefined);

export const CartProvider = ({ children }: { children: ReactNode }) => {
    const [cartItems, setCartItems] = useState<CartItem[]>([]);
    const [cartTotalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        const ttp = cartItems.reduce((acc, item, index) => item.price * item.count + acc, 0)
        setTotalPrice(ttp)
    }, [cartItems])

    const addItemToCart = (item: CartItem) => {
        const existingProduct = cartItems.find((e) => e._id === item._id);

        if (existingProduct) {
            const updatedCart = cartItems.map((product) => {
                if (product._id === item._id) {
                    return {
                        ...product,
                        count: product.count + 1, // Увеличиваем count на 1
                    };
                }
                return product;
            });

            setCartItems(updatedCart);
        } else {
            setCartItems([...cartItems, item]);
        }
    };

    const updateItemCount = (id: string, newCount: number) => {
        const itemToUpdate = cartItems.find((item) => item._id === id);

        if (itemToUpdate) {
            const priceDifference = (newCount - itemToUpdate.count) * itemToUpdate.price;

            const updatedCart = cartItems.map((product) => {
                if (product._id === id) {
                    return {
                        ...product,
                        count: newCount,
                    };
                }
                return product;
            });

            setCartItems(updatedCart);
        }
    };

    const removeItemFromCart = async (itemId: string) => {
        const itemToRemove = cartItems.find((item) => item._id === itemId);

        if (itemToRemove) {
            const updatedCart = cartItems.filter((item) => item._id !== itemId);
            await setCartItems(updatedCart);
        }
    };

    const clearCart = () => {
        setTotalPrice(0); // Обнуляем итоговую стоимость
        setCartItems([]);
    };

    const replaceItemsCart = async (items: CartItem[]) => {
        await setCartItems(items);
    };

    const value: ICartContext = {
        cartItems,
        cartTotalPrice,
        addItemToCart,
        removeItemFromCart,
        updateItemCount,
        clearCart,
        replaceItemsCart,
    };

    return (
        <CartContext.Provider value={value}>
            {children}
            <Cart />
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};
