import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';
import { SnapshotIn, types } from 'mobx-state-tree';

export const Indicators = types.model({
    isEmpty: Indicator,
    isLoading: Indicator,
    isGettingList: Indicator,
});

export const defaultIndicatorsSnapshot: SnapshotIn<typeof Indicators> = {
    isEmpty: defaultIndicatorSnapshot,
    isLoading: defaultIndicatorSnapshot,
    isGettingList: defaultIndicatorSnapshot,
};
