import {flow, getEnv, types} from "mobx-state-tree";
import {TextField} from "../../common/TextField";
import {Indicators} from "./models";
import {CountryService} from "../../../../api/country";

export interface Environment {
    isAdmin?: boolean;
    countryService: CountryService;
}

export const Base = types.model({
    title: TextField,
    code: TextField,
    disabled: types.boolean,
    indicators: Indicators,
}).views(self => {
    return ({
        isValid: () => {
            const isValidTitle = self.title.value.length;

            return isValidTitle
        }
    })
})
