import {CreateParams, Organisation, Organisations, GetParams, BlockedParams} from './interfaces';
import {BaseService} from '../base/BaseService';

export class OrganisationService extends BaseService {
    private readonly url: string = `${this.baseUrl}/organisation`;

    public getList = (): Promise<Organisations> =>
        this.http.get(`${this.url}/list`).then((res) => res.data);

    public create = (params: CreateParams): Promise<void> =>
        this.http.post(`${this.url}/create`, params).then((res) => res.data);

    public get = (params: GetParams): Promise<Organisation> =>
        this.http.get(`${this.url}/?_id=${params._id}`, ).then((res) => res.data);

    public blocked = (params: BlockedParams): Promise<Organisation> =>
        this.http.patch(`${this.url}/block/?_id=${params._id}`).then((res) => res.data);

    public unblocked = (params: BlockedParams): Promise<Organisation> =>
        this.http.patch(`${this.url}/unblock/?_id=${params._id}`).then((res) => res.data);
}
