import { SnapshotIn, types } from 'mobx-state-tree';


export const Organisation = types.model({
    _id:  types.string,
    description: types.string,
    title: types.string,
    createdAt: types.string,
    isBlocked: types.boolean,
    number: types.number,
    countryId: types.string,
    currencyId: types.string
});

export const defaultOrganisationSnapshot: SnapshotIn<typeof Organisation> = {
    _id: '',
    title: '',
    description: '',
    createdAt: '',
    isBlocked: false,
    number: 0,
    countryId: '',
    currencyId: ''
};
