import React, { useRef, useState } from 'react';
import { Button, Image, Modal, Typography, Upload, UploadProps } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import './styles.css';
import Webcam from 'react-webcam';
import { config } from '../../modules/api/serviceInitializer';
import { useTranslation } from 'react-i18next';
import { CameraOutlined, UploadOutlined } from '@ant-design/icons';

const { Text } = Typography;

export interface OnUploadAttachment {
    list: string[];
    active: string | null;
}

interface Props {
    onUpload: (e: OnUploadAttachment) => void;
    fileList: UploadFile[];
    // logoId: string | null;
    setFileList: (list: UploadFile[]) => void;
    setActiveImage: (e: string | null) => void;
    activeImage: string | null;
}

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
    facingMode: FACING_MODE_USER,
};

export const UploadManager: React.FC<Props> = ({
    onUpload,
    fileList,
    setFileList,
    activeImage,
    setActiveImage,
}) => {
    const { t } = useTranslation();

    const [isVisibleAddPictureModal, setVisibleAddPictureModal] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage] = useState('');
    const [previewTitle] = useState('');
    const webcamRef = useRef(null);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

    // useEffect(() => setActiveImage(logoId), [logoId])

    const handleCancel = () => setPreviewOpen(false);

    const switchCamera = React.useCallback(() => {
        setFacingMode((prevState) =>
            prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER,
        );
    }, []);

    function dataURLtoBlob(dataURL: string) {
        const arr = dataURL.split(',');
        //@ts-ignore
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    }

    const handleUpload = () => {
        //@ts-ignore
        const screenshot = webcamRef.current?.getScreenshot({ width: 640, height: 480 });

        if (!screenshot) {
            return;
        }

        const fileName = `${Date.now()}.jpeg`;

        const formData = new FormData();
        formData.append('file', dataURLtoBlob(screenshot), fileName);

        fetch(config.baseURL + '/api/attachment/upload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then(({ response }) => {
                setFileList([
                    ...fileList,
                    {
                        uid: response._id,
                        name: response.name,
                        status: 'done',
                        url: config.baseURL + '/' + response.url,
                    },
                ]);

                onUpload({
                    list: [...fileList.map((file) => file.uid), response._id],
                    active: activeImage,
                });
                setVisibleAddPictureModal(false);
            })
            .then(() => {})
            .catch((error) => {
                console.error(t('common:errorUploadingFile') + ':', error);
            });
    };

    const handleUploadLocalFile = ({ file, onSuccess, onError }: any) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch(config.baseURL + '/api/attachment/upload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then(({ response }) => {
                setFileList([
                    ...fileList,
                    {
                        uid: response._id,
                        name: response.name,
                        status: 'done',
                        url: config.baseURL + '/' + response.url,
                    },
                ]);

                onUpload({
                    list: [...fileList.map((file) => file.uid), response._id],
                    active: activeImage,
                });
                setVisibleAddPictureModal(false);
            })
            .then(() => {})
            .catch((error) => {
                console.error(t('common:errorUploadingFile') + ':', error);
            });
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            console.error('You can only upload JPG/PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            console.error('Image must bigger than 5MB!');
        }
        return isJpgOrPng && isLt5M;
    };

    return (
        <>
            <div className={'fileList'}>
                {fileList.map((file) => (
                    <div
                        key={file.name}
                        className={'fileCard'}
                        onClick={() => {
                            setActiveImage(file.uid);
                            onUpload({
                                list: fileList.map((file) => file.uid),
                                active: file.uid,
                            });
                        }}
                        style={{
                            border:
                                activeImage === file.uid ? '2px solid #53d371' : '2px solid #eee',
                        }}
                    >
                        <Image src={file.url} preview={false} />
                    </div>
                ))}
                <div className={'uploadButton'}>
                    <CameraOutlined
                        className={'createProductUploadButtons'}
                        onClick={() => setVisibleAddPictureModal(true)}
                    />
                    <Upload
                        className={'createProductUploadButtons'}
                        name="file"
                        showUploadList={false}
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        customRequest={handleUploadLocalFile}
                    >
                        <div className={'createProductUploadSvgContainer'}>
                            <UploadOutlined onClick={(e) => console.log(e)} />
                        </div>
                    </Upload>
                </div>
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {isVisibleAddPictureModal && (
                <Modal
                    open={true}
                    onCancel={() => setVisibleAddPictureModal(false)}
                    onOk={handleUpload}
                    okText={t('productCreate:takePhoto')}
                    cancelText={t('common:cancel')}
                    title={
                        <Text strong className={'modalTitle'}>
                            {t('productCreate:addingPhoto')}
                        </Text>
                    }
                >
                    <div className={'modalBody'}>
                        <Webcam
                            audio={false}
                            height={350}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            width={472}
                            videoConstraints={{
                                ...videoConstraints,
                                facingMode,
                            }}
                        />
                        <Button onClick={switchCamera}>{t('productCreate:changeCamera')}</Button>
                    </div>
                </Modal>
            )}
        </>
    );
};
