import * as React from 'react';
import { Content } from './Content';
import { useEffect, useState } from 'react';
import { AccountService } from '../../../../../modules/api/account';
import { CreateProductStore } from '../../../../../modules/business-logic/stores/Product/Manage/Create';
import { defaultBaseProductStore } from '../../../../../modules/business-logic/stores/Product/Manage/Base';
import serviceInitializer from '../../../../../modules/api/serviceInitializer';
import { ProductService } from '../../../../../modules/api/product';
import { CurrencyService } from '../../../../../modules/api/currency';
import { isAdmin } from '../../../../../context/UserProvider';
import { SimilarNamesService } from '../../../../../modules/api/similar-names';
import { createReactModal, Modal } from '../../../../ModalWindow/AntModal/createAntModal';
import { Product } from '../../../../../modules/api/product/interfaces';
import {CategoryService} from "../../../../../modules/api/category";

interface Params {
    updateList: () => void;
    user: any;
    currency?: string | null;
}

type Props = Params & Modal<Product | {}>;

export const CreateProductModal: React.FC<Props> = (props) => {
    const onClose = () => props.modal.onClose({});
    const [store, setStore] = useState<any>(defaultBaseProductStore);
    useEffect(() => {
        const productStore = CreateProductStore.create(defaultBaseProductStore, {
            isAdmin: isAdmin(props.user),
            userId: props.user?._id,
            currency: props.currency,
            productService: serviceInitializer<ProductService>(ProductService),
            currencyService: serviceInitializer<CurrencyService>(CurrencyService),
            accountService: serviceInitializer<AccountService>(AccountService),
            categoryService: serviceInitializer<AccountService>(CategoryService),
            similarNamesService: serviceInitializer<SimilarNamesService>(SimilarNamesService),
        });
        setStore(productStore);
    }, [props.user]);

    if (!store) return null;

    const submit = async () => {
        const newProduct = await store.create();
        props.modal.onClose(newProduct);
        props.updateList();
    };

    return <Content onClose={onClose} store={store} onSubmit={submit} />;
};

//@ts-ignore
export const createProduct = (params: Params) => createReactModal(CreateProductModal, params);
