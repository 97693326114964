import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { ProductService } from '../../../../../api/product';
import { defaultTextFieldSnapshot, TextField } from '../../../common/TextField';
import { defaultIndicatorsSnapshot, Indicators } from './indicators';

interface Environment {
    productService: ProductService;
}
//currencyId, limit, offset, query, startDate, endDate
export const Filters = types
    .model({
        query: TextField,
        startDate: types.maybeNull(types.string),
        endDate: types.maybeNull(types.string),
        indicators: Indicators,
    })
    .actions((self) => {
        const { productService } = getEnv<Environment>(self);

        const setDates = (startDate: string | null, endDate: string | null) => {
            self.startDate = startDate && startDate.length ? startDate : null;
            self.endDate = endDate && endDate.length ? endDate : null;
        };

        return {
            setDates,
        };
    });

export const defaultFiltersStore: SnapshotIn<typeof Filters> = {
    query: defaultTextFieldSnapshot,
    indicators: defaultIndicatorsSnapshot,
};
