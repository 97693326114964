import OpenAI from 'openai';

const openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true,
});

export const generateOpenAIString = async (req: string) =>
    openai.chat.completions
        .create({
            messages: [{ role: 'user', content: req }],
            model: 'gpt-3.5-turbo',
        })
        .then((res) => res.choices && res.choices[0]?.message?.content);
