import {flow, getEnv, SnapshotIn, types} from 'mobx-state-tree';
import {defaultIndicatorsSnapshot, Indicators} from "./models";
import {CategoryService} from "../../../../api/category";

export interface Environment {
    categoryService: CategoryService;
}

export const RemoveCategoryStore = types.model({
    indicators: Indicators,
}).actions(self => {
    const { categoryService } = getEnv<Environment>(self);

    const remove = flow(function* (_id: string) {
        self.indicators.isSending.set(true);
        try {
            return yield categoryService.edit({
                _id,
                blocked: true
            })
        } catch (err) {
            console.error(err);
        } finally {
            self.indicators.isSending.set(false);
        }
    });

    return {
        remove
    }
})

export const defaultRemoveCategoryStoreSnapshot: SnapshotIn<typeof RemoveCategoryStore> = {
    indicators: defaultIndicatorsSnapshot,
}
