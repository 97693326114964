import * as React from 'react';
import { useEffect, useState } from 'react';
import serviceInitializer from "../../../../modules/api/serviceInitializer";
import {Product} from "../../../../modules/api/product/interfaces";
import {isAdmin} from "../../../../context/UserProvider";
import {createReactModal, Modal} from "../../../ModalWindow/AntModal/createAntModal";
import {AccountService} from "../../../../modules/api/account";
import {
    CreateCategoryStore,
    defaultCreateCategoryStoreSnapshot
} from "../../../../modules/business-logic/stores/Category/Create";
import {CategoryService} from "../../../../modules/api/category";
import {Content} from "./Content";
import {OrganisationService} from "../../../../modules/api/organisation";

interface Params {
    updateList: () => void;
    user: any;
}

type Props = Params & Modal<Product | {}>;

export const CreateCategoryModal: React.FC<Props> = (props) => {
    const onClose = () => props.modal.onClose({});
    const [store, setStore] = useState<any>(null);
    const isAdminUser = isAdmin(props.user) || false;
    useEffect(() => {
        const productStore = CreateCategoryStore.create(defaultCreateCategoryStoreSnapshot, {
            isAdmin: isAdmin(props.user),
            accountService: serviceInitializer<AccountService>(AccountService),
            categoryService: serviceInitializer<CategoryService>(CategoryService),
            organisationService: serviceInitializer<CategoryService>(OrganisationService),
        });
        setStore(productStore);
    }, [props.user]);

    if (!store) return null;

    const submit = async () => {
        const res = await store.create();
        props.modal.onClose(res);
        props.updateList();
    };

    return <Content onClose={onClose} store={store} onSubmit={submit} isAdmin={isAdminUser}/>;
};

//@ts-ignore
export const createCategory = (params: Params) => createReactModal(CreateCategoryModal, params);
