import * as React from 'react';
import {useEffect, useState} from 'react';
import {Product} from "../../../../modules/api/product/interfaces";
import {isAdmin} from "../../../../context/UserProvider";
import {createReactModal, Modal} from "../../../ModalWindow/AntModal/createAntModal";
import {Content} from "./Content";
import {Instance} from "mobx-state-tree";
import {
    CreateOrganisationStore,
    defaultCreateOrganisationStoreSnapshot
} from "../../../../modules/business-logic/stores/Organisations/Create";
import {User} from "../../../../modules/api/auth/interfaces";
import {OrganisationService} from "../../../../modules/api/organisation";
import serviceInitializer from "../../../../modules/api/serviceInitializer";
import {PaymentStatusService} from "../../../../modules/api/payment-status";
import {CurrencyService} from "../../../../modules/api/currency";
import {toJS} from "mobx";
import {CountryService} from "../../../../modules/api/country";

interface Params {
    user: User | null;
    updateList: () => void;
}

type Props = Params & Modal<Product | {}>;

export const CreateOrganisationsModal: React.FC<Props> = (props) => {
    const onClose = () => props.modal.onClose({});
    const [store, setStore] = useState<Instance<typeof CreateOrganisationStore> | null>(null);
    const isAdminUser = isAdmin(props.user) || false;

    useEffect(() => {
        const organisationStore = CreateOrganisationStore.create(defaultCreateOrganisationStoreSnapshot, {
            isAdmin: isAdmin(props.user),
            organisationServices: serviceInitializer<OrganisationService>(OrganisationService),
            paymentStatusServices: serviceInitializer<PaymentStatusService>(PaymentStatusService),
            currencyService: serviceInitializer<CurrencyService>(CurrencyService),
            countryService: serviceInitializer<CountryService>(CountryService)
        });
        setStore(organisationStore);
    }, [props.user]);

    if (!store) return null;

    const submit = async () => {
        const res = await store.create();
        props.updateList();
        props.modal.onClose(res);
    };

    return <Content onClose={onClose} store={store} onSubmit={submit} isAdmin={isAdminUser}/>;
};

//@ts-ignore
export const createOrganisations = (params: Params) => createReactModal(CreateOrganisationsModal, params);
