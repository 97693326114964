import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultTextFieldSnapshot, TextField } from '../common/TextField';
import { defaultIndicatorSnapshot, Indicator } from '../common/Indicator';
import { AuthService } from '../../../api/auth';

export interface Environment {
    authService: AuthService;
}

export const AuthStore = types
    .model({
        username: TextField,
        password: TextField,
        isSending: Indicator,
    })
    .actions((self) => {
        const { authService } = getEnv<Environment>(self);

        const login = flow(function* () {
            self.isSending.set(true);
            try {
                const { token, user } = yield authService.login({
                    username: self.username.value,
                    password: self.password.value,
                });
                return { token, user };
            } catch (err) {
                console.log(err);
                return { token: null };
            } finally {
                self.isSending.set(false);
            }
        });

        return {
            login,
        };
    })
    .views((self) => ({
        isValid: () => self.username.value.length > 3 && self.password.value.length > 3,
    }));

export const defaultAuthStoreSnapshot: SnapshotIn<typeof AuthStore> = {
    username: defaultTextFieldSnapshot,
    password: defaultTextFieldSnapshot,
    isSending: defaultIndicatorSnapshot,
};
