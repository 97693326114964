import { types } from 'mobx-state-tree';

export const ConsignmentProductItem = types.model({
    _id: types.string,
    count: types.number,
});

export const Consignment = types.model({
    _id: types.string,
    number: types.number,
    numberOrder: types.string,
    label: types.string,
    createdAt: types.string,
    isDeleted: types.boolean,
    productIds: types.array(ConsignmentProductItem),
    responsiblePersonId: types.string,
});
