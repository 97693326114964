import React, { useEffect, useState } from 'react';
import {
    CreateAccountStore,
    defaultCreateAccountStore,
    Environment,
} from '../../../../modules/business-logic/stores/Users/Create';
import serviceInitializer from '../../../../modules/api/serviceInitializer';
import { Content } from './components/Content';
import { AccountService } from '../../../../modules/api/account';
import {OrganisationService} from "../../../../modules/api/organisation";
import {isAdmin, useUser} from "../../../../context/UserProvider";

interface Props {
    onClose: () => void;
    updateList: () => void;
}

export const CreateAccountModal: React.FC<Props> = ({ onClose, updateList }) => {
    const { user } = useUser();
    const [store, setStore] = useState<any>(defaultCreateAccountStore);
    useEffect(
        () => {
            const s =
                CreateAccountStore.create(defaultCreateAccountStore, {
                    isAdmin: isAdmin(user),
                    accountService: serviceInitializer<AccountService>(AccountService),
                    organisationService: serviceInitializer<OrganisationService>(OrganisationService),
                } as Environment);
            s.getOrganisations();
            setStore(s);
        },
        [],
    );

    if (!store) return null;

    return <Content store={store} onClose={onClose} updateList={updateList} />;
};
