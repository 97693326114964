import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';

import { SimilarNamesService } from '../../../../api/similar-names';

export interface Environment {
    // url: string;
    similarNamesService: SimilarNamesService;
}

const Name = types.model({
    name: types.string,
    src: types.string,
});

export const YandexNames = types
    .model({
        disabledButton: Indicator,
        names: types.array(Name),
        isGetting: Indicator,
    })
    .actions((self) => {
        const { similarNamesService } = getEnv<Environment>(self);

        const getData = flow(function* (url: string) {
            self.isGetting.set(true);
            try {
                // const url1 = 'https://chipify.ru/attachment/1701659660020.jpeg'
                const qwe = yield similarNamesService.getYandex(url);
                console.log(qwe);
                self.names = qwe;
            } catch (e) {
                console.error(e);
            } finally {
                self.isGetting.set(false);
            }
        });

        const afterCreate = flow(function* () {
            // yield getData(url);
        });

        return {
            afterCreate,
            getData,
        };
    });

export const defaultYandexNamesSnapshot: SnapshotIn<typeof YandexNames> = {
    disabledButton: {
        value: true,
    },
    isGetting: defaultIndicatorSnapshot,
};
