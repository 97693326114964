import { defaultTextFieldSnapshot, TextField } from '../../common/TextField';
import { flow, getEnv, SnapshotIn, types } from 'mobx-state-tree';
import { defaultNumberFieldSnapshot, NumberField } from '../../common/NumberField';
import { File } from '../../common/File';
import { defaultIndicatorSnapshot, Indicator } from '../../common/Indicator';
import { User } from '../../Users/models';
import { Currency } from '../../Currency';
import { defaultYandexNamesSnapshot, Environment as YaEnv, YandexNames } from './YandexNames';
import { Role } from '../../../../api/account/interfaces';
import { CreateProductStore } from './Create';
import { generateOpenAIString } from '../../helpers/generateOpenAIString';
import { ProductService } from '../../../../api/product';
import { CurrencyService } from '../../../../api/currency';
import { AccountService } from '../../../../api/account';
import { FileService } from '../../../../api/attachment';
import {CategoryService} from "../../../../api/category";
import {Category} from "../../Category/models";

export interface Environment extends YaEnv {
    isAdmin: boolean;
    userId: string;
    currency?: string;
    productService: ProductService;
    currencyService: CurrencyService;
    accountService: AccountService;
    fileService: FileService;
    categoryService: CategoryService;
}

export const Base = types
    .model({
        title: TextField,
        description: TextField,
        price: NumberField,
        barcode: TextField,
        externalLink: TextField,
        isSending: Indicator,
        isGettingImages: Indicator,
        isLoading: Indicator,
        isGettingCurrencies: Indicator,
        isGettingCategories: Indicator,
        isGettingProduct: Indicator,
        isLoadingData: Indicator,
        isGettingUsers: Indicator,
        isVisibleOperatorsSelect: Indicator,
        logoId: types.maybeNull(types.string),
        imageIds: types.array(types.string),
        images: types.array(File),
        responsiblePersons: types.array(User),
        categories: types.array(Category),
        selectedResponsiblePersonId: types.maybeNull(types.string),
        currencies: types.array(Currency),
        selectedCurrency: types.maybeNull(types.string),
        selectedCategory: types.maybeNull(types.string),
        number: types.maybeNull(types.number),
        newProductNumber: types.maybe(types.number),
        yandexNames: YandexNames,
        disabledGenerateNameButton: Indicator,
        isLoadingGenerateNameButton: Indicator,
        disabledCurrencySelect: Indicator,
        weight: NumberField,
    })
    .actions((self) => {
        const { isAdmin, currency, accountService, currencyService, categoryService }: Environment =
            getEnv<Environment>(self);

        const loadData = flow(function* () {
            self.isLoading.set(true);
            try {
                self.isVisibleOperatorsSelect.set(isAdmin);
                yield Promise.all([isAdmin && getAccounts(), getCurrencies(), getCategories()]);
                if (currency) {
                    self.selectedCurrency = currency;
                    self.disabledCurrencySelect.set(true);
                }
            } catch (err) {
                console.error(err);
            } finally {
                self.isLoading.set(false);
            }
        });

        const setLogoId = (id: string | null) => {
            self.logoId = id;
            id && self.yandexNames.disabledButton.set(false);
        };

        const setAttachmentList = (ids: string[]) => {
            const listWasEmpty = self.imageIds.length === 0;
            self.imageIds.replace(ids);
            if (listWasEmpty) setLogoId(self.imageIds[0]);
        };
        const setCurrency = async (id: string) => {
            self.selectedCurrency = id;
            if (self.selectedCurrency)
                await localStorage.setItem('currencyId', self.selectedCurrency);
        };

        const setCategory = async (id: string) => {
            if (typeof id !== "string") return

            const foundedCategory = id
                ? self.categories.find((category) => category._id === id)
                : undefined;

            if (!foundedCategory) {
                await localStorage.removeItem('categoryId');
                return;
            }

            self.selectedCategory = foundedCategory
                ? foundedCategory._id
                : null;

            if (self.selectedCategory)
                await localStorage.setItem('categoryId', self.selectedCategory);
        };

        const getCurrencies = flow(function* () {
            self.isGettingCurrencies.set(true);
            try {
                self.currencies = yield currencyService.getList();
            } catch (err) {
                console.error(err);
            } finally {
                self.isGettingCurrencies.set(false);
            }
        });

        const getCategories = flow(function* () {
            self.isGettingCategories.set(true);
            try {
                const { items } = yield categoryService.getList();
                self.categories = items
            } catch (err) {
                console.error(err);
            } finally {
                self.isGettingCategories.set(false);
            }
        });

        const getAccounts = flow(function* () {
            self.isGettingUsers.set(true);
            try {
                self.responsiblePersons = yield accountService.getList({
                    roles: Role.OPERATOR,
                });
            } catch (err) {
                console.error(err);
            } finally {
                self.isGettingUsers.set(false);
            }
        });

        const generateDescription = flow(function* () {
            self.isLoadingGenerateNameButton.set(true);
            try {
                const description = yield generateOpenAIString(
                    'Get 50 words description for ' + self.title.value + ' and add information about material',
                );
                self.description.change(description);
            } catch (err) {
                console.log(err);
            } finally {
                self.isLoadingGenerateNameButton.set(false);
            }
        });

        const setResponsiblePerson = (value: string | 0) => {
            const foundedResponsiblePerson = value
                ? self.responsiblePersons.find((account) => account._id === value)
                : undefined;
            self.selectedResponsiblePersonId = foundedResponsiblePerson
                ? foundedResponsiblePerson._id
                : null;
        };

        const isValid = () => {
            const isValidTitle = Boolean(self.title.value.trim().length);
            const isValidPrice = Number(self.price.value) > 0;
            const isValidWeight = Number(self.weight.value) >= 0;
            const isValidLogo = self.logoId && self.logoId.length > 0;
            const isValidImages = self.imageIds && self.imageIds.length > 0;
            const isValidCurrency = Boolean(
                self.selectedCurrency &&
                    self.selectedCurrency.length > 0 &&
                    self.currencies.find((currency) => currency._id === self.selectedCurrency),
            );

            return (
                isValidTitle &&
                isValidCurrency &&
                isValidPrice &&
                isValidWeight &&
                isValidLogo &&
                isValidImages &&
                !self.isLoading.value
            );
        };

        // const afterCreate = async () => {
        //
        // }
        return {
            // afterCreate,
            loadData,
            isValid,
            setResponsiblePerson,
            setLogoId,
            setAttachmentList,
            setCurrency,
            setCategory,
            generateDescription,
        };
    });

export const defaultBaseProductStore: SnapshotIn<typeof CreateProductStore> = {
    title: defaultTextFieldSnapshot,
    description: defaultTextFieldSnapshot,
    barcode: defaultTextFieldSnapshot,
    externalLink: defaultTextFieldSnapshot,
    price: {
        value: 50,
    },
    weight: defaultNumberFieldSnapshot,
    isSending: defaultIndicatorSnapshot,
    isLoading: defaultIndicatorSnapshot,
    isLoadingData: defaultIndicatorSnapshot,
    isGettingCurrencies: defaultIndicatorSnapshot,
    isVisibleOperatorsSelect: defaultIndicatorSnapshot,
    isGettingProduct: defaultIndicatorSnapshot,
    isGettingUsers: defaultIndicatorSnapshot,
    isGettingImages: defaultIndicatorSnapshot,
    yandexNames: defaultYandexNamesSnapshot,
    disabledGenerateNameButton: defaultIndicatorSnapshot,
    isLoadingGenerateNameButton: defaultIndicatorSnapshot,
    disabledCurrencySelect: defaultIndicatorSnapshot,
    isGettingCategories: defaultIndicatorSnapshot,
    currencies: [],
    categories: [],
    responsiblePersons: [],
};
