import React, { useEffect, useState } from 'react';

import {
    defaultProductListSnapshot,
    ProductListStore,
} from '../../../../modules/business-logic/stores/Product/list';
import { CurrencyService } from '../../../../modules/api/currency';
import { ProductService } from '../../../../modules/api/product';
import { FileService } from '../../../../modules/api/attachment';
import serviceInitializer from '../../../../modules/api/serviceInitializer';
import { createReactModal, Modal } from '../../../ModalWindow/AntModal/createAntModal';
import { Product } from '../../../../modules/api/product/interfaces';
import { ModalProductListContent } from './List';

interface AddProductModalParams {
    currencyId?: string;
}

type Props = AddProductModalParams & Modal<Product | {}>;

export const ModalProductList: React.FC<Props> = (props) => {
    const closeModal = () => props.modal.onClose({});
    const [store, setStore] = useState<any>(defaultProductListSnapshot);
    useEffect(
        () =>
            setStore(
                ProductListStore.create(defaultProductListSnapshot, {
                    currencyId: props.currencyId,
                    productService: serviceInitializer<ProductService>(ProductService),
                    currencyService: serviceInitializer<CurrencyService>(CurrencyService),
                    fileService: serviceInitializer<FileService>(FileService),
                }),
            ),
        [],
    );

    if (!store) return null;

    const submit = async (item: any) => {
        props.modal.onClose(item);
    };

    return <ModalProductListContent store={store} onClose={closeModal} onSubmit={submit} />;
};

export const createModalProductList = (params?: AddProductModalParams) =>
    createReactModal(ModalProductList, params);
