import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { WayBillService } from '../../../modules/api/waybill';
import serviceInitializer from '../../../modules/api/serviceInitializer';
import {
    defaultWayBillListStore,
    WayBillListStore,
} from '../../../modules/business-logic/stores/Consignment/List';
import { Environment } from '../../../modules/business-logic/stores/Consignment/List';
import { List } from './components/List';
import './styles.css';
import { createWayBillExportModal } from '../../modals/WayBillExportModal';
import { useTranslation } from 'react-i18next';
import { Filters } from './components/Filters';

const { Title } = Typography;

export const ConsignmentList: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [store, setStore] = useState<any>(defaultWayBillListStore);
    useEffect(() => {
        setStore(
            WayBillListStore.create(defaultWayBillListStore, {
                wayBillService: serviceInitializer<WayBillService>(WayBillService),
            } as Environment),
        );
    }, []);

    const createWayBill = async () => navigate('/waybill-create');
    // const exportWayBill = async () => navigate('/waybill-export-registry');
    const exportWayBill = async () => createWayBillExportModal({ navigate });

    return (
        <div className={'deviceListPage'}>
            <div className={'titleBlock'}>
                <Title className={'title'}>{t('waybillList:waybills')}</Title>
                <div style={{ display: 'flex', gap: 8 }}>
                    <Button
                        className={'titleButton'}
                        icon={<DownloadOutlined />}
                        onClick={exportWayBill}
                        size={'large'}
                    >
                        {t('waybillList:unloading')}
                    </Button>
                    <Button
                        type={'primary'}
                        className={'titleButton'}
                        icon={<PlusOutlined />}
                        onClick={createWayBill}
                        size={'large'}
                    >
                        {t('waybillList:addWaybill')}
                    </Button>
                </div>
            </div>
            <Filters store={store} />
            <List store={store} />
        </div>
    );
};
