import { flow, getEnv } from 'mobx-state-tree';
import { Base, Environment } from './Base';

export const CreateProductStore = Base.actions((self) => {
    const { userId, productService }: Environment = getEnv<Environment>(self);

    const afterCreate = flow(function* () {
        yield self.loadData();
        const savedCurrencyId = localStorage.getItem('currencyId');
        self.selectedCurrency = savedCurrencyId || null;

        const savedCategoryId = localStorage.getItem('categoryId');
        if (savedCategoryId) self.setCategory(savedCategoryId)
    });

    const create = flow(function* () {
        if (!self.isValid() || !self.logoId || !self.selectedCurrency) return;
        self.isSending.set(true);
        try {
            const newProduct = yield productService.create({
                title: self.title.value.trim(),
                description: self.description.value.trim(),
                logoId: self.logoId,
                currencyId: self.selectedCurrency,
                categoryId: self.selectedCategory,
                imageIds: self.imageIds,
                barcode: self.barcode.value.length ? self.barcode.value : undefined,
                externalLink: self.externalLink.value.length ? self.externalLink.value : undefined,
                price: self.price.value,
                weight: self.weight.value,
                responsiblePersonId: self.selectedResponsiblePersonId || userId,
            });

            self.newProductNumber = newProduct.number;

            return newProduct;
        } catch (err) {
            console.error(err);
        } finally {
            self.isSending.set(false);
        }
    });

    return {
        create,
        afterCreate,
    };
});
